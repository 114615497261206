import React,{Component} from 'react';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {
    Container
} from 'semantic-ui-react';

class Signer extends Component{
    constructor(props){
        super(props);

        this.state={
        };

        this.key=this.props.match.params.key;
        console.log('KEY =>',this.key);
    }

    render(){
        const {
            key
        }=this.state;

        return (
            <Container>
                <h1>SIGN</h1>
                <p>{key}</p>
            </Container>
        );
    }
}

const mapStateToProps=(state)=>{
    return {
        auth:state.auth
    };
};

export default withTranslation('global')(
    withRouter(connect(mapStateToProps)(Signer))
);

