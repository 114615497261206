import moment from 'moment-timezone';
import React,{Component,Fragment} from 'react';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {
    Button,
    Container,
    Dimmer,
    Divider,
    Dropdown,
    Header,
    Icon,
    Image,
    Input,
    Form,
    Loader,
    Menu,
    Modal,
    Segment
} from 'semantic-ui-react';

import {
    TIMEZONE_DEFAULT
} from '../config';
import {
    apiFetch,
    message,
    acl
} from '../utils';

const empty='data:image/gif;base64,'+
    'R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

class WebcaptureViewer extends Component{
    constructor(props){
        super(props);

        this.state={
            mode:this.props.mode,
            id:this.props.match.params.resource,
            data:{
                ts:'',
                url:'',
                params:{
                    width:1024,
                    height:768
                }
            },
            preview:empty,
            loading:false,
            modal:false,
            timezone:this.props.data.timezone||TIMEZONE_DEFAULT
        };

        this.handleClose=this.handleClose.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.handleClick=this.handleClick.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
    }

    componentDidMount(){
        const {
                id
            }=this.state,
            {t}=this.props;

        apiFetch({
            url:'/api/settings/data',
            method:'GET',
            success:(data)=>{
                let obj=JSON.parse(data.data);

                this.setState({
                    timezone:obj.timezone
                });

                if(id){
                    apiFetch({
                        url:'/api/webcaptures/'+id,
                        method:'GET',
                        success:(data)=>{
                            this.setState({
                                data:data,
                                preview:'/api/webcaptures/'+id+'/png'
                            });
                        },
                        error:(error)=>{
                            message(false,error.body.message,t);
                        },
                        t:t
                    });
                }
            },
            error:(error)=>{
                this.setState({
                    error:error
                });
            },
            t:t
        });
    }

    handleClose(){
        this.setState({
            modal:false
        });
    }

    handleChange(event,_data){
        const {
            data
        }=this.state;

        switch(_data.name){
            case 'width':
                this.setState({
                    data:{
                        ...data,
                        params:{
                            ...data.params,
                            width:_data.value
                        }
                    }
                });

                break;
            case 'height':
                this.setState({
                    data:{
                        ...data,
                        params:{
                            ...data.params,
                            height:_data.value
                        }
                    }
                });

                break;
            default:
                this.setState({
                    data:{
                        ...data,
                        [_data.name]:_data.value
                    }
                });
        }
    }

    handleClick(event,_data){
        const {
                id,
                data
            }=this.state,
            {t}=this.props;

        event.stopPropagation();

        switch(_data['data-name']){
            case 'preview':
                if(
                    document.querySelector('input[name="url"]')
                    .reportValidity()&&
                    document.querySelector('input[name="width"]')
                    .reportValidity()&&
                    document.querySelector('input[name="height"]')
                    .reportValidity()
                ){
                    this.setState({
                        loading:true
                    });

                    apiFetch({
                        url:'/api/webcaptures/preview',
                        method:'POST',
                        data:{
                            url:data.url,
                            params:{
                                width:+data.params.width,
                                height:+data.params.height
                            }
                        },
                        success:(__data)=>{
                            this.setState({
                                preview:'data:image/png;base64,'+__data.preview,
                                loading:false
                            });
                        },
                        error:(error)=>{
                            this.setState({
                                loading:false
                            });

                            message(false,error.body.message,t);
                        },
                        t:t
                    });
                }

                break;
            case 'archive':
                this.setState({
                    modal:true
                });

                break;
            case 'modal-archive':
                apiFetch({
                    url:'/api/webcaptures/'+id+'/archive',
                    method:'POST',
                    success:(__data)=>{
                        this.setState({
                            modal:false
                        });
                        this.props.history.push('/webcaptures');

                        message(__data.ok,__data.message,t);
                    },
                    error:(error)=>{
                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            default:
                break;
        }
    }

    handleSubmit(event){
        const {
                mode,
                data
            }=this.state,
            {t}=this.props;

        this.setState({
            loading:true
        });

        event.preventDefault();

        if(mode==='add'){
            apiFetch({
                url:'/api/webcaptures',
                method:'POST',
                data:{
                    url:data.url,
                    params:{
                        width:+data.params.width,
                        height:+data.params.height
                    }
                },
                success:(data)=>{
                    this.setState({
                        loading:false
                    });
                    this.props.history.push('/webcaptures');

                    message(data.ok,data.message,t);
                },
                error:(error)=>{
                    this.setState({
                        loading:false
                    });

                    message(false,error.body.message,t);
                },
                t:t
            });
        }
    }

    render(){
        const {
                mode,
                id,
                data,
                preview,
                loading,
                modal,
                timezone
            }=this.state,
            {t}=this.props;

        if(['add','edit'].includes(mode)){
            if(
                !this.props.auth.logged||
                !acl(this.props.auth,[['webcapture:generate']])
            ){
                return (
                    <Redirect to='/' />
                );
            }
        }else if(['view'].includes(mode)){
            if(
                !this.props.auth.logged||
                !acl(this.props.auth,[['webcapture:list']])
            ){
                return (
                    <Redirect to='/' />
                );
            }
        }

        return (
            <Container fluid>
                <Header as='h1'>{t('menubar.webcaptures')}</Header>

                <div className='ui breadcrumb'>
                    <a href='/' className='section'>
                        {t('menubar.home')}
                    </a>
                    <i aria-hidden='true'
                        className='right angle icon divider'></i>
                    <a href='/webcaptures' className='section'>
                        {t('menubar.webcaptures')}
                    </a>
                    <i aria-hidden='true'
                        className='right angle icon divider'></i>
                    {
                        {
                            'add':<div className='active section'>
                                {t('webcaptures.new')}</div>,
                            'view':<div className='active section'>
                                {t('webcaptures.view')}</div>
                        }[mode]
                    }
                </div>
                <br />

                <Menu tabular>
                    <Menu.Item
                        as='a'
                        href='/webcaptures'
                        name={t('webcaptures.active')}
                    />
                    {acl(this.props.auth,[['webcapture:archive']])&&
                        <Menu.Item
                            as='a'
                            href='/webcaptures/archive'
                            name={t('webcaptures.archived')}
                        />
                    }
                    {
                        {
                            'add':
                                <Menu.Item
                                    as='a'
                                    href='/webcaptures/add'
                                    name={t('webcaptures.new')}
                                    active
                                />,
                            'view':
                                <Menu.Item
                                    as='a'
                                    href={'/webcaptures/'+id+'/view'}
                                    name={t('webcaptures.view')}
                                    active
                                />
                        }[mode]
                    }
                </Menu>

                <Header as='h4' dividing>
                    {t('webcaptures.preview')}
                </Header>

                <Segment
                    basic
                    placeholder
                >
                    <Image
                        src={preview}
                        centered
                        bordered
                    />
                </Segment>

                <Dimmer.Dimmable dimmed={loading}>
                    <Form onSubmit={this.handleSubmit}>
                        <Header as='h4' dividing>
                            {t('webcaptures.website.title')}
                        </Header>

                        <Form.Group widths='equal'>
                            <Form.Field required={mode!=='view'}>
                                <label htmlFor='url'>
                                    {t('webcaptures.website.url')}
                                </label>
                                {mode==='view'?
                                    <Segment
                                        name='url'
                                        size='small'
                                    >
                                        {data.url}
                                    </Segment>:
                                    <Input
                                        name='url'
                                        type='url'
                                        value={data.url}
                                        onChange={this.handleChange}
                                        icon='world'
                                        iconPosition='left'
                                        required
                                    />
                                }
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field required={mode!=='view'}>
                                <label htmlFor='width'>
                                    {t('webcaptures.website.width')}
                                    &nbsp;
                                    ({t('webcaptures.website.pixels')})
                                </label>
                                {mode==='view'?
                                    <Segment
                                        name='width'
                                        size='small'
                                    >
                                        {data.params.width}
                                    </Segment>:
                                    <Input
                                        name='width'
                                        type='number'
                                        min='640'
                                        max='3840'
                                        value={data.params.width}
                                        onChange={this.handleChange}
                                        icon='arrows alternate horizontal'
                                        iconPosition='left'
                                        required
                                    />
                                }
                            </Form.Field>
                            <Form.Field required={mode!=='view'}>
                                <label htmlFor='height'>
                                    {t('webcaptures.website.height')}
                                    &nbsp;
                                    ({t('webcaptures.website.pixels')})
                                </label>
                                {mode==='view'?
                                    <Segment
                                        name='height'
                                        size='small'
                                    >
                                        {data.params.height}
                                    </Segment>:
                                    <Input
                                        name='height'
                                        type='number'
                                        min='360'
                                        max='2160'
                                        value={data.params.height||''}
                                        onChange={this.handleChange}
                                        icon='arrows alternate vertical'
                                        iconPosition='left'
                                        required
                                    />
                                }
                            </Form.Field>
                        </Form.Group>

                        <Divider />
                        {mode==='view'&&
                            <Button
                                icon
                                color='green'
                                labelPosition='right'
                                floated='right'
                                type='button'
                                title={t('webcaptures.pdf.download')}
                                target='_BLANK'
                                href={'/api/webcaptures/'+id+'/pdf'}
                                download={
                                    t('webcaptures.pdf.file')+' ('+
                                    moment(data.ts)
                                    .tz(timezone)
                                    .format('YYYY-MM-DD HH:mm:ss')+
                                    ')'
                                }
                            >
                                <Icon name='download' />
                                Descargar
                            </Button>
                        }
                        {{
                            'add':
                                <Fragment>
                                    <Button
                                        icon
                                        color='blue'
                                        labelPosition='left'
                                        type='submit'
                                    >
                                        <Icon name='save' />
                                        {t('utils.actions.save')}
                                    </Button>
                                    <Button
                                        icon
                                        labelPosition='left'
                                        type='button'
                                        data-name='preview'
                                        onClick={this.handleClick}
                                    >
                                        <Icon name='camera' />
                                        {t('utils.actions.preview')}
                                    </Button>
                                </Fragment>,
                            'view':
                                <Dropdown
                                    text={t('utils.actions.options')}
                                    icon='tasks'
                                    className='icon'
                                    labeled
                                    button
                                >
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            icon='box'
                                            content={t('utils.actions.archive')}
                                            data-name='archive'
                                            onClick={this.handleClick}
                                        />
                                    </Dropdown.Menu>
                                </Dropdown>
                        }[mode]}

                        <Modal
                            basic
                            size='small'
                            open={modal}
                            onClose={this.handleClose}
                        >
                            <Header
                                icon='archive'
                                content={t('webcaptures.confirm.archive.title')}
                            />

                            <Modal.Content>
                                <p>
                                    {t('webcaptures.confirm.archive.content')}
                                </p>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button
                                    basic
                                    inverted
                                    color='red'
                                    onClick={this.handleClose}
                                >
                                    <Icon name='remove' />
                                    {t('utils.bool.no')}
                                </Button>
                                <Button
                                    inverted
                                    color='green'
                                    data-name='modal-archive'
                                    onClick={this.handleClick}
                                >
                                    <Icon name='checkmark' />
                                    {t('utils.bool.yes')}
                                </Button>
                            </Modal.Actions>
                        </Modal>
                    </Form>

                    <Dimmer active={loading}>
                        <Loader
                            active={loading}
                            indeterminate>
                            {t('api.sending')}
                        </Loader>
                    </Dimmer>
                </Dimmer.Dimmable>
            </Container>
        );
    }
}

const mapStateToProps=(state)=>{
    return {
        auth:state.auth,
        data:state.data
    };
};

export default withTranslation('global')(
    connect(mapStateToProps)(WebcaptureViewer)
);

