import Cookies from 'js-cookie';
import {
    AUTH_IN,
    AUTH_OUT
} from '../constants';

const session=Cookies.get('firmanza.user.session')||'false',
    _permissions=Cookies.get('firmanza.user.permissions')||'[]',
    permissions=JSON.parse(_permissions),
    initialState={
        logged:session==='true'?true:false,
        permissions:permissions,
    };

const auth=(state=initialState,action)=>{
    switch(action.type){
        case AUTH_IN:
            return {
                logged:true,
                permissions:action.permissions
            };
        case AUTH_OUT:
            return {
                logged:false,
                permissions:[]
            };
        default:
            return state;
    }
};

export default auth;

