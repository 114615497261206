import React,{Fragment} from 'react';
import Files from 'react-butterfiles';
import {
    Button,
    Icon,
    Menu
} from 'semantic-ui-react';

export default ({
    type,
    className,
    children,
    handleClick,
    onSuccess,
    onError,
    t=((s)=>s)
})=>(
    <Files
        multiple={true}
        maxSize='1024mb'
        multipleMaxSize='1024mb'
        accept={['application/pdf']}
        onSuccess={onSuccess}
        onError={onError}
    >
        {({
            browseFiles,
            getDropZoneProps
        })=>(
            <Fragment>
                {{
                    'area':
                        <div
                            aria-hidden='true'
                            onClick={
                                handleClick?handleClick:browseFiles
                            }
                            {...getDropZoneProps({
                                className:className
                            })}
                        >
                            {children}
                        </div>,
                    'menuitem':
                        <Menu.Item
                            icon='cloud upload'
                            title={t('api.upload')}
                            onClick={browseFiles}
                        />,
                    'button':
                        <Button
                            icon
                            labelPosition='left'
                            title={t('api.upload')}
                            data-name='upload'
                            onClick={browseFiles}
                        >
                            <Icon name='upload' />
                            {t('api.upload')}
                        </Button>
                }[type]}
            </Fragment>
        )}
    </Files>
);

