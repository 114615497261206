import filesize from 'filesize';
import Cookies from 'js-cookie';
import moment from 'moment-timezone';
import React,{Component,Fragment} from 'react';
import {withTranslation} from 'react-i18next';
import {Document,Page,pdfjs} from 'react-pdf';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {
    Button,
    Container,
    Divider,
    Form,
    Grid,
    Header,
    Icon,
    Input,
    List,
    Menu,
    Modal,
    Progress,
    Table
} from 'semantic-ui-react';

import {
    Uploader
} from '../components';
import {
    TIMEZONE_DEFAULT
} from '../config';
import {
    UPDATE
} from '../constants';
import {
    apiFetch,
    message,
    acl
} from '../utils';

pdfjs.GlobalWorkerOptions.workerSrc='//cdnjs.cloudflare.com/ajax/libs/pdf.js/'+
    pdfjs.version+'/pdf.worker.js';

class DocumentList extends Component{
    constructor(props){
        super(props);

        this.state={
            folders:0,
            files:0,
            items:[],
            path:'/',
            search:'',
            folder:'',
            file:'',
            selected:{
                name:'',
                path:'',
                type:'',
                id:''
            },
            view:'list',
            modal:false,
            modal_type:'',
            clipboard:{
                path:'',
                action:''
            },
            viewer:{
                page:1,
                total:1
            },
            timezone:this.props.data.timezone||TIMEZONE_DEFAULT,
            bookmarks:this.props.data.bookmarks||[],
            count:0,
            max:this.props.data.quota||128
        };

        this.handleUpload=this.handleUpload.bind(this);
        this.handleError=this.handleError.bind(this);
        this.handleClose=this.handleClose.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.handleClick=this.handleClick.bind(this);
        this.handleSelect=this.handleSelect.bind(this);
        this.handleDeselect=this.handleDeselect.bind(this);
        this.handleDocument=this.handleDocument.bind(this);
    }

    loadData(search,path){
        const {t}=this.props;

        apiFetch({
            url:'/api/documents?'+[
                'search='+search,
                'path='+path
            ].join('&'),
            method:'GET',
            success:(data)=>{
                path+=path.endsWith('/')?'':'/';

                this.setState({
                    folders:data.folders,
                    files:data.files,
                    count:data.quota,
                    items:data.list,
                    path:path,
                    search:search,
                    selected:{
                        name:'',
                        path:'',
                        type:'',
                        id:''
                    }
                });
            },
            error:(error)=>{
                this.setState({
                    error:error
                });
            },
            t:t
        });
    }

    componentDidMount(){
        const {
                path
            }=this.state,
            {t}=this.props;

        apiFetch({
            url:'/api/settings/data',
            method:'GET',
            success:(data)=>{
                let obj=JSON.parse(data.data);

                this.setState({
                    timezone:obj.timezone,
                    bookmarks:obj.bookmarks||[],
                    max:obj.quota
                });

                this.loadData('',path);
            },
            error:(error)=>{
                this.setState({
                    error:error
                });
            },
            t:t
        });
    }

    handleUpload(files){
        const {
                path
            }=this.state,
            {t}=this.props;

        let formData=new FormData(),
            status;

        files.forEach((file)=>{
            formData.append(
                'file',
                file.src.file,
                file.src.file.name
            );
        });

        fetch('/api/documents?path='+path,{
            method:'POST',
            credentials:'same-origin',
            headers:{
                'Accept':'application/json',
                'CSRF-Token':Cookies.get('firmanza.user.csrf')
            },
            body:formData
        })
        .then((response)=>{
            status=response.status;

            return response.json();
        })
        .then((body)=>{
            if(status===200){
                body.results.forEach((result)=>{
                    switch(result.code){
                        case 'OK':
                            message(true,result.message,t,result.values);

                            break;
                        default:
                            message(false,result.message,t,result.values);
                    }
                });

                this.componentDidMount();
            }else{
                message(false,body.message,t);
            }
        })
        .catch((e)=>{
            console.log(e);

            message(false,'api.error',t);
        });
    }

    handleError(errors){
        const {t}=this.props;

        errors.forEach((error)=>{
            message(false,{
                'unsupportedFileType':'messages.pdf.unsupportedFileType',
                'maxSizeExceeded':'messages.pdf.maxSizeExceeded'
            }[error.type],t);
        });
    }

    handleClose(){
        this.setState({
            modal:false
        });
    }

    handleChange(event,data){
        let field=event.target.name;

        this.setState({
            [field]:data.value
        });
    }

    handleClick(event,data){
        const {
                path,
                search,
                folder,
                file,
                selected,
                clipboard,
                viewer,
                timezone,
                bookmarks
            }=this.state,
            {t}=this.props;

        event.stopPropagation();

        switch(data['data-name']){
            case 'documents':
                this.loadData('','/');

                break;
            case 'certificates':
                this.loadData('','certificates://');

                break;
            case 'webcaptures':
                this.loadData('','webcaptures://');

                break;
            case 'mails':
                this.loadData('','mails://');

                break;
            case 'workflows':
                this.loadData('','workflows://');

                break;
            case 'recycle':
                this.loadData('','recycle://');

                break;
            case 'bookmark':
                this.loadData('',data['data-path']);

                break;
            case 'add-bookmark':
                bookmarks.push(path);

                apiFetch({
                    url:'/api/settings/data',
                    method:'POST',
                    data:{
                        timezone:timezone,
                        bookmarks:bookmarks,
                    },
                    success:(data)=>{
                        message(data.ok,data.message,t);

                        this.componentDidMount();
                    },
                    error:(error)=>{
                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            case 'remove-bookmark':
                bookmarks.splice(bookmarks.indexOf(path),1);

                apiFetch({
                    url:'/api/settings/data',
                    method:'POST',
                    data:{
                        timezone:timezone,
                        bookmarks:bookmarks,
                    },
                    success:(data)=>{
                        message(data.ok,data.message,t);

                        this.componentDidMount();
                    },
                    error:(error)=>{
                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            case 'home':
                this.loadData('','/');

                break;
            case 'up':{
                let parent='/';

                if(!/[a-z]+:\/\//.test(path)){
                    parent=path.split('/').slice(0,-2).join('/');
                }

                this.setState({
                    path:parent,
                    search:''
                });
                this.loadData('',parent);

                break;
            }
            case 'folder':
                this.setState({
                    modal:true,
                    modal_type:'folder'
                });

                break;
            case 'modal-folder':
                if(document.querySelector('input[name="folder"]')
                .reportValidity()){
                    apiFetch({
                        url:'/api/documents/actions?'+[
                            'action=mkdir',
                            'path='+encodeURIComponent(path+folder)
                        ].join('&'),
                        method:'POST',
                        success:(data)=>{
                            this.setState({
                                modal:false,
                                folder:''
                            });

                            message(data.ok,data.message,t);

                            this.componentDidMount();
                        },
                        error:(error)=>{
                            message(false,error.body.message,t);
                        },
                        t:t
                    });
                }

                break;
            case 'view-icon':
                this.setState({
                    view:'icon'
                });

                break;
            case 'view-list':
                this.setState({
                    view:'list'
                });

                break;
            case 'file':
                this.setState({
                    modal:true,
                    modal_type:'file'
                });

                break;
            case 'open-folder':
                this.loadData('',selected.path);

                break;
            case 'rename':
                this.setState({
                    modal:true,
                    modal_type:'rename',
                    file:selected.name
                });

                break;
            case 'modal-rename':
                if(selected.name===file){
                    return;
                }

                if(document.querySelector('input[name="file"]')
                .reportValidity()){
                    apiFetch({
                        url:'/api/documents/actions?'+[
                            'action=move',
                            'path='+encodeURIComponent(selected.path),
                            'dest='+encodeURIComponent(path+file)
                        ].join('&'),
                        method:'POST',
                        success:(data)=>{
                            this.setState({
                                modal:false,
                                file:''
                            });

                            message(data.ok,data.message,t);

                            this.componentDidMount();
                        },
                        error:(error)=>{
                            message(false,error.body.message,t);
                        },
                        t:t
                    });
                }

                break;
            case 'clone':
                apiFetch({
                    url:'/api/documents/actions?'+[
                        'action=clone',
                        'path='+encodeURIComponent(selected.path)
                    ].join('&'),
                    method:'POST',
                    success:(data)=>{
                        this.setState({
                            modal:false,
                            file:''
                        });

                        message(data.ok,data.message,t);

                        this.componentDidMount();
                    },
                    error:(error)=>{
                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            case 'copy':
                this.setState({
                    clipboard:{
                        path:selected.path,
                        action:'copy'
                    }
                });

                break;
            case 'move':
                this.setState({
                    clipboard:{
                        path:selected.path,
                        action:'move'
                    }
                });

                break;
            case 'paste':
                apiFetch({
                    url:'/api/documents/actions?'+[
                        'action='+clipboard.action,
                        'path='+encodeURIComponent(clipboard.path),
                        'dest='+encodeURIComponent(path)
                    ].join('&'),
                    method:'POST',
                    success:(data)=>{
                        this.setState({
                            modal:false,
                            file:''
                        });

                        message(data.ok,data.message,t);

                        this.componentDidMount();
                    },
                    error:(error)=>{
                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            case 'repair':
                apiFetch({
                    url:'/api/documents/repair?'+[
                        'action=repair',
                        'path='+encodeURIComponent(selected.path)
                    ].join('&'),
                    method:'POST',
                    success:(data)=>{
                        this.setState({
                            modal:false,
                            file:''
                        });

                        message(data.ok,data.message,t);

                        this.componentDidMount();
                    },
                    error:(error)=>{
                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            case 'archive':
                this.setState({
                    modal:true,
                    modal_type:'archive'
                });

                break;
            case 'modal-archive':
                apiFetch({
                    url:'/api/documents?'+[
                        'path='+encodeURIComponent(selected.path)
                    ].join('&'),
                    method:'DELETE',
                    success:(data)=>{
                        this.setState({
                            modal:false,
                        });

                        message(data.ok,data.message,t);

                        this.componentDidMount();
                    },
                    error:(error)=>{
                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            case 'restore':
                this.setState({
                    modal:true,
                    modal_type:'restore'
                });

                break;
            case 'modal-restore':
                apiFetch({
                    url:'/api/documents/actions?'+[
                        'action=restore',
                        'path='+encodeURIComponent(selected.path)
                    ].join('&'),
                    method:'POST',
                    success:(data)=>{
                        this.setState({
                            modal:false,
                            file:''
                        });

                        message(data.ok,data.message,t);

                        this.componentDidMount();
                    },
                    error:(error)=>{
                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            case 'trash':
                this.setState({
                    modal:true,
                    modal_type:'trash'
                });

                break;
            case 'modal-trash':
                apiFetch({
                    url:'/api/documents',
                    method:'DELETE',
                    success:(data)=>{
                        this.setState({
                            modal:false,
                        });

                        message(data.ok,data.message,t);

                        this.componentDidMount();
                    },
                    error:(error)=>{
                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            case 'redirect':{
                const resource=path.slice(0,-3),
                    id=selected.id;

                switch(resource){
                    case 'workflows':
                        this.props.history.push(
                            '/'+resource+'/'+id+'/view?step=0'
                        );

                        break;
                    default:
                        this.props.history.push(
                            '/'+resource+'/'+id+'/view'
                        );
                }

                break;
            }
            case 'path':
                this.setState({
                    search:''
                });
                this.loadData('',path);

                break;
            case 'search':
                this.loadData(search,path);

                break;
            case 'file-start':
                this.setState({
                    viewer:{
                        ...viewer,
                        page:1
                    }
                });

                break;
            case 'file-previous':
                if(viewer.page>1){
                    this.setState({
                        viewer:{
                            ...viewer,
                            page:viewer.page-1
                        }
                    });
                }

                break;
            case 'file-next':
                if(viewer.page<viewer.total){
                    this.setState({
                        viewer:{
                            ...viewer,
                            page:viewer.page+1
                        }
                    });
                }

                break;
            case 'file-end':
                this.setState({
                    viewer:{
                        ...viewer,
                        page:viewer.total
                    }
                });

                break;
            default:
                return;
        }
    }

    handleSelect(item){
        this.setState({
            selected:item
        });
    }

    handleDeselect(){
        this.setState({
            selected:{
                name:'',
                path:'',
                type:''
            }
        });
    }

    handleDocument(data){
        this.setState({
            viewer:{
                page:1,
                total:data.numPages
            }
        });
    }

    renderSidebar(){
        const {
                bookmarks
            }=this.state,
            {t}=this.props;

        return (
            <List animated verticalAlign='middle'>
                <List.Item
                    data-name='documents'
                    onClick={this.handleClick}
                >
                    <Icon name='folder' size='large' />
                    <List.Content>
                        <List.Header>/</List.Header>
                    </List.Content>
                </List.Item>
                {acl(this.props.auth,[['certificate:list']])&&
                    <List.Item
                        data-name='certificates'
                        onClick={this.handleClick}
                    >
                        <Icon name='address card' size='large' />
                        <List.Content>
                            <List.Header>
                                {t('menubar.certificates')}
                            </List.Header>
                        </List.Content>
                    </List.Item>
                }
                {acl(this.props.auth,[['mail:list']])&&
                    <List.Item
                        data-name='mails'
                        onClick={this.handleClick}
                    >
                        <Icon name='mail' size='large' />
                        <List.Content>
                            <List.Header>
                                {t('menubar.emails')}
                            </List.Header>
                        </List.Content>
                    </List.Item>
                }
                {acl(this.props.auth,[['webcapture:list']])&&
                    <List.Item
                        data-name='webcaptures'
                        onClick={this.handleClick}
                    >
                        <Icon name='camera' size='large' />
                        <List.Content>
                            <List.Header>
                                {t('menubar.webcaptures')}
                            </List.Header>
                        </List.Content>
                    </List.Item>
                }
                {acl(this.props.auth,[['workflow:list']])&&
                    <List.Item
                        data-name='workflows'
                        onClick={this.handleClick}
                    >
                        <Icon name='list alternate' size='large' />
                        <List.Content>
                            <List.Header>
                                {t('menubar.workflows')}
                            </List.Header>
                        </List.Content>
                    </List.Item>
                }
                {acl(this.props.auth,[['document:delete']])&&
                    <List.Item
                        data-name='recycle'
                        onClick={this.handleClick}
                    >
                        <Icon name='trash' size='large' />
                        <List.Content>
                            <List.Header>
                                {t('menubar.recycle')}
                            </List.Header>
                        </List.Content>
                    </List.Item>
                }
                <Divider />
                {bookmarks&&bookmarks.length!==0&&
                    <List.Item>
                        <List.Header>
                            {t('documents.bookmarks')}
                        </List.Header>
                    </List.Item>
                }
                {bookmarks&&bookmarks.map((item,i)=>(
                    <List.Item
                        key={'bookmark-'+i}
                        data-name='bookmark'
                        data-path={item}
                        onClick={this.handleClick}
                    >
                        <Icon name='bookmark' size='large' />
                        <List.Content>
                            <List.Header>
                                {item}
                            </List.Header>
                        </List.Content>
                    </List.Item>
                ))}
            </List>
        );
    }

    renderIcons(items){
        const {
            selected
        }=this.state;

        return (
            <div className='fs'>
                {items.map((item,i)=>(
                    <button
                        key={'icon-'+i}
                        className={[
                            'fs-item',
                            selected.name===item.name?'selected':''
                        ].join(' ')}
                        onClick={(event)=>{
                            event.stopPropagation();

                            this.handleSelect(item);
                        }}
                        title={item.path}
                    >
                        <Icon
                            name={item.type}
                            size='huge'
                        />
                        <p>{item.name}</p>
                    </button>
                ))}
            </div>
        );
    }

    renderList(items){
        const {
                selected,
                timezone
            }=this.state,
            {t}=this.props;

        return (
            <Table celled selectable compact='very'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Nombre</Table.HeaderCell>
                        <Table.HeaderCell textAlign='center'>
                            {t('utils.headers.size')}
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign='center'>
                            {t('utils.headers.date')}
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {items.map((item,i)=>(
                        <Table.Row
                            key={'list-'+i}
                            className={[
                                selected.name===item.name?'active':''
                            ].join(' ')}
                            onClick={(event)=>{
                                event.stopPropagation();

                                this.handleSelect(item);
                            }}
                            title={item.path}
                        >
                            <Table.Cell>
                                <Icon name={item.type} />
                                {item.name}
                            </Table.Cell>
                            <Table.Cell textAlign='center'>
                                {item.type!=='folder'&&filesize(item.size)}
                            </Table.Cell>
                            <Table.Cell textAlign='center'>
                                {moment(item.ts)
                                .tz(timezone)
                                .format('YYYY-MM-DD HH:mm:ss')}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        );
    }

    renderToolbar(){
        const {
                path,
                selected,
                view,
                clipboard,
                bookmarks
            }=this.state,
            {t}=this.props;

        var bookmark_type='add';

        if([
            '/',
            'certificates://',
            'mails://',
            'webcaptures://',
            'workflows://',
            'recycle://'
        ].includes(path)){
            bookmark_type='disabled';
        }else if(bookmarks.includes(path)){
            bookmark_type='remove';
        }

        return (
            <Menu
                className='toolbar'
                size='small'
                icon
            >
                <Menu.Item
                    icon={[
                        'bookmark',
                        bookmark_type==='remove'?'':'outline'
                    ].join(' ').trim()}
                    title={
                        bookmark_type==='remove'?
                            t('documents.bookmars.remove'):
                            t('documents.bookmars.add')
                    }
                    data-name={
                        bookmark_type==='remove'?
                            'remove-bookmark':
                            'add-bookmark'
                    }
                    onClick={this.handleClick}
                    disabled={bookmark_type==='disabled'}
                />
                &nbsp;
                &nbsp;
                <Menu.Item
                    icon='home'
                    title={t('documents.home')}
                    data-name='home'
                    onClick={this.handleClick}
                />
                <Menu.Item
                    icon='arrow up'
                    title={t('documents.up')}
                    data-name='up'
                    onClick={this.handleClick}
                />
                {
                    !/[a-z]+:\/\//.test(path)&&
                    acl(this.props.auth,[['document:upload']])&&
                    <Uploader
                        onSuccess={this.handleUpload}
                        onError={this.handleError}
                        setState={this.setState}
                        type='menuitem'
                        t={t}
                    />
                }
                {
                    !/[a-z]+:\/\//.test(path)&&
                    acl(this.props.auth,[['document:upload']])&&
                    <Menu.Item
                        icon='folder'
                        title={t('documents.folder.create')}
                        data-name='folder'
                        onClick={this.handleClick}
                    />
                }
                &nbsp;
                &nbsp;
                <Menu.Item
                    icon='th'
                    title={t('documents.view.icon')}
                    active={view==='icon'}
                    data-name='view-icon'
                    onClick={this.handleClick}
                />
                <Menu.Item
                    icon='th list'
                    title={t('documents.view.list')}
                    active={view==='list'}
                    data-name='view-list'
                    onClick={this.handleClick}
                />
                &nbsp;
                &nbsp;
                {!/[a-z]+:\/\//.test(path)&&
                    selected.name!==''&&
                    selected.type==='file'&&
                    <Menu.Item
                        icon='eye'
                        title={t('documents.view.file')}
                        data-name='file'
                        onClick={this.handleClick}
                    />
                }
                {!/[a-z]+:\/\//.test(path)&&
                    selected.name!==''&&
                    selected.type==='file'&&
                    <Menu.Item
                        as='a'
                        icon='download'
                        title={t('documents.download')}
                        href={'/api/documents/view?path='+selected.path}
                        download={selected.name}
                    />
                }
                {!/[a-z]+:\/\//.test(path)&&
                    selected.name!==''&&
                    selected.type==='folder'&&
                    <Menu.Item
                        icon='folder open'
                        title={t('documents.folder.open')}
                        data-name='open-folder'
                        onClick={this.handleClick}
                    />
                }
                &nbsp;
                &nbsp;
                {
                    !/[a-z]+:\/\//.test(path)&&
                    acl(this.props.auth,[['document:upload']])&&
                    selected.name!==''&&
                    <Menu.Item
                        icon='text cursor'
                        title={t('documents.rename')}
                        data-name='rename'
                        onClick={this.handleClick}
                    />
                }
                {
                    !/[a-z]+:\/\//.test(path)&&
                    acl(this.props.auth,[['document:upload']])&&
                    selected.name!==''&&
                    <Menu.Item
                        icon='clone'
                        title={t('documents.clone')}
                        data-name='clone'
                        onClick={this.handleClick}
                    />
                }
                {
                    !/[a-z]+:\/\//.test(path)&&
                    acl(this.props.auth,[['document:upload']])&&
                    selected.name!==''&&
                    <Menu.Item
                        icon='copy outline'
                        title={t('documents.copy')}
                        data-name='copy'
                        onClick={this.handleClick}
                    />
                }
                {
                    !/[a-z]+:\/\//.test(path)&&
                    acl(this.props.auth,[['document:upload']])&&
                    selected.name!==''&&
                    <Menu.Item
                        icon='cut'
                        title={t('documents.move')}
                        data-name='move'
                        onClick={this.handleClick}
                    />
                }
                {
                    !/[a-z]+:\/\//.test(path)&&
                    acl(this.props.auth,[['document:upload']])&&
                    clipboard.path!==''&&
                    <Menu.Item
                        icon='paste'
                        title={t('documents.paste')}
                        data-name='paste'
                        onClick={this.handleClick}
                    />
                }
                &nbsp;
                &nbsp;
                {!/[a-z]+:\/\//.test(path)&&
                    selected.name!==''&&
                    selected.type==='file'&&
                    <Menu.Item
                        icon='wrench'
                        title={t('documents.repair')}
                        data-name='repair'
                        onClick={this.handleClick}
                    />
                }
                {
                    !/[a-z]+:\/\//.test(path)&&
                    acl(this.props.auth,[['document:delete']])&&
                    selected.name!==''&&
                    <Menu.Item
                        icon='trash'
                        title={t('documents.archive')}
                        data-name='archive'
                        onClick={this.handleClick}
                    />
                }
                {/recycle:\/\//.test(path)&&
                    selected.name!==''&&
                    <Menu.Item
                        icon='undo'
                        title={t('documents.restore')}
                        data-name='restore'
                        onClick={this.handleClick}
                    />
                }
                {
                    /recycle:\/\//.test(path)&&
                    selected.name!==''&&
                    acl(this.props.auth,[['document:delete']])&&
                    <Menu.Item
                        icon='trash'
                        title={t('documents.delete')}
                        data-name='archive'
                        onClick={this.handleClick}
                    />
                }
                {
                    /recycle:\/\//.test(path)&&
                    acl(this.props.auth,[['document:delete']])&&
                    <Menu.Item
                        icon='recycle'
                        title={t('documents.clean')}
                        data-name='trash'
                        onClick={this.handleClick}
                    />
                }
                {
                    /(certificates|mails|webcaptures|workflows):\/\//
                    .test(path)&&
                    selected.name!==''&&
                    <Menu.Item
                        icon='share'
                        title={t('documents.view.resource')}
                        data-name='redirect'
                        onClick={this.handleClick}
                    />
                }
            </Menu>
        );
    }

    render(){
        const {
                items,
                path,
                search,
                folder,
                file,
                selected,
                view,
                modal,
                modal_type,
                viewer,
                count,
                max
            }=this.state,
            {t}=this.props;

        if(
            !this.props.auth.logged||
            !acl(this.props.auth,[['document:list']])
        ){
            return (
                <Redirect to='/' />
            );
        }

        return (
            <Container fluid>
                <Header as='h1'>{t('menubar.documents')}</Header>

                <div className='ui breadcrumb'>
                    <a href='/' className='section'>
                        {t('menubar.home')}
                    </a>
                    <i aria-hidden='true'
                        className='right angle icon divider'></i>
                    <div className='active section'>
                        {t('menubar.documents')}
                    </div>
                </div>
                <Divider />

                <Grid>
                    <Grid.Column width={4}>
                        <Progress
                            value={count}
                            total={max*1024}
                            progress='percent'
                            precision={2}
                            size='small'
                            color={[
                                'black',
                                'purple',
                                'violet',
                                'blue',
                                'teal',
                                'green',
                                'olive',
                                'yellow',
                                'orange',
                                'red'
                            ][
                            Math.floor((count*10)/(max*1024))
                            ]}
                        >
                            {Math.round(count/1024*100)/100} / {max} MiB
                        </Progress>
                    </Grid.Column>
                    <Grid.Column width={9}>
                        <Form>
                            <Input
                                name='path'
                                size='small'
                                iconPosition='left'
                                icon={{name:'at'}}
                                value={path}
                                onChange={this.handleChange}
                                action={
                                    <Button
                                        icon
                                        data-name='path'
                                        onClick={this.handleClick}
                                    >
                                        <Icon name='sync' />
                                    </Button>
                                }
                                fluid
                            />
                        </Form>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <Form>
                            <Input
                                name='search'
                                size='small'
                                placeholder={t('api.search')}
                                value={search}
                                onChange={this.handleChange}
                                action={
                                    <Button
                                        icon
                                        data-name='search'
                                        onClick={this.handleClick}
                                    >
                                        <Icon name='search' />
                                    </Button>
                                }
                                fluid
                            />
                        </Form>
                    </Grid.Column>
                </Grid>

                <Divider />
                <Grid columns={2} divided>
                    <Grid.Row stretched>
                        <Grid.Column width={4}>
                            {this.renderSidebar()}
                        </Grid.Column>
                        <Grid.Column width={12}>
                            {this.renderToolbar()}
                            <Uploader
                                onSuccess={this.handleUpload}
                                onError={this.handleError}
                                setState={this.setState}
                                handleClick={this.handleDeselect}
                                className='uploader wide'
                                type='area'
                                t={t}
                            >
                                {{
                                    'icon':this.renderIcons(items),
                                    'list':this.renderList(items)
                                }[view]}
                            </Uploader>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <Modal
                    basic
                    size='small'
                    open={modal}
                    onClose={this.handleClose}
                >
                    {modal_type==='folder'&&
                        <Header icon='folder open'
                            content={t('documents.confirm.folder.title')}
                        />
                    }
                    {modal_type==='rename'&&
                        <Header icon='text cursor'
                            content={t('documents.confirm.rename.title')}
                        />
                    }
                    {modal_type==='archive'&&
                        !/[a-z]+:\/\//.test(path)&&
                        <Header icon='trash'
                            content={t('documents.confirm.recycle.title')}
                        />
                    }
                    {modal_type==='restore'&&
                        /[a-z]+:\/\//.test(path)&&
                        <Header icon='undo'
                            content={t('documents.confirm.restore.title')}
                        />
                    }
                    {modal_type==='archive'&&
                        /[a-z]+:\/\//.test(path)&&
                        <Header icon='trash'
                            content={t('documents.confirm.archive.title')}
                        />
                    }
                    {modal_type==='trash'&&
                        /[a-z]+:\/\//.test(path)&&
                        <Header icon='recycle'
                            content={t('documents.confirm.clean.title')}
                        />
                    }

                    <Modal.Content>
                        {modal_type==='folder'&&
                            <Fragment>
                                <p>
                                    {t('documents.confirm.folder.content')}
                                </p>
                                <Form>
                                    <Form.Group widths='equal'>
                                        <Form.Field required>
                                            <Input
                                                type='text'
                                                name='folder'
                                                value={folder}
                                                onChange={this.handleChange}
                                                pattern='^[\w\-,.#@ ]+$'
                                                title={t('documents.confirm.'+
                                                    'folder.constraint')}
                                                required
                                            />
                                        </Form.Field>
                                    </Form.Group>
                                </Form>
                            </Fragment>
                        }
                        {modal_type==='file'&&
                            <Document
                                className='viewer'
                                renderMode='canvas'
                                onLoadSuccess={this.handleDocument}
                                file={'/api/documents/view?path='+
                                    encodeURIComponent(selected.path)
                                }
                            >
                                <Page
                                    loading={t('api.document')}
                                    pageNumber={viewer.page}
                                    renderMode='canvas'
                                    height={760}
                                />
                            </Document>
                        }
                        {modal_type==='rename'&&
                            <Fragment>
                                <p>
                                    {t('documents.confirm.rename.content')}
                                </p>
                                <Form>
                                    <Form.Group widths='equal'>
                                        <Form.Field required>
                                            <Input
                                                type='text'
                                                name='file'
                                                value={file}
                                                onChange={this.handleChange}
                                                pattern='^[\w\-,.#@ ]+$'
                                                title={t('documents.confirm.'+
                                                    'rename.constraint')}
                                                required
                                            />
                                        </Form.Field>
                                    </Form.Group>
                                </Form>
                            </Fragment>
                        }
                        {modal_type==='archive'&&
                            !/[a-z]+:\/\//.test(path)&&
                            <p>
                                {t('documents.confirm.recycle.content')}
                            </p>
                        }
                        {modal_type==='restore'&&
                            /[a-z]+:\/\//.test(path)&&
                            <p>
                                {t('documents.confirm.restore.content')}
                            </p>
                        }
                        {modal_type==='archive'&&
                            /[a-z]+:\/\//.test(path)&&
                            <p>
                                {t('documents.confirm.archive.content')}
                            </p>
                        }
                        {modal_type==='trash'&&
                            /[a-z]+:\/\//.test(path)&&
                            <p>
                                {t('documents.confirm.clean.content')}
                            </p>
                        }
                    </Modal.Content>
                    <Modal.Actions>
                        <Fragment>
                            {modal_type==='file'&&
                                <Button.Group floated='left'>
                                    <Button
                                        icon
                                        inverted
                                        data-name='file-start'
                                        onClick={this.handleClick}
                                    >
                                        <Icon name='step backward' />
                                    </Button>
                                    <Button
                                        icon
                                        inverted
                                        data-name='file-previous'
                                        onClick={this.handleClick}
                                    >
                                        <Icon name='angle left' />
                                    </Button>
                                    <Button
                                        basic
                                        inverted
                                    >
                                        {viewer.page}&nbsp;/&nbsp;{viewer.total}
                                    </Button>
                                    <Button
                                        icon
                                        inverted
                                        data-name='file-next'
                                        onClick={this.handleClick}
                                    >
                                        <Icon name='angle right' />
                                    </Button>
                                    <Button
                                        icon
                                        inverted
                                        data-name='file-end'
                                        onClick={this.handleClick}
                                    >
                                        <Icon name='step forward' />
                                    </Button>
                                </Button.Group>
                            }
                            <Button.Group floated='right'>
                                {modal_type==='file'&&
                                    <Button
                                        basic
                                        inverted
                                        href={
                                            '/api/documents/view?path='+
                                            selected.path
                                        }
                                        download={selected.name}
                                    >
                                        <Icon name='download' />
                                        {t('documents.download')}
                                    </Button>
                                }
                                <Button
                                    basic
                                    inverted
                                    color={modal_type!=='file'?'red':'green'}
                                    onClick={this.handleClose}
                                >
                                    <Icon name='remove' />
                                    {{
                                        'folder':t('utils.actions.close'),
                                        'file':t('utils.actions.close'),
                                        'rename':t('utils.actions.close'),
                                        'restore':t('utils.bool.no'),
                                        'archive':t('utils.bool.no'),
                                        'trash':t('utils.bool.no')
                                    }[modal_type]}
                                </Button>
                                {modal_type!=='file'&&
                                    <Button
                                        inverted
                                        color='green'
                                        data-name={'modal-'+modal_type}
                                        onClick={this.handleClick}
                                    >
                                        <Icon name='checkmark' />
                                        {{
                                            'folder':t('utils.actions.folder'),
                                            'rename':t('utils.actions.rename'),
                                            'restore':t('utils.bool.yes'),
                                            'archive':t('utils.bool.yes'),
                                            'trash':t('utils.bool.yes')
                                        }[modal_type]}
                                    </Button>
                                }
                            </Button.Group>
                        </Fragment>
                    </Modal.Actions>
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps=(state)=>{
    return {
        auth:state.auth,
        data:state.data
    };
};

const mapDispatchToProps=(dispatch)=>{
    return {
        data:(timezone,bookmarks,quota)=>{
            dispatch({
                type:UPDATE,
                timezone:timezone,
                bookmarks:bookmarks,
                quota:quota
            });
        }
    };
};

export default withTranslation('global')(
    connect(mapStateToProps,mapDispatchToProps)(DocumentList)
);

