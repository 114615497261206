import moment from 'moment-timezone';
import React,{Component,Fragment} from 'react';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {
    Button,
    Container,
    Grid,
    Header,
    Icon,
    Input,
    Menu,
    Message,
    Modal,
    Table
} from 'semantic-ui-react';

import {
    TIMEZONE_DEFAULT
} from '../config';
import {
    apiFetch,
    message,
    acl
} from '../utils';

class TemplateFieldsList extends Component{
    constructor(props){
        super(props);

        this.state={
            error:null,
            isLoaded:false,
            total:0,
            items:[],
            shareable:false,
            search:'',
            page:1,
            modal:false,
            modal_type:'',
            timezone:this.props.data.timezone||TIMEZONE_DEFAULT
        };

        this.handleSearch=this.handleSearch.bind(this);
        this.handlePagination=this.handlePagination.bind(this);
        this.handleClose=this.handleClose.bind(this);
        this.handleClick=this.handleClick.bind(this);
    }

    loadData(search,page){
        const {t}=this.props;

        apiFetch({
            url:'/api/templates/fields',
            method:'GET',
            success:(data)=>{
                this.setState({
                    isLoaded:true,
                    total:data.total,
                    items:data.list,
                    shareable:data.shareable,
                    search:search,
                    page:page
                });
            },
            error:(error)=>{
                this.setState({
                    isLoaded:true,
                    error:error
                });
            },
            t:t
        });
    }

    componentDidMount(){
        const {t}=this.props;

        apiFetch({
            url:'/api/settings/data',
            method:'GET',
            success:(data)=>{
                let obj=JSON.parse(data.data);

                this.setState({
                    timezone:obj.timezone
                });

                this.loadData('',1);
            },
            error:(error)=>{
                this.setState({
                    error:error
                });
            },
            t:t
        });
    }

    handleSearch(event,data){
        this.loadData(data.value,1);
    }

    handlePagination(event,data){
        const {
            search
        }=this.state;

        this.loadData(search,data.activePage);
    }

    handleClose(){
        this.setState({
            modal:false
        });

        this.loadData('',1);
    }

    handleClick(event,data){
        const {
                items,
                modal_index
            }=this.state,
            {t}=this.props;

        event.stopPropagation();

        let index=+data['data-index'];

        switch(data['data-name']){
            case 'unshare':
                apiFetch({
                    url:'/api/templates/fields/'+items[index]._id+'/unshare',
                    method:'POST',
                    success:(data)=>{
                        this.loadData('',1);

                        message(data.ok,data.message,t);
                    },
                    error:(error)=>{
                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            case 'share':
                apiFetch({
                    url:'/api/templates/fields/'+items[index]._id+'/share',
                    method:'POST',
                    success:(data)=>{
                        this.loadData('',1);

                        message(data.ok,data.message,t);
                    },
                    error:(error)=>{
                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            case 'remove':
                this.setState({
                    modal:true,
                    modal_type:'remove',
                    modal_index:index
                });

                break;
            case 'modal-remove':
                apiFetch({
                    url:'/api/templates/fields/'+items[modal_index]._id,
                    method:'DELETE',
                    success:(data)=>{
                        this.setState({
                            modal:false,
                            modal_type:''
                        });
                        this.loadData('',1);

                        message(data.ok,data.message,t);
                    },
                    error:(error)=>{
                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            default:
                return;
        }
    }

    render(){
        const {
                error,
                isLoaded,
                items,
                shareable,
                modal,
                modal_type,
                timezone
            }=this.state,
            {t}=this.props;

        if(
            !this.props.auth.logged||
            !acl(this.props.auth,[['templates:fields']])
        ){
            return (
                <Redirect to='/' />
            );
        }

        return (
            <Container fluid>
                <Header as='h1'>{t('menubar.templates')}</Header>

                <div className='ui breadcrumb'>
                    <a href='/' className='section'>
                        {t('menubar.home')}
                    </a>
                    <i aria-hidden='true'
                        className='right angle icon divider'></i>
                    <div className='active section'>
                        {t('templates.fields.title')}
                    </div>
                </div>
                <br />

                <Menu tabular>
                    <Menu.Item
                        as='a'
                        href='/templates/qa'
                        name={t('templates.q&a.title')}
                    />
                    <Menu.Item
                        as='a'
                        href='/templates/fields'
                        name={t('templates.fields.title')}
                        active
                    />
                </Menu>

                <Grid>
                    <Grid.Column width={12}>
                        <Button
                            basic
                            color='black'
                            href='/templates/fields/add'
                        >
                            <Icon name='plus' />
                            {t('templates.add')}
                        </Button>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Input
                            className='search-input'
                            size='small'
                            icon={{
                                name:'search',
                                circular:true,
                                link:true
                            }}
                            placeholder={t('api.search')}
                            onChange={this.handleSearch}
                        />
                    </Grid.Column>
                </Grid>

                <Table compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                {t('utils.headers.details')}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                textAlign='center'
                                width={3}
                            >
                                {t('utils.headers.date')}
                            </Table.HeaderCell>
                            {shareable&&
                                <Table.HeaderCell>
                                    &nbsp;
                                </Table.HeaderCell>
                            }
                            <Table.HeaderCell width={2}>
                                &nbsp;
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {error&&
                            <Table.Row>
                                <Table.Cell colSpan='4'>
                                    <Message negative>
                                        <Message.Header>
                                            {t('api.emptytable')}
                                        </Message.Header>
                                        <p>
                                            {t(error.body.message)}
                                        </p>
                                    </Message>
                                </Table.Cell>
                            </Table.Row>
                        }
                        {!isLoaded&&
                            <Table.Row>
                                <Table.Cell colSpan='4'>
                                    <Message icon info>
                                        <Icon name='circle notched' loading />
                                        <Message.Content>
                                            <Message.Header>
                                                {t('api.wait')}
                                            </Message.Header>
                                            <p>
                                                {t('api.loading')}
                                            </p>
                                        </Message.Content>
                                    </Message>
                                </Table.Cell>
                            </Table.Row>
                        }
                        {items.map((item,i)=>(
                            <Table.Row
                                key={item._id}
                                active={item.owner}
                            >
                                <Table.Cell>
                                    <Header as='h4' image>
                                        <Header.Content>
                                            {item.name}
                                            <Header.Subheader>
                                                <strong>
                                                    {t([
                                                        'utils',
                                                        'headers',
                                                        'pages'
                                                    ].join('.'))}:
                                                </strong>
                                                &nbsp;
                                                {item.pages}&nbsp;
                                                <strong>
                                                    {t([
                                                        'utils',
                                                        'headers',
                                                        'signers'
                                                    ].join('.'))}:
                                                </strong>
                                                &nbsp;
                                                {item.recipients}&nbsp;
                                                <strong>
                                                    {t([
                                                        'utils',
                                                        'headers',
                                                        'fields'
                                                    ].join('.'))}:
                                                </strong>
                                                &nbsp;
                                                {item.fields.length}
                                            </Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell textAlign='center'>
                                    {moment(item.ts)
                                    .tz(timezone)
                                    .format('YYYY-MM-DD HH:mm:ss')}
                                </Table.Cell>
                                {shareable&&
                                    <Table.Cell textAlign='center'>
                                        {item.enterprise?
                                            <Fragment>
                                                {item.owner?
                                                    <span>{t([
                                                        'templates',
                                                        'sharing'
                                                    ].join('.'))}</span>:
                                                    <span>{t([
                                                        'templates',
                                                        'shared'
                                                    ].join('.'))}</span>
                                                }
                                                <Button
                                                    basic
                                                    icon='broken chain'
                                                    color='blue'
                                                    title={t('templates.hide')}
                                                    type='button'
                                                    data-name='unshare'
                                                    data-index={i}
                                                    onClick={this.handleClick}
                                                    disabled={!item.owner}
                                                />
                                            </Fragment>:
                                            <Button
                                                basic
                                                icon='chain'
                                                color='blue'
                                                title={t('templates.share')}
                                                type='button'
                                                data-name='share'
                                                data-index={i}
                                                onClick={this.handleClick}
                                                disabled={!item.owner}
                                            />
                                        }
                                    </Table.Cell>
                                }
                                <Table.Cell textAlign='right'>
                                    <Button.Group basic size='small'>
                                        <Button
                                            icon='eye'
                                            title={t('templates.view')}
                                            href={
                                                '/templates/fields/'+
                                                item._id+'/view'
                                            }
                                        />
                                        <Button
                                            icon='save'
                                            title={t('templates.edit')}
                                            href={
                                                '/templates/fields/'+
                                                item._id+'/edit'
                                            }
                                            disabled={!item.owner}
                                        />
                                        <Button
                                            icon='trash'
                                            title={t('templates.trash')}
                                            data-name='remove'
                                            data-index={i}
                                            onClick={this.handleClick}
                                            disabled={!item.owner}
                                        />
                                    </Button.Group>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>

                <Modal
                    basic
                    size='small'
                    open={modal}
                    onClose={this.handleClose}
                >
                    <Header
                        icon='archive'
                        content={t('templates.fields.confirm.trash.title')}
                    />

                    <Modal.Content>
                        <p>
                            {t('templates.fields.confirm.trash.content')}
                        </p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button.Group floated='right'>
                            <Button
                                basic
                                inverted
                                title='No'
                                onClick={this.handleClose}
                            >
                                <Icon name='remove' />
                                {t('utils.bool.no')}
                            </Button>
                            <Button
                                inverted
                                title='Sí'
                                color='green'
                                data-name={'modal-'+modal_type}
                                onClick={this.handleClick}
                            >
                                <Icon name='checkmark' />
                                {t('utils.bool.yes')}
                            </Button>
                        </Button.Group>
                    </Modal.Actions>
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps=(state)=>{
    return {
        auth:state.auth,
        data:state.data
    };
};

export default withTranslation('global')(
    connect(mapStateToProps)(TemplateFieldsList)
);

