import Cookies from 'js-cookie';
import {
    TIMEZONE_DEFAULT,
    LANGUAGE_DEFAULT
} from '../config';
import {
    UPDATE
} from '../constants';

const __data=Cookies.get('firmanza.user.data')||'{}',
    _data=JSON.parse(__data),
    initialState={
        timezone:_data.timezone||TIMEZONE_DEFAULT,
        language:_data.language||LANGUAGE_DEFAULT,
        bookmarks:_data.bookmarks||[],
        quota:_data.quota||128
    };

const data=(state=initialState,action)=>{
    switch(action.type){
        case UPDATE:
            return {
                timezone:action.timezone,
                language:action.language,
                bookmarks:action.bookmarks,
                quota:action.quota
            };
        default:
            return state;
    }
};

export default data;

