import React,{Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch,useSelector} from 'react-redux';
import {withRouter} from 'react-router';
import {
    Menu,
    Sidebar
} from 'semantic-ui-react';

import {
    AUTH_OUT
} from '../constants';
import {
    apiFetch,
    message,
    acl
} from '../utils';

const logout=()=>{
    return {
        type:AUTH_OUT
    };
};

export default withRouter(function(props){
    const offcanvas=useSelector(state=>state.offcanvas),
        auth=useSelector(state=>state.auth),
        dispatch=useDispatch(),
        [t]=useTranslation('global');

    function handleClick(event){
        event.preventDefault();

        apiFetch({
            url:'/api/_session',
            method:'DELETE',
            data:{},
            success:(data)=>{
                dispatch(logout());
                props.history.push('/signin');

                message(data.ok,data.message,t);
            },
            error:(error)=>{
                message(false,error.body.message,t);
            },
            t:t
        });
    }

    return (
        <Sidebar
            id='sidebar'
            as={Menu}
            icon='labeled'
            inverted
            animation='slide along'
            vertical
            visible={offcanvas}
            width='thin'
        >
            <Menu.Item
                as='a'
                href='/'
            >
                {t('menubar.home')}
            </Menu.Item>

            {auth.logged&&
                <Fragment>
                    {acl(auth,[['certificate:list']])&&
                        <Menu.Item
                            as='a'
                            href='/certificates'
                        >
                            {t('menubar.certificates')}
                        </Menu.Item>
                    }
                    {acl(auth,[['document:list']])&&
                        <Menu.Item
                            as='a'
                            href='/documents'
                        >
                            {t('menubar.documents')}
                        </Menu.Item>
                    }
                    {acl(auth,[['workflow:list']])&&
                        <Menu.Item
                            as='a'
                            href='/workflows'
                        >
                            {t('menubar.workflows')}
                        </Menu.Item>
                    }
                    <Menu.Item
                        as='a'
                        href='/settings'
                    >
                        {t('menubar.settings')}
                    </Menu.Item>
                </Fragment>
            }

            {auth.logged?
                <Menu.Item
                    as='a'
                    onClick={handleClick}
                >
                    {t('menubar.logout')}
                </Menu.Item>:
                <Menu.Item
                    as='a'
                    href='/signin'
                >
                    {t('menubar.login')}
                </Menu.Item>
            }
        </Sidebar>
    );
});

