import es from 'date-fns/locale/es';
import filesize from 'filesize';
import Cookies from 'js-cookie';
import moment from 'moment-timezone';
import {basename} from 'path';
import React,{Component,Fragment} from 'react';
import {withTranslation} from 'react-i18next';
import {Document,Page,pdfjs} from 'react-pdf';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import DatePicker,{registerLocale} from 'react-datepicker';
import {
    Button,
    Checkbox,
    Container,
    Dimmer,
    Divider,
    Dropdown,
    Feed,
    Form,
    Grid,
    Header,
    Icon,
    Input,
    Label,
    List,
    Loader,
    Menu,
    Message,
    Modal,
    Segment,
    Step,
    Table,
    TextArea
} from 'semantic-ui-react';

import Phones_en from '../translations/phones.en';
import Phones_es from '../translations/phones.es';

import {
    Uploader
} from '../components';
import {
    COUNTRIES_WITHOUT_FLAG,
    TIMEZONE_DEFAULT,
    WORKFLOWS
} from '../config';
import {
    UPDATE
} from '../constants';
import {
    apiFetch,
    promiseFetch,
    generate,
    humanStatus,
    jsonPrint,
    message,
    acl
} from '../utils';

import 'react-datepicker/dist/react-datepicker.css';

pdfjs.GlobalWorkerOptions.workerSrc='//cdnjs.cloudflare.com/ajax/libs/pdf.js/'+
    pdfjs.version+'/pdf.worker.js';

registerLocale('es',es);

class WorkflowViewer extends Component{
    constructor(props){
        super(props);

        this.steps=3;
        this.state={
            mode:this.props.mode,
            id:this.props.match.params.resource,
            data:{
                name:'',
                stage:'',
                currentStep:0,
                documents:[],
                recipients:[],
                ordered:false,
                autonotify:false,
                message:'',
                deadline:null,
                unlimited:true,
                currentDocument:0,
                changes:[],
                hash:''
            },
            drive:{
                type:'folder',
                name:'/',
                path:'/',
                children:[]
            },
            document:{
                path:null,
                name:null,
                page:0,
                total:1
            },
            recipient:{
                index:-1,
                email:'',
                auth:'email',
                params:{}
            },
            params:{},
            question:'',
            answer:'',
            editQA:false,
            editQA_index:-1,
            viewer:{
                filepath:null,
                page:0,
                total:1
            },
            field_index:-1,
            field:{
                recipient:null,
                name:'',
                description:'',
                type:null,
                values:[],
                positions:[],
                mandatory:false,
                readonly:false
            },
            selected:{
                active:false,
                field:-1,
                position:-1
            },
            value:'',
            position:{
                page:0,
                coordinates:{
                    x:0,
                    y:0,
                    w:200,
                    h:20
                },
                edit:false,
                edit_index:-1
            },
            dragging:{
                active:false,
                x:0,
                y:0
            },
            templates:{
                qa:[],
                fields:[],
            },
            templateQA:-1,
            templateFields:-1,
            modal:false,
            modal_type:'',
            loading:false,
            timezone:this.props.data.timezone||TIMEZONE_DEFAULT
        };

        this.handleUpload=this.handleUpload.bind(this);
        this.handleError=this.handleError.bind(this);
        this.handleDatePicker=this.handleDatePicker.bind(this);
        this.handleDocument=this.handleDocument.bind(this);
        this.handleViewer=this.handleViewer.bind(this);
        this.handleClose=this.handleClose.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.handleClick=this.handleClick.bind(this);
        this.handlePointerDown=this.handlePointerDown.bind(this);
        this.handlePointerMove=this.handlePointerMove.bind(this);
        this.handlePointerUp=this.handlePointerUp.bind(this);
        this.handlePointerOver=this.handlePointerOver.bind(this);
        this.handlePointerOut=this.handlePointerOut.bind(this);
    }

    componentDidMount(){
        const {
                id,
                data,
                timezone
            }=this.state,
            {t}=this.props;

        const search=new URLSearchParams(this.props.location.search),
            step=search.has('step')?+search.get('step'):-1;

        apiFetch({
            url:'/api/settings/data',
            method:'GET',
            success:(_data)=>{
                let obj=JSON.parse(_data.data);

                this.setState({
                    timezone:obj.timezone
                });

                if(id){
                    let list_qa=[],
                        list_fields=[];

                    promiseFetch({
                        url:'/api/workflows/'+id,
                        method:'GET',
                        data:null,
                        t:t
                    })
                    .then((_data)=>{
                        if(_data.documents.length>0){
                            this.setState({
                                data:{
                                    ..._data,
                                    currentStep:step===-1?
                                        data.currentStep:step,
                                    deadline:_data.deadline?
                                        new Date(_data.deadline):null,
                                    unlimited:_data.deadline?false:true,
                                    currentDocument:0
                                },
                                viewer:{
                                    filepath:_data.documents[0].filepath,
                                    page:_data.documents[0].page,
                                    total:_data.documents[0].total
                                }
                            });
                        }else{
                            this.setState({
                                data:{
                                    ..._data,
                                    currentStep:step===-1?
                                        data.currentStep:step,
                                    deadline:_data.deadline?
                                        new Date(_data.deadline):null,
                                    unlimited:_data.deadline?false:true,
                                    currentDocument:0
                                }
                            });
                        }
                    })
                    .then(()=>{
                        return promiseFetch({
                            url:'/api/templates/qa',
                            method:'GET',
                            data:null,
                            t:t
                        })
                        .then((_data)=>{
                            list_qa=_data.list;
                        });
                    })
                    .then(()=>{
                        return promiseFetch({
                            url:'/api/templates/fields',
                            method:'GET',
                            data:null,
                            t:t
                        })
                        .then((_data)=>{
                            list_fields=_data.list;
                        });
                    })
                    .then(()=>{
                        this.setState({
                            templates:{
                                qa:list_qa,
                                fields:list_fields
                            }
                        });
                    })
                    .catch((error)=>{
                        message(false,error.body.message,t);
                    });
                }else{
                    this.setState({
                        data:{
                            ...data,
                            name:t('workflows.document.name')+' ('+moment()
                            .tz(timezone)
                            .format('YYYY-MM-DD HH:mm:ss')+
                            ')'
                        }
                    });
                }
            },
            error:(error)=>{
                this.setState({
                    error:error
                });
            },
            t:t
        });
    }

    getDocuments(){
        const {
            data
        }=this.state;

        return data.documents.map((document,index)=>{
            return {
                key:index,
                text:document.filepath,
                value:index
            };
        });
    }

    getRecipients(){
        const {
            data
        }=this.state;

        return data.recipients.map((recipient,index)=>{
            return {
                key:index,
                text:recipient.email,
                value:index
            };
        });
    }

    getOptions(index=-1){
        const {
                data,
                recipient,
                params
            }=this.state,
            {t}=this.props;

        let text=[
                t('workflows.2fa.email.name'),
                t('workflows.2fa.phone.name'),
                t('workflows.2fa.password.name'),
                t('workflows.2fa.firmanza.name'),
                t('workflows.2fa.q&a.name')
            ],
            _recipient=data.recipients[index]||{},
            _params=_recipient.params||params,
            auth=_recipient.auth?_recipient.auth:recipient.auth;

        switch(auth){
            case 'phone':
                text[1]=text[1]+': '+((_params.code+_params.number)||'...');

                break;
            case 'password':
                text[2]=text[2]+': '+(_params.password||'...');

                break;
            case 'q&a':
                text[4]=text[4]+': '+((_params.questions||[]).length)+
                    ' | '+(_params.required||'0')+
                    ' | '+(_params.skippable||'0');

                break;
            default:
                break;
        }

        return [{
            key:0,
            text:text[0],
            value:'email',
            icon:'mail'
        },{
            key:1,
            text:text[1],
            value:'phone',
            icon:'mobile alternate'
        },{
            key:2,
            text:text[2],
            value:'password',
            icon:'key'
        },{
            key:3,
            text:text[3],
            value:'firmanza',
            icon:'address card'
        },{
            key:4,
            text:text[4],
            value:'q&a',
            icon:'question circle'
        }];
    }

    getPhoneCodes(){
        const {i18n}=this.props,
            language=i18n.language||'es',
            Phones={
                'en':Phones_en,
                'es':Phones_es
            }[language];

        return Phones
        .map((country)=>{
            return {
                key:country.code,
                value:country.code,
                flag:COUNTRIES_WITHOUT_FLAG.includes(country.code)?
                    null:country.code,
                text:country.label
            };
        });
    }

    getTypes(){
        const {t}=this.props;

        return [{
            key:0,
            text:t('utils.fieldType.checkbox'),
            value:'checkbox',
            icon:'check square'
        },{
            key:1,
            text:t('utils.fieldType.radio'),
            value:'radio',
            icon:'radio'
        },{
            key:2,
            text:t('utils.fieldType.text'),
            value:'text',
            icon:'text cursor'
        },{
            key:3,
            text:t('utils.fieldType.list'),
            value:'list',
            icon:'list ul'
        },{
            key:4,
            text:t('utils.fieldType.combo'),
            value:'combo',
            icon:'caret square down'
        },{
            key:5,
            text:t('utils.fieldType.signature'),
            value:'signature',
            icon:'edit'
        },{
            key:6,
            text:t('utils.fieldType.signatureImage'),
            value:'signature-image',
            icon:'signing'
        },{
            key:7,
            text:t('utils.fieldType.image'),
            value:'image',
            icon:'image'
        }];
    }

    getTemplatesQA(){
        const {
            templates
        }=this.state;

        return templates.qa
        .map((template,index)=>{
            return {
                key:index,
                text:template.name,
                value:index
            };
        });
    }

    getTemplatesFields(){
        const {
                templates
            }=this.state,
            {t}=this.props;

        return templates.fields
        .map((template,index)=>{
            return {
                key:index,
                text:template.name+' ('+
                [
                    t('utils.headers.pages')+': '+template.pages,
                    t('utils.headers.signers')+': '+template.recipients
                ].join(', ')+')',
                value:index
            };
        });
    }

    handleUpload(files){
        const {
                data,
                viewer
            }=this.state,
            {t}=this.props;

        let formData=new FormData(),
            status;

        files.forEach((file)=>{
            formData.append(
                'file',
                file.src.file,
                file.src.file.name
            );
        });

        this.setState({
            loading:true
        });

        fetch('/api/documents?path=/',{
            method:'POST',
            credentials:'same-origin',
            headers:{
                'Accept':'application/json',
                'CSRF-Token':Cookies.get('firmanza.user.csrf')
            },
            body:formData
        })
        .then((response)=>{
            status=response.status;

            return response.json();
        })
        .then((body)=>{
            if(status===200){
                body.results.forEach((result)=>{
                    switch(result.code){
                        case 'OK':
                        case 'ERR_EXISTS':
                            apiFetch({
                                url:'/api/documents/info?'+[
                                    'path='+encodeURIComponent(result.path)
                                ].join('&'),
                                method:'GET',
                                success:(_data)=>{
                                    if(_data.message){
                                        this.setState({
                                            modal:false,
                                            modal_type:'',
                                            loading:false
                                        });

                                        message(
                                            false,
                                            'workflows.messages.broken',
                                            t
                                        );
                                    }else{
                                        let documents=data.documents;

                                        documents.push({
                                            filepath:result.path,
                                            size:_data.size,
                                            page:0,
                                            total:_data.total,
                                            pages:_data.pages,
                                            fields:_data.fields.map((field)=>{
                                                return {
                                                    ...field,
                                                    ...{
                                                        mandatory:false,
                                                        readonly:true
                                                    }
                                                };
                                            })
                                        });

                                        if(viewer.filepath){
                                            this.setState({
                                                data:{
                                                    ...data,
                                                    documents:documents
                                                },
                                                loading:false
                                            });
                                        }else{
                                            this.setState({
                                                data:{
                                                    ...data,
                                                    documents:documents,
                                                    currentDocument:0
                                                },
                                                viewer:{
                                                    filepath:
                                                    data.documents[0].filepath,
                                                    page:data.documents[0].page,
                                                    total:
                                                    data.documents[0].total
                                                },
                                                loading:false
                                            });
                                        }

                                        message(true,result.message,t);
                                    }
                                },
                                error:(error)=>{
                                    message(false,error.body.message,t);
                                },
                                t:t
                            });

                            break;
                        default:
                            message(false,result.message,t);
                    }
                });
            }else{
                message(false,body.message,t);
            }
        })
        .catch((e)=>{
            console.log(e);

            message(false,'api.error',t);
        });
    }

    handleError(errors){
        const {t}=this.props;

        errors.forEach((error)=>{
            message(false,{
                'unsupportedFileType':t('messages.pdf.unsupportedFileType'),
                'maxSizeExceeded':t('messages.pdf.maxSizeExceeded')
            }[error.type],t);
        });
    }

    handleDatePicker(date){
        const {
            data
        }=this.state;

        this.setState({
            data:{
                ...data,
                deadline:date
            }
        });
    }

    handleDocument(data){
        const {
            document
        }=this.state;

        this.setState({
            document:{
                ...document,
                page:0,
                total:data.numPages
            }
        });
    }

    handleViewer(data){
        const {
            viewer
        }=this.state;

        this.setState({
            viewer:{
                ...viewer,
                page:0,
                total:data.numPages
            }
        });
    }

    handleClose(){
        this.setState({
            editQA:false,
            editQA_index:-1,
            modal:false,
            modal_type:''
        });
    }

    handleChange(event,_data){
        const {
                mode,
                data,
                recipient,
                params,
                field,
                position
            }=this.state,
            {i18n}=this.props,
            language=i18n.language||'es',
            Phones={
                'en':Phones_en,
                'es':Phones_es
            }[language];

        event.stopPropagation();

        switch(_data['data-name']){
            case 'recipients-2fa':{
                let index=+_data['data-index'],
                    recipients=data.recipients;

                recipients[index].auth=_data.value;

                this.setState({
                    data:{
                        ...data,
                        recipients:recipients
                    }
                });

                break;
            }
            case 'recipient-email':
                this.setState({
                    recipient:{
                        ...recipient,
                        email:_data.value
                    }
                });

                break;
            case 'recipient-2fa':
                this.setState({
                    recipient:{
                        ...recipient,
                        auth:_data.value
                    }
                });

                break;
            case 'recipient-2fa-phone-country':
                if(mode!=='view'){
                    this.setState({
                        params:{
                            ...params,
                            country:_data.value,
                            code:Phones.find((i)=>{
                                return i.code===_data.value;
                            }).prefix
                        }
                    });
                }

                break;
            case 'recipient-2fa-phone-number':
                if(mode!=='view'){
                    this.setState({
                        params:{
                            ...params,
                            number:_data.value
                        }
                    });
                }

                break;
            case 'recipient-2fa-password':
                this.setState({
                    params:{
                        ...params,
                        password:_data.value
                    }
                });

                break;
            case 'recipient-2fa-q&a-question':
                this.setState({
                    question:_data.value
                });

                break;
            case 'recipient-2fa-q&a-answer':
                this.setState({
                    answer:_data.value
                });

                break;
            case 'recipient-2fa-q&a-required':
                if(mode!=='view'){
                    this.setState({
                        params:{
                            ...params,
                            required:_data.value
                        }
                    });
                }

                break;
            case 'recipient-2fa-q&a-skippable':
                if(mode!=='view'){
                    this.setState({
                        params:{
                            ...params,
                            skippable:_data.value
                        }
                    });
                }

                break;
            case 'ordered':
                this.setState({
                    data:{
                        ...data,
                        ordered:_data.checked
                    }
                });

                break;
            case 'autonotify':
                this.setState({
                    data:{
                        ...data,
                        autonotify:_data.checked
                    }
                });

                break;
            case 'unlimited':
                this.setState({
                    data:{
                        ...data,
                        unlimited:_data.checked
                    }
                });

                break;
            case 'template-q&a':
                this.setState({
                    templateQA:_data.value
                });

                break;
            case 'field-name':
                if(!field.readonly&&mode!=='view'){
                    this.setState({
                        field:{
                            ...field,
                            name:_data.value
                        }
                    });
                }

                break;
            case 'field-type':
                if(!field.readonly&&mode!=='view'){
                    this.setState({
                        field:{
                            ...field,
                            type:_data.value
                        }
                    });
                }

                break;
            case 'field-recipient':
                if(mode!=='view'){
                    this.setState({
                        field:{
                            ...field,
                            recipient:_data.value
                        }
                    });
                }

                break;
            case 'field-description':
                if(mode!=='view'){
                    this.setState({
                        field:{
                            ...field,
                            description:_data.value
                        }
                    });
                }

                break;
            case 'field-value':
                this.setState({
                    value:_data.value
                });

                break;
            case 'position-page':
                this.setState({
                    position:{
                        ...position,
                        page:+_data.value-1
                    }
                });

                break;
            case 'position-x':
                this.setState({
                    position:{
                        ...position,
                        coordinates:{
                            ...position.coordinates,
                            x:+_data.value
                        }
                    }
                });

                break;
            case 'position-y':
                this.setState({
                    position:{
                        ...position,
                        coordinates:{
                            ...position.coordinates,
                            y:+_data.value
                        }
                    }
                });

                break;
            case 'position-w':
                this.setState({
                    position:{
                        ...position,
                        coordinates:{
                            ...position.coordinates,
                            w:+_data.value
                        }
                    }
                });

                break;
            case 'position-h':
                this.setState({
                    position:{
                        ...position,
                        coordinates:{
                            ...position.coordinates,
                            h:+_data.value
                        }
                    }
                });

                break;
            case 'field-mandatory':
                this.setState({
                    field:{
                        ...field,
                        mandatory:_data.checked
                    }
                });

                break;
            case 'fields-document':{
                const document=data.documents[+_data.value];

                this.setState({
                    data:{
                        ...data,
                        currentDocument:+_data.value
                    },
                    viewer:{
                        filepath:document.filepath,
                        page:0,
                        total:document.total
                    }
                });

                break;
            }
            case 'template-fields':
                this.setState({
                    templateFields:_data.value
                });

                break;
            default:
                this.setState({
                    data:{
                        ...data,
                        [_data.name]:_data.value
                    }
                });
        }
    }

    handleClick(event,_data){
        const {
                mode,
                id,
                data,
                drive,
                document:doc,
                recipient,
                params,
                question,
                answer,
                editQA_index,
                viewer,
                field_index,
                field,
                value,
                position,
                templates,
                templateQA,
                templateFields,
                timezone
            }=this.state,
            {t}=this.props;

        event.stopPropagation();

        switch(_data['data-name']){
            case 'step1':
                this.setState({
                    data:{
                        ...data,
                        currentStep:0
                    }
                });

                break;
            case 'step2':
                this.setState({
                    data:{
                        ...data,
                        currentStep:1
                    }
                });

                break;
            case 'step3':
                this.setState({
                    data:{
                        ...data,
                        currentStep:2
                    }
                });

                break;
            case 'step4':
                this.setState({
                    data:{
                        ...data,
                        currentStep:3
                    }
                });

                break;
            case 'step5':
                apiFetch({
                    url:'/api/workflows/'+id,
                    method:'GET',
                    success:(_data)=>{
                        this.setState({
                            data:{
                                ...data,
                                currentStep:4,
                                changes:_data.changes
                            }
                        });
                    },
                    error:(error)=>{
                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            case 'drive-folder':{
                const path=_data['data-value'];

                apiFetch({
                    url:'/api/documents?'+[
                        'path='+encodeURIComponent(path)
                    ].join('&'),
                    method:'GET',
                    success:(__data)=>{
                        let _drive=drive,
                            node=path
                            .slice(1)
                            .split('/')
                            .reduce((obj,i)=>{
                                if(obj.children){
                                    const node=obj.children.find((j)=>{
                                        return j.name===i;
                                    });

                                    if(node){
                                        return node;
                                    }
                                }

                                return {};
                            },_drive);

                        node.children=__data.list;

                        this.setState({
                            drive:_drive
                        });
                    },
                    error:(error)=>{
                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            }
            case 'drive-file':{
                const path=_data['data-value'];

                this.setState({
                    modal:false,
                    modal_type:'',
                    loading:true
                });

                apiFetch({
                    url:'/api/documents/info?'+[
                        'path='+encodeURIComponent(path)
                    ].join('&'),
                    method:'GET',
                    success:(__data)=>{
                        if(__data.message){
                            this.setState({
                                modal:false,
                                modal_type:'',
                                loading:false
                            });

                            message(false,'workflows.messages.broken',t);
                        }else{
                            let documents=data.documents;

                            if(!documents.some((i)=>{
                                return i.filepath===path;
                            })){
                                documents.push({
                                    filepath:path,
                                    size:__data.size,
                                    page:0,
                                    total:__data.total,
                                    pages:__data.pages,
                                    fields:__data.fields.map((field)=>{
                                        return {
                                            ...field,
                                            ...{
                                                mandatory:false,
                                                readonly:true
                                            }
                                        };
                                    })
                                });

                                if(viewer.filepath){
                                    this.setState({
                                        data:{
                                            ...data,
                                            documents:documents
                                        },
                                        modal:false,
                                        modal_type:'',
                                        loading:false
                                    });
                                }else{
                                    this.setState({
                                        data:{
                                            ...data,
                                            documents:documents,
                                            currentDocument:0
                                        },
                                        viewer:{
                                            filepath:data.documents[0].filepath,
                                            page:data.documents[0].page,
                                            total:data.documents[0].total
                                        },
                                        modal:false,
                                        modal_type:'',
                                        loading:false
                                    });
                                }
                            }else{
                                this.setState({
                                    modal:false,
                                    modal_type:'',
                                    loading:false
                                });
                            }
                        }
                    },
                    error:(error)=>{
                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            }
            case 'documents-up':{
                let index=+_data['data-index'],
                    documents=data.documents;

                if(index>0){
                    [documents[index],documents[index-1]]=
                        [documents[index-1],documents[index]];

                    this.setState({
                        data:{
                            ...data,
                            documents:documents
                        }
                    });
                }

                break;
            }
            case 'documents-bottom':{
                let index=+_data['data-index'],
                    documents=data.documents;

                if(index<documents.length-1){
                    [documents[index],documents[index+1]]=
                        [documents[index+1],documents[index]];

                    this.setState({
                        data:{
                            ...data,
                            documents:documents
                        }
                    });
                }

                break;
            }
            case 'documents-viewer':{
                let index=+_data['data-index'],
                    documents=data.documents;

                this.setState({
                    document:{
                        path:documents[index].filepath,
                        page:0,
                        total:1
                    },
                    modal:true,
                    modal_type:'file'
                });

                break;
            }
            case 'documents-remove':{
                let index=+_data['data-index'],
                    documents=data.documents;

                documents.splice(index,1);

                if(documents.length===0){
                    this.setState({
                        data:{
                            ...data,
                            documents:documents,
                            currentDocument:0
                        },
                        viewer:{
                            filepath:null
                        }
                    });
                }else if(data.currentDocument>=documents.length){
                    this.setState({
                        data:{
                            ...data,
                            documents:documents,
                            currentDocument:0
                        },
                        viewer:{
                            filepath:data.documents[0].filepath,
                            page:data.documents[0].page,
                            total:data.documents[0].total
                        }
                    });
                }else{
                    this.setState({
                        data:{
                            ...data,
                            documents:documents
                        }
                    });
                }

                break;
            }
            case 'documents-download':{
                let index=+_data['data-document'],
                    file=_data['data-file'],
                    generic=_data['data-generic'],
                    translate={
                        'original':t('workflows.workflow.download.original'),
                        'generated':t('workflows.workflow.download.generated'),
                        'v':t('workflows.workflow.download.versioned'),
                        'filled':t('workflows.workflow.download.last')
                    };

                this.setState({
                    document:{
                        path:'/'+WORKFLOWS+'/'+data.hash+'.'+file,
                        name:'('+translate[generic]+') '+
                            basename(data.documents[index].filepath),
                        page:0,
                        total:1
                    },
                    modal:true,
                    modal_type:'file'
                });

                break;
            }
            case 'drive':
                apiFetch({
                    url:'/api/documents?'+[
                        'path=%2F'
                    ].join('&'),
                    method:'GET',
                    success:(__data)=>{
                        this.setState({
                            drive:{
                                ...drive,
                                children:__data.list
                            },
                            modal:true,
                            modal_type:'drive'
                        });
                    },
                    error:(error)=>{
                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            case 'recipients-2fa-params':{
                let index=+_data['data-index'];

                if(0<=index&&index<data.recipients.length){
                    this.setState({
                        params:{
                            ...data.recipients[index].params,
                            index:index
                        },
                        modal:true,
                        modal_type:'recipient-'+data.recipients[index].auth
                    });
                }

                break;
            }
            case 'recipients-up':{
                let index=+_data['data-index'],
                    recipients=data.recipients;

                if(index>0){
                    [recipients[index],recipients[index-1]]=
                        [recipients[index-1],recipients[index]];

                    this.setState({
                        data:{
                            ...data,
                            recipients:recipients
                        }
                    });
                }

                break;
            }
            case 'recipients-bottom':{
                let index=+_data['data-index'],
                    recipients=data.recipients;

                if(index<recipients.length-1){
                    [recipients[index],recipients[index+1]]=
                        [recipients[index+1],recipients[index]];

                    this.setState({
                        data:{
                            ...data,
                            recipients:recipients
                        }
                    });
                }

                break;
            }
            case 'recipients-remove':{
                let index=+_data['data-index'],
                    recipients=data.recipients;

                recipients.splice(index,1);

                this.setState({
                    data:{
                        ...data,
                        recipients:recipients
                    }
                });

                break;
            }
            case 'recipients-notify':{
                let index=+_data['data-index'],
                    recipients=data.recipients;

                this.setState({
                    loading:true
                });

                apiFetch({
                    url:'/api/workflows/'+id+'/notify/'+index,
                    method:'POST',
                    success:(_data)=>{
                        recipients[index].notified=true;

                        this.setState({
                            data:{
                                ...data,
                                recipients:recipients
                            },
                            loading:false
                        });

                        message(_data.ok,_data.message,t);
                    },
                    error:(error)=>{
                        this.setState({
                            loading:false
                        });

                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            }
            case 'recipient-2fa-params':{
                this.setState({
                    params:{
                        index:-1
                    },
                    modal:true,
                    modal_type:'recipient-'+recipient.auth
                });

                break;
            }
            case 'modal-recipient-phone':
                if(
                    document.querySelector('input[name="phone"]')
                    .reportValidity()
                ){
                    if(params.index>=0){
                        let index=params.index,
                            recipients=data.recipients;

                        recipients[index].params={
                            country:params.country,
                            code:params.code,
                            number:params.number
                        };

                        this.setState({
                            data:{
                                ...data,
                                recipients:recipients
                            },
                            modal:false
                        });
                    }else{
                        this.setState({
                            modal:false
                        });
                    }
                }

                break;
            case 'recipient-2fa-password-generate':
                this.setState({
                    params:{
                        ...params,
                        password:generate(12)
                    }
                });

                break;
            case 'modal-recipient-password':
                if(
                    document.querySelector('input[name="password"]')
                    .reportValidity()
                ){
                    if(params.index>=0){
                        let index=params.index,
                            recipients=data.recipients;

                        recipients[index].params={
                            password:params.password
                        };

                        this.setState({
                            data:{
                                ...data,
                                recipients:recipients
                            },
                            modal:false
                        });
                    }else{
                        this.setState({
                            modal:false
                        });
                    }
                }

                break;
            case 'recipient-2fa-q&a-save':{
                let questions=params.questions;

                questions[editQA_index].question=question;
                questions[editQA_index].answer=answer;

                this.setState({
                    questions:questions,
                    question:'',
                    answer:'',
                    editQA:false
                });

                break;
            }
            case 'recipient-2fa-q&a-edit':{
                let index=+_data['data-index'];

                this.setState({
                    question:params.questions[index].question,
                    answer:params.questions[index].answer,
                    editQA:true,
                    editQA_index:index
                });

                break;
            }
            case 'recipient-2fa-q&a-remove':{
                let index=+_data['data-index'],
                    questions=params.questions;

                questions.splice(index,1);

                this.setState({
                    params:{
                        ...params,
                        questions:questions
                    }
                });

                break;
            }
            case 'recipient-2fa-q&a-add':
                this.setState({
                    params:{
                        ...params,
                        questions:(params.questions||[]).concat({
                            question:question,
                            answer:answer
                        })
                    },
                    question:'',
                    answer:''
                });

                break;
            case 'modal-recipient-q&a':
                if(
                    document.querySelector('input[name="required"]')
                    .reportValidity()
                ){
                    if(params.index>=0){
                        let index=params.index,
                            recipients=data.recipients;

                        recipients[index].params={
                            questions:params.questions,
                            required:params.required,
                            skippable:params.skippable
                        };

                        this.setState({
                            data:{
                                ...data,
                                recipients:recipients
                            },
                            modal:false,
                            editQA:false
                        });
                    }else{
                        this.setState({
                            modal:false,
                            editQA:false
                        });
                    }
                }

                break;
            case 'recipient-add':
                this.setState({
                    data:{
                        ...data,
                        recipients:data.recipients.concat({
                            email:recipient.email,
                            auth:recipient.auth,
                            params:{
                                ...params,
                                index:undefined
                            }
                        })
                    },
                    recipient:{
                        email:'',
                        auth:'email',
                        params:{}
                    },
                    params:{}
                });

                break;
            case 'template-q&a-load':
                this.setState({
                    params:{
                        questions:templates.qa[templateQA].questions,
                        required:templates.qa[templateQA].required,
                        skippable:templates.qa[templateQA].skippable,
                        editQA:false
                    }
                });

                break;
            case 'template-q&a-save':
                apiFetch({
                    url:'/api/templates/qa',
                    method:'POST',
                    data:{
                        name:t('workflows.template.name')+' ('+moment()
                        .tz(timezone)
                        .format('YYYY-MM-DD HH:mm:ss')+
                        ')',
                        questions:params.questions,
                        required:params.required,
                        skippable:params.skippable
                    },
                    success:(_data)=>{
                        message(_data.ok,_data.message,t);
                    },
                    error:(error)=>{
                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            case 'fields-page-start':{
                let documents=data.documents;

                documents[data.currentDocument].page=0;

                this.setState({
                    data:{
                        ...data,
                        documents:documents
                    },
                    viewer:{
                        ...viewer,
                        page:0
                    }
                });

                break;
            }
            case 'fields-page-previous':{
                if(viewer.page>0){
                    let documents=data.documents;

                    documents[data.currentDocument].page=viewer.page-1;

                    this.setState({
                        data:{
                            ...data,
                            documents:documents
                        },
                        viewer:{
                            ...viewer,
                            page:viewer.page-1
                        }
                    });
                }

                break;
            }
            case 'fields-page-next':{
                if(viewer.page<viewer.total-1){
                    let documents=data.documents;

                    documents[data.currentDocument].page=viewer.page+1;

                    this.setState({
                        data:{
                            ...data,
                            documents:documents
                        },
                        viewer:{
                            ...viewer,
                            page:viewer.page+1
                        }
                    });
                }

                break;
            }
            case 'fields-page-end':{
                let documents=data.documents;

                documents[data.currentDocument].page=viewer.total-1;

                this.setState({
                    data:{
                        ...data,
                        documents:documents
                    },
                    viewer:{
                        ...viewer,
                        page:viewer.total-1
                    }
                });

                break;
            }
            case 'value-up':{
                let index=+_data['data-index'],
                    values=field.values;

                if(index>0){
                    [values[index],values[index-1]]=
                        [values[index-1],values[index]];

                    this.setState({
                        field:{
                            ...field,
                            values:values
                        }
                    });
                }

                break;
            }
            case 'value-down':{
                let index=+_data['data-index'],
                    values=field.values;

                if(index<values.length-1){
                    [values[index],values[index+1]]=
                        [values[index+1],values[index]];

                    this.setState({
                        field:{
                            ...field,
                            values:values
                        }
                    });
                }

                break;
            }
            case 'value-remove':{
                let index=+_data['data-index'],
                    values=field.values;

                values.splice(index,1);

                this.setState({
                    field:{
                        ...field,
                        values:values
                    }
                });

                break;
            }
            case 'value-add':{
                this.setState({
                    field:{
                        ...field,
                        values:field.values.concat(value)
                    },
                    value:''
                });

                break;
            }
            case 'position-up':{
                let index=+_data['data-index'],
                    positions=field.positions;

                if(index>0){
                    [positions[index],positions[index-1]]=
                        [positions[index-1],positions[index]];

                    this.setState({
                        field:{
                            ...field,
                            positions:positions
                        }
                    });
                }

                break;
            }
            case 'position-down':{
                let index=+_data['data-index'],
                    positions=field.positions;

                if(index<positions.length-1){
                    [positions[index],positions[index+1]]=
                        [positions[index+1],positions[index]];

                    this.setState({
                        field:{
                            ...field,
                            positions:positions
                        }
                    });
                }

                break;
            }
            case 'position-save':{
                let index=+_data['data-index'],
                    _position=field.positions[index];

                _position.page=position.page;
                _position.coordinates=position.coordinates;

                this.setState({
                    field:{
                        ...field,
                        positions:field.positions
                    },
                    position:{
                        page:0,
                        coordinates:{
                            x:0,
                            y:0,
                            w:200,
                            h:20
                        },
                        edit:false,
                        edit_index:-1
                    }
                });

                break;
            }
            case 'position-edit':{
                let index=+_data['data-index'];

                this.setState({
                    position:{
                        page:field.positions[index].page,
                        coordinates:field.positions[index].coordinates,
                        edit:true,
                        edit_index:index
                    }
                });

                break;
            }
            case 'position-remove':{
                let index=+_data['data-index'],
                    positions=field.positions;

                positions.splice(index,1);

                this.setState({
                    field:{
                        ...field,
                        positions:positions
                    }
                });

                break;
            }
            case 'position-add':
                this.setState({
                    field:{
                        ...field,
                        positions:field.positions.concat({
                            ...position,
                            ...{
                                page:position.page
                            }
                        })
                    },
                    position:{
                        page:0,
                        coordinates:{
                            x:0,
                            y:0,
                            w:200,
                            h:20
                        },
                        edit:false,
                        edit_index:-1
                    }
                });

                break;
            case 'field':{
                let index=+_data['data-index'];

                this.setState({
                    field_index:index,
                    field:data.documents[data.currentDocument].fields[index],
                    modal:true,
                    modal_type:'field'
                });

                break;
            }
            case 'modal-field':
                if(
                    document.querySelector('input[name="field-name"]')
                    .reportValidity()&&
                    field.type!==null&&
                    field.recipient!==null
                ){
                    let documents=data.documents,
                        document=documents[data.currentDocument];

                    if(field_index>=0){
                        document.fields[field_index]=field;
                    }else{
                        document.fields.push(field);
                    }

                    this.setState({
                        data:{
                            ...data,
                            documents:documents
                        },
                        field_index:-1,
                        modal:false,
                        modal_type:''
                    });
                }

                break;
            case 'field-up':{
                let index=+_data['data-index'],
                    fields=data.documents[data.currentDocument].fields;

                if(index>0){
                    [fields[index],fields[index-1]]=
                        [fields[index-1],fields[index]];

                    this.setState({
                        data:{
                            ...data,
                            documents:data.documents
                        }
                    });
                }

                break;
            }
            case 'field-bottom':{
                let index=+_data['data-index'],
                    fields=data.documents[data.currentDocument].fields;

                if(index<fields.length-1){
                    [fields[index],fields[index+1]]=
                        [fields[index+1],fields[index]];

                    this.setState({
                        data:{
                            ...data,
                            documents:data.documents
                        }
                    });
                }

                break;
            }
            case 'field-remove':{
                let index=+_data['data-index'],
                    fields=data.documents[data.currentDocument].fields;

                fields.splice(index,1);

                this.setState({
                    data:{
                        ...data,
                        documents:data.documents
                    }
                });

                break;
            }
            case 'field-clone':{
                let index=+_data['data-index'],
                    documents=data.documents,
                    document=documents[data.currentDocument],
                    field={
                        ...document.fields[index],
                        name:document.fields[index].name+' '+t('workflows.copy')
                    };

                document.fields.push(JSON.parse(JSON.stringify(field)));

                this.setState({
                    data:{
                        ...data,
                        documents:documents
                    }
                });

                break;
            }
            case 'field-add':
                this.setState({
                    field:{
                        recipient:null,
                        name:'',
                        description:'',
                        type:null,
                        values:[],
                        positions:[],
                        mandatory:false,
                        readonly:false
                    },
                    modal:true,
                    modal_type:'field'
                });

                break;
            case 'field-clean':
                data.documents[data.currentDocument].fields=
                data.documents[data.currentDocument].fields
                .filter((field,i)=>{
                    return field.readonly;
                });

                this.setState({
                    data:{
                        ...data,
                        documents:data.documents
                    }
                });

                break;
            case 'template-fields-load':{
                let fields=data.documents[data.currentDocument].fields
                    .filter((field,i)=>{
                        return field.readonly;
                    }),
                    template=templates.fields[templateFields];

                template.fields
                .filter((i)=>{
                    return i.recipient<data.recipients.length;
                })
                .filter((i)=>{
                    return i.positions
                    .map((j)=>{
                        return j.page;
                    })
                    .reduce((sum,j)=>{
                        return Math.max(sum,j);
                    },0)<data.documents[data.currentDocument].total;
                })
                .filter((i)=>{
                    return !(fields.map(j=>j.name).includes(i.name));
                })
                .forEach((i)=>{
                    fields.push({
                        recipient:i.recipient,
                        name:i.name,
                        description:i.description,
                        type:i.type,
                        values:i.values,
                        positions:i.positions,
                        mandatory:i.mandatory,
                        readonly:false
                    });
                });

                data.documents[data.currentDocument].fields=fields;

                this.setState({
                    data:{
                        ...data,
                        documents:data.documents
                    }
                });

                break;
            }
            case 'template-fields-save':{
                let document=data.documents[data.currentDocument],
                    fields=document.fields;

                apiFetch({
                    url:'/api/templates/fields',
                    method:'POST',
                    data:{
                        name:t('workflows.template.name')+' ('+moment()
                        .tz(timezone)
                        .format('YYYY-MM-DD HH:mm:ss')+
                        ')',
                        pages:document.total,
                        recipients:data.recipients.length,
                        papersize:{
                            width:document.pages[0].width,
                            height:document.pages[0].height
                        },
                        fields:fields
                        .map((i)=>{
                            return {
                                recipient:i.recipient,
                                name:i.name,
                                description:i.description,
                                type:i.type,
                                values:i.values,
                                positions:i.positions,
                                mandatory:i.mandatory
                            };
                        })
                    },
                    success:(_data)=>{
                        message(_data.ok,_data.message,t);
                    },
                    error:(error)=>{
                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            }
            case 'summary-document':{
                const index=+_data['data-index'];

                this.setState({
                    data:{
                        ...data,
                        currentDocument:index
                    },
                    viewer:{
                        filepath:data.documents[index].filepath,
                        page:0,
                        total:data.documents[index].total
                    }
                });

                break;
            }
            case 'title':
                apiFetch({
                    url:'/api/workflows/'+id,
                    method:'PUT',
                    data:{
                        name:data.name
                    },
                    success:(_data)=>{
                        message(_data.ok,_data.message,t);
                    },
                    error:(error)=>{
                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            case 'publish':
                if(mode==='edit'){
                    this.setState({
                        loading:true
                    });

                    apiFetch({
                        url:'/api/workflows/'+id+'/publish',
                        method:'POST',
                        data:data,
                        success:(_data)=>{
                            this.setState({
                                loading:false
                            });

                            this.props.history.push('/workflows');

                            message(_data.ok,_data.message,t);
                        },
                        error:(error)=>{
                            this.setState({
                                loading:false
                            });

                            error.body.results.forEach((result)=>{
                                message(
                                    false,
                                    result.message,
                                    t,
                                    result.values
                                );
                            });
                        },
                        t:t
                    });
                }

                break;
            case 'finalize':
                apiFetch({
                    url:'/api/workflows/'+id+'/finalize',
                    method:'POST',
                    success:(_data)=>{
                        this.setState({
                            loading:false
                        });

                        this.props.history.push('/workflows');

                        message(_data.ok,_data.message,t);
                    },
                    error:(error)=>{
                        this.setState({
                            loading:false
                        });

                        error.body.results.forEach((result)=>{
                            message(false,result.message,t);
                        });
                    },
                    t:t
                });

                break;
            case 'next':
                if(data.currentStep<this.steps){
                    if(mode==='edit'){
                        this.setState({
                            loading:true
                        });

                        apiFetch({
                            url:'/api/workflows/'+id,
                            method:'PUT',
                            data:{
                                ...{
                                    currentStep:data.currentStep+1
                                },
                                ...[{
                                    documents:data.documents
                                },{
                                    recipients:data.recipients,
                                    ordered:data.ordered,
                                    autonotify:data.autonotify,
                                    message:data.message,
                                    deadline:data.unlimited?null:data.deadline,
                                },{
                                    documents:data.documents
                                }][data.currentStep]
                            },
                            success:(_data)=>{
                                this.setState({
                                    data:{
                                        ...data,
                                        currentStep:data.currentStep+1
                                    },
                                    loading:false
                                });

                                message(_data.ok,_data.message,t);
                            },
                            error:(error)=>{
                                message(false,error.body.message,t);
                            },
                            t:t
                        });
                    }else{
                        this.setState({
                            data:{
                                ...data,
                                currentStep:data.currentStep+1
                            },
                            loading:false
                        });
                    }
                }

                break;
            case 'previous':
                if(0<data.currentStep){
                    if(mode==='edit'){
                        apiFetch({
                            url:'/api/workflows/'+id,
                            method:'PUT',
                            data:{
                                currentStep:data.currentStep-1
                            },
                            success:(_data)=>{
                                this.setState({
                                    data:{
                                        ...data,
                                        currentStep:data.currentStep-1
                                    }
                                });

                                message(_data.ok,_data.message,t);
                            },
                            error:(error)=>{
                                message(false,error.body.message,t);
                            },
                            t:t
                        });
                    }else{
                        this.setState({
                            data:{
                                ...data,
                                currentStep:data.currentStep-1
                            }
                        });
                    }
                }

                break;
            case 'edit':
                this.props.history.push('/workflows/'+id+'/edit');
                window.location.reload();

                break;
            case 'save-draft':
                if(mode==='edit'){
                    this.setState({
                        loading:true
                    });

                    apiFetch({
                        url:'/api/workflows/'+id,
                        method:'PUT',
                        data:{
                            name:data.name,
                            currentStep:data.currentStep,
                            documents:data.documents,
                            recipients:data.recipients,
                            ordered:data.ordered,
                            autonotify:data.autonotify,
                            message:data.message,
                            deadline:data.unlimited?null:data.deadline
                        },
                        success:(_data)=>{
                            this.setState({
                                loading:false
                            });

                            message(_data.ok,_data.message,t);
                        },
                        error:(error)=>{
                            message(false,error.body.message,t);
                        },
                        t:t
                    });
                }

                break;
            case 'archive':
                this.setState({
                    modal:true,
                    modal_type:'archive'
                });

                break;
            case 'modal-archive':
                apiFetch({
                    url:'/api/workflows/'+id,
                    method:'DELETE',
                    success:(_data)=>{
                        this.setState({
                            modal:false
                        });

                        this.props.history.push('/workflows');

                        message(_data.ok,_data.message,t);
                    },
                    error:(error)=>{
                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            case 'file-start':
                this.setState({
                    document:{
                        ...doc,
                        page:0
                    }
                });

                break;
            case 'file-previous':
                if(doc.page>0){
                    this.setState({
                        document:{
                            ...doc,
                            page:doc.page-1
                        }
                    });
                }

                break;
            case 'file-next':
                if(doc.page<doc.total-1){
                    this.setState({
                        document:{
                            ...doc,
                            page:doc.page+1
                        }
                    });
                }

                break;
            case 'file-end':
                this.setState({
                    document:{
                        ...doc,
                        page:doc.total-1
                    }
                });

                break;
            default:
                return;
        }
    }

    handlePointerDown(event){
        const {
            data
        }=this.state;

        const box=data
            .documents[data.currentDocument]
            .fields[event.target.dataset.field]
            .positions[event.target.dataset.position],
            bbox=event.target.getBoundingClientRect();

        event.target.setPointerCapture(event.pointerId);

        this.setState({
            dragging:{
                active:true,
                x:((event.clientX-bbox.left)*box.coordinates.w)/bbox.width,
                y:((event.clientY-bbox.top)*box.coordinates.h)/bbox.height
            }
        });
    }

    handlePointerMove(event){
        const {
            data,
            dragging
        }=this.state;

        if(dragging.active){
            let documents=data.documents,
                box=data
                .documents[data.currentDocument]
                .fields[event.target.dataset.field]
                .positions[event.target.dataset.position]
                .coordinates,
                bbox=event.target.getBoundingClientRect();

            box.x=Math.round(
                box.x-(
                    dragging.x-(
                        ((event.clientX-bbox.left)*box.w)/bbox.width
                    )
                )
            );
            box.y=Math.round(
                box.y-(
                    dragging.y-(
                        ((event.clientY-bbox.top)*box.h)/bbox.height
                    )
                )
            );

            this.setState({
                data:{
                    ...data,
                    documents:documents
                }
            });
        }
    }

    handlePointerUp(){
        this.setState({
            dragging:{
                active:false,
                x:0,
                y:0
            }
        });
    }

    handlePointerOver(event){
        this.setState({
            selected:{
                active:true,
                field:+event.target.dataset.field,
                position:+event.target.dataset.position
            }
        });
    }

    handlePointerOut(event){
        this.setState({
            selected:{
                active:false,
                field:-1,
                position:-1
            }
        });
    }

    renderSteps(){
        const {
                data
            }=this.state,
            {t}=this.props;

        return (
            <Step.Group widths={5} size='small'>
                <Step
                    link
                    active={data.currentStep===0}
                    data-name='step1'
                    onClick={this.handleClick}
                >
                    <Icon name='file pdf' />
                    <Step.Content>
                        <Step.Title>
                            {t('workflows.workflow.step1.title')}
                        </Step.Title>
                        <Step.Description>
                            {{
                                'draft':
                                    t('workflows.workflow.step1.draft'),
                                'published':
                                    t('workflows.workflow.step1.published')
                            }[data.stage]}
                        </Step.Description>
                    </Step.Content>
                </Step>
                <Step
                    link
                    active={data.currentStep===1}
                    data-name='step2'
                    onClick={this.handleClick}
                >
                    <Icon name='users' />
                    <Step.Content>
                        <Step.Title>
                            {t('workflows.workflow.step2.title')}
                        </Step.Title>
                        <Step.Description>
                            {{
                                'draft':
                                    t('workflows.workflow.step2.draft'),
                                'published':
                                    t('workflows.workflow.step2.published')
                            }[data.stage]}
                        </Step.Description>
                    </Step.Content>
                </Step>
                <Step
                    link
                    active={data.currentStep===2}
                    data-name='step3'
                    onClick={this.handleClick}
                >
                    <Icon name='signup' />
                    <Step.Content>
                        <Step.Title>
                            {t('workflows.workflow.step3.title')}
                        </Step.Title>
                        <Step.Description>
                            {{
                                'draft':
                                    t('workflows.workflow.step3.draft'),
                                'published':
                                    t('workflows.workflow.step3.published')
                            }[data.stage]}
                        </Step.Description>
                    </Step.Content>
                </Step>
                <Step
                    link
                    active={data.currentStep===3}
                    data-name='step4'
                    onClick={this.handleClick}
                >
                    <Icon name='send' />
                    <Step.Content>
                        <Step.Title>
                            {t('workflows.workflow.step4.title')}
                        </Step.Title>
                        <Step.Description>
                            {{
                                'draft':
                                    t('workflows.workflow.step4.draft'),
                                'published':
                                    t('workflows.workflow.step4.published')
                            }[data.stage]}
                        </Step.Description>
                    </Step.Content>
                </Step>
                <Step
                    link
                    active={data.currentStep===4}
                    data-name='step5'
                    onClick={this.handleClick}
                >
                    <Icon name='history' />
                    <Step.Content>
                        <Step.Title>
                            {t('workflows.workflow.step5.title')}
                        </Step.Title>
                        <Step.Description>
                            {t('workflows.workflow.step5.description')}
                        </Step.Description>
                    </Step.Content>
                </Step>
            </Step.Group>
        );
    }

    renderDrive(item){
        switch(item.type){
            case 'folder':
                return (
                    <List.Item
                        key={'drive-node-'+item.path}
                        data-name='drive-folder'
                        data-value={item.path}
                        onClick={this.handleClick}
                    >
                        <List.Icon name={item.type} />
                        <List.Content>
                            <List.Header>{item.name}</List.Header>
                            {item.children&&item.children.length!==0&&
                                <List.List>
                                    {item.children.map((node)=>{
                                        return (
                                            this.renderDrive(node)
                                        );
                                    })}
                                </List.List>
                            }
                        </List.Content>
                    </List.Item>
                );
            case 'file':
                return (
                    <List.Item
                        key={'drive-node-'+item.path}
                        data-name='drive-file'
                        data-value={item.path}
                        onClick={this.handleClick}
                    >
                        <List.Icon name={item.type} />
                        <List.Content>
                            <List.Header>{item.name}</List.Header>
                        </List.Content>
                    </List.Item>
                );
            default:
                return;
        }
    }

    renderStep1(){
        const {
                mode,
                data
            }=this.state,
            {t}=this.props;

        const renderFile=(file,i,j)=>{
                return (
                    <Feed.Event key={'event-'+i+'-'+j}>
                        <Feed.Content>
                            <Feed.Extra images>
                                <Button
                                    icon
                                    size='tiny'
                                    data-name='documents-download'
                                    data-document={i}
                                    data-generic={file.generic}
                                    data-file={file.name}
                                    onClick={this.handleClick}
                                    title={t('workflows.document.view')}
                                    color='grey'
                                >
                                    <Icon name='file pdf' />
                                    &nbsp;
                                    {{
                                        'original':t([
                                            'workflows',
                                            'document',
                                            'versions',
                                            'original'
                                        ].join('.')),
                                        'generated':t([
                                            'workflows',
                                            'document',
                                            'versions',
                                            'generated'
                                        ].join('.')),
                                        'v':t([
                                            'workflows',
                                            'document',
                                            'versions',
                                            'versioned'
                                        ].join('.'))+' '+file.name,
                                        'filled':t([
                                            'workflows',
                                            'document',
                                            'versions',
                                            'last'
                                        ].join('.'))
                                    }[file.generic]}
                                </Button>
                            </Feed.Extra>
                        </Feed.Content>
                    </Feed.Event>
                );
            },
            renderDocument=(document,i)=>{
                if(data.stage==='draft'){
                    return (
                        <Table.Row key={'step1-'+i}>
                            <Table.Cell
                                textAlign='left'
                            >
                                <Header.Content>
                                    {document.filepath}
                                    <Header.Subheader>
                                        {t('workflows.document.size')}
                                        :&nbsp;
                                        {filesize(document.size)}
                                        &nbsp;
                                        |
                                        &nbsp;
                                        {t('workflows.document.pages')}
                                        :&nbsp;
                                        {document.total}
                                    </Header.Subheader>
                                </Header.Content>
                            </Table.Cell>
                            <Table.Cell
                                width={2}
                                textAlign='center'
                            >
                                {mode==='edit'&&
                                    <Button
                                        icon='chevron up'
                                        size='tiny'
                                        data-name='documents-up'
                                        data-index={i}
                                        onClick={this.handleClick}
                                        title={t('templates.fields.moveUp')}
                                        disabled={i===0}
                                    />
                                }
                                {mode==='edit'&&
                                    <Button
                                        icon='chevron down'
                                        size='tiny'
                                        data-name='documents-bottom'
                                        data-index={i}
                                        onClick={this.handleClick}
                                        title={t('templates.fields.getDown')}
                                        disabled={
                                            i===data.documents.length-1
                                        }
                                    />
                                }
                            </Table.Cell>
                            <Table.Cell
                                textAlign='right'
                            >
                                {mode==='edit'&&
                                    <Button
                                        icon='eye'
                                        size='tiny'
                                        data-name='documents-viewer'
                                        data-index={i}
                                        onClick={this.handleClick}
                                        title={t('workflows.document.preview')}
                                    />
                                }
                                {mode==='edit'&&
                                    <Button
                                        icon='times'
                                        size='tiny'
                                        data-name='documents-remove'
                                        data-index={i}
                                        onClick={this.handleClick}
                                        title={t('templates.fields.remove')}
                                        negative
                                    />
                                }
                            </Table.Cell>
                        </Table.Row>
                    );
                }else{
                    const match1=new RegExp(
                            '(filled|generated|original).'+i+'.pdf'
                        ),
                        match2=new RegExp('v(\\d+).'+i+'.pdf');

                    return (
                        <Table.Row key={'step1-'+i}>
                            <Table.Cell
                                width={6}
                                style={{
                                    verticalAlign:'top'
                                }}
                            >
                                <Header.Content>
                                    {document.filepath}
                                    <Header.Subheader>
                                        {t('workflows.document.size')}
                                        :&nbsp;
                                        {filesize(document.size)}
                                        &nbsp;
                                        |
                                        &nbsp;
                                        {t('workflows.document.pages')}
                                        :&nbsp;
                                        {document.total}
                                    </Header.Subheader>
                                </Header.Content>
                            </Table.Cell>
                            <Table.Cell
                                colSpan='2'
                                width={10}
                            >
                                <Feed>
                                    {data.files
                                    .filter((j)=>{
                                        return match1.test(j)||
                                            match2.test(j);//||match3.test(j);
                                    })
                                    .map((j)=>{
                                        const _j=j.substring(0,j.indexOf('.')),
                                            _k=_j.startsWith('v');

                                        return {
                                            generic:_k?'v':_j,
                                            name:j
                                        };
                                    })
                                    .sort((j,k)=>{
                                        const w={
                                            'original':0,
                                            'generated':1,
                                            'filled':3
                                        };

                                        if(j.generic in w){
                                            if(k.generic in w){
                                                return w[j.generic]
                                                    <w[k.generic];
                                            }else{
                                                return w[j.generic]<2;
                                            }
                                        }else{
                                            if(k.generic in w){
                                                return 2<w[k.generic];
                                            }else{
                                                return 1;
                                            }
                                        }
                                    })
                                    .map((file,j)=>{
                                        return renderFile(file,i,j);
                                    })}
                                </Feed>
                            </Table.Cell>
                        </Table.Row>
                    );
                }
            };

        return (
            <Fragment>
                <Header as='h4'>
                    {t('workflows.document.list')}
                </Header>

                <Table size='small'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                colSpan='3'
                                textAlign='center'
                            >
                                {t('workflows.workflow.step1.title')}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {data.documents.map((document,i)=>{
                            return renderDocument(document,i);
                        })}
                        {mode==='edit'&&
                            <Table.Row>
                                <Table.Cell colSpan='3'>
                                    <Uploader
                                        onSuccess={this.handleUpload}
                                        onError={this.handleError}
                                        setState={this.setState}
                                        className='uploader'
                                        type='area'
                                        t={t}
                                    >
                                        {data.documents.length===0&&
                                            <Message color='red'>
                                                {t('workflows.document.empty')}
                                            </Message>
                                        }
                                        {data.documents.length!==0&&
                                            <Message color='blue'>
                                                {t('workflows.document.upload')}
                                            </Message>
                                        }
                                    </Uploader>
                                </Table.Cell>
                            </Table.Row>
                        }
                    </Table.Body>
                    {mode==='edit'&&
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan='3'>
                                    <Button
                                        icon
                                        labelPosition='left'
                                        type='button'
                                        data-name='drive'
                                        onClick={this.handleClick}
                                        title={t('workflows.document.store')}
                                    >
                                        <Icon name='disk' />
                                        {t('workflows.document.store')}
                                    </Button>
                                    <Uploader
                                        onSuccess={this.handleUpload}
                                        onError={this.handleError}
                                        setState={this.setState}
                                        type='button'
                                        t={t}
                                    />
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    }
                </Table>
            </Fragment>
        );
    }

    recipientPhone(){
        const {
                data,
                params
            }=this.state,
            {t}=this.props;

        return (
            <Form inverted>
                <Form.Group>
                    <Form.Select
                        options={this.getPhoneCodes()}
                        data-name='recipient-2fa-phone-country'
                        value={params.country}
                        onChange={this.handleChange}
                        width={4}
                        label={t('workflows.2fa.phone.country')}
                        search
                        selection
                        disabled={data.stage==='published'}
                    />
                    <Form.Field width={12}>
                        <label htmlFor='phone'>
                            {t('workflows.2fa.phone.number')}
                        </label>
                        <Input
                            label={params.code}
                            type='text'
                            name='phone'
                            data-name='recipient-2fa-phone-number'
                            value={params.number}
                            onChange={this.handleChange}
                            fluid
                            required
                            readOnly={data.stage==='published'}
                        />
                    </Form.Field>
                </Form.Group>
            </Form>
        );
    }

    recipientPassword(){
        const {
                mode,
                data,
                params
            }=this.state,
            {t}=this.props;

        return (
            <Form inverted>
                <Form.Group>
                    <Form.Field width={16}>
                        <label htmlFor='password'>
                            {t('workflows.2fa.password.required')}
                        </label>
                        <Input
                            type='text'
                            name='password'
                            value={params.password}
                            data-name='recipient-2fa-password'
                            onChange={this.handleChange}
                            fluid
                            required
                            readOnly={data.stage==='published'}
                        />
                    </Form.Field>
                </Form.Group>
                {mode==='edit'&&
                    <Form.Group>
                        <Form.Field>
                            <Button
                                data-name='recipient-2fa-password-generate'
                                onClick={this.handleClick}
                                title={t('workflows.2fa.password.generate')}
                                disabled={data.stage==='published'}
                            >
                                {t('utils.actions.generate')}
                            </Button>
                        </Form.Field>
                    </Form.Group>
                }
            </Form>
        );
    }

    recipientQA(){
        const {
                mode,
                data,
                params,
                question,
                answer,
                editQA,
                editQA_index
            }=this.state,
            {t}=this.props;

        return (
            <Form inverted>
                <label htmlFor='questions'>
                    {t('templates.fields.possibleValues')}
                </label>
                <Table
                    name='questions'
                    size='small'
                    inverted
                >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                textAlign='center'
                                width={1}
                            >
                                #
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                width={7}
                                textAlign='center'
                            >
                                {t('templates.q&a.question')}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                textAlign='center'
                                width={5}
                            >
                                {t('templates.q&a.answer')}
                            </Table.HeaderCell>
                            {mode==='edit'&&
                                <Table.HeaderCell
                                    textAlign='right'
                                    width={3}
                                >
                                    &nbsp;
                                </Table.HeaderCell>
                            }
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {(params.questions||[]).map((item,i)=>{
                            return (
                                <Table.Row
                                    key={'recipient-qa-'+i}
                                >
                                    <Table.Cell>
                                        {i+1}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {editQA&&editQA_index===i?
                                            <Input
                                                type='text'
                                                placeholder={
                                                    t('templates.q&a.question')
                                                }
                                                value={question}
                                                data-name={
                                                    'recipient-2fa-q&a-question'
                                                }
                                                onChange={this.handleChange}
                                                fluid
                                            />:
                                            <Fragment>
                                                {item.question}
                                            </Fragment>
                                        }
                                    </Table.Cell>
                                    <Table.Cell>
                                        {editQA&&editQA_index===i?
                                            <Input
                                                type='text'
                                                placeholder={
                                                    t('templates.q&a.answer')
                                                }
                                                value={answer}
                                                data-name={
                                                    'recipient-2fa-q&a-answer'
                                                }
                                                onChange={this.handleChange}
                                                fluid
                                            />:
                                            <Fragment>
                                                {item.answer}
                                            </Fragment>
                                        }
                                    </Table.Cell>
                                    {mode==='edit'&&
                                        <Table.Cell
                                            textAlign='right'
                                        >
                                            {editQA?
                                                <Button
                                                    icon='save'
                                                    size='tiny'
                                                    data-name={
                                                        'recipient-2fa-q&a-save'
                                                    }
                                                    data-index={i}
                                                    onClick={this.handleClick}
                                                    title={t([
                                                        'templates',
                                                        'fields',
                                                        'valueEdit'
                                                    ].join('.'))}
                                                    disabled={
                                                        data.stage===
                                                        'published'||
                                                        editQA_index!==i
                                                    }
                                                />:
                                                <Button
                                                    icon='edit'
                                                    size='tiny'
                                                    data-name={
                                                        'recipient-2fa-q&a-edit'
                                                    }
                                                    data-index={i}
                                                    onClick={this.handleClick}
                                                    title={t([
                                                        'templates',
                                                        'fields',
                                                        'valueEdit'
                                                    ].join('.'))}
                                                    disabled={
                                                        data.stage==='published'
                                                    }
                                                />
                                            }
                                            <Button
                                                icon='times'
                                                size='tiny'
                                                data-name={
                                                    'recipient-2fa-q&a-remove'
                                                }
                                                data-index={i}
                                                onClick={this.handleClick}
                                                title={t([
                                                    'templates',
                                                    'fields',
                                                    'remove'
                                                ].join('.'))}
                                                negative
                                                disabled={
                                                    data.stage==='published'||
                                                    editQA
                                                }
                                            />
                                        </Table.Cell>
                                    }
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                    {data.stage!=='published'&&
                    mode==='edit'&&
                    !editQA&&
                        <Table.Footer>
                            <Table.Row>
                                <Table.Cell>&nbsp;</Table.Cell>
                                <Table.Cell>
                                    <Input
                                        type='text'
                                        placeholder={t([
                                            'templates',
                                            'q&a',
                                            'question'
                                        ].join('.'))}
                                        value={question}
                                        data-name='recipient-2fa-q&a-question'
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    <Input
                                        type='text'
                                        placeholder={t([
                                            'templates',
                                            'q&a',
                                            'answer'
                                        ].join('.'))}
                                        value={answer}
                                        data-name='recipient-2fa-q&a-answer'
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Table.Cell>
                                <Table.Cell
                                    textAlign='right'
                                >
                                    <Button
                                        icon='plus'
                                        size='tiny'
                                        data-name='recipient-2fa-q&a-add'
                                        onClick={this.handleClick}
                                        title={t('templates.q&a.add')}
                                        secondary
                                    />
                                </Table.Cell>
                            </Table.Row>
                        </Table.Footer>
                    }
                </Table>
                <Form.Group widths='equal'>
                    <Form.Input
                        label={t('templates.q&a.required')}
                        type='number'
                        name='required'
                        data-name='recipient-2fa-q&a-required'
                        value={params.required||0}
                        min={1}
                        max={(params.questions||[]).length}
                        onChange={this.handleChange}
                        required
                        fluid
                        readOnly={data.stage==='published'}
                    />
                    <Form.Input
                        label={t('templates.q&a.skippable')}
                        type='number'
                        name='skippable'
                        data-name='recipient-2fa-q&a-skippable'
                        value={params.skippable||0}
                        min={0}
                        max={(params.questions||[]).length-(params.required||0)}
                        onChange={this.handleChange}
                        required
                        fluid
                        readOnly={data.stage==='published'}
                    />
                </Form.Group>
            </Form>
        );
    }

    renderStep2(){
        const {
                mode,
                data,
                recipient,
                timezone
            }=this.state,
            {t}=this.props;

        let flag_notifier=true,
            is_first=()=>{
                if(flag_notifier){
                    flag_notifier=false;

                    return true;
                }

                return false;
            };

        return (
            <Fragment>
                <Header as='h3' dividing>
                    {t('workflows.recipient.list')}
                </Header>

                <Table size='small'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                textAlign='center'
                            >
                                {t('workflows.workflow.step2.title')}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                textAlign='right'
                            >
                                2FA
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                colSpan={
                                    data.ordered||data.stage==='published'?2:1
                                }
                            >
                                &nbsp;
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {data.recipients.length===0&&
                            <Table.Row>
                                <Table.Cell
                                    colSpan={
                                        data.ordered||data.stage==='published'?
                                            4:3
                                    }
                                >
                                    {mode==='edit'&&
                                        <Message color='red'>
                                            {t('workflows.recipient.empty')}
                                        </Message>
                                    }
                                </Table.Cell>
                            </Table.Row>
                        }
                        {data.recipients.map((recipient,i)=>{
                            return (
                                <Table.Row
                                    key={'step2-'+i}
                                    positive={recipient.completed}
                                >
                                    <Table.Cell
                                        width={
                                            data.ordered||
                                            data.stage==='published'?
                                                9:11
                                        }
                                        textAlign='left'
                                    >
                                        {recipient.email}
                                    </Table.Cell>
                                    <Table.Cell
                                        width={4}
                                        textAlign='right'
                                    >
                                        <Dropdown
                                            options={this.getOptions(i)}
                                            value={recipient.auth}
                                            data-name='recipients-2fa'
                                            data-index={i}
                                            onChange={this.handleChange}
                                            disabled={mode==='view'}
                                        />
                                        &nbsp;
                                        &nbsp;
                                        &nbsp;
                                        &nbsp;
                                        <Button
                                            icon='cog'
                                            size='tiny'
                                            data-name='recipients-2fa-params'
                                            data-index={i}
                                            onClick={this.handleClick}
                                            title={t([
                                                'workflows',
                                                'recipient',
                                                'params'
                                            ].join('.'))}
                                            disabled={{
                                                'email':true,
                                                'phone':false,
                                                'password':false,
                                                'firmanza':true,
                                                'q&a':false
                                            }[recipient.auth]}
                                        />
                                    </Table.Cell>
                                    {(data.ordered||data.stage==='published')&&
                                        <Table.Cell
                                            width={2}
                                            textAlign='center'
                                        >
                                            {mode==='edit'&&
                                                <Button
                                                    icon='chevron up'
                                                    size='tiny'
                                                    data-name='recipients-up'
                                                    data-index={i}
                                                    onClick={this.handleClick}
                                                    title={t([
                                                        'templates',
                                                        'fields',
                                                        'moveUp'
                                                    ].join('.'))}
                                                    disabled={i===0}
                                                />
                                            }
                                            {mode==='edit'&&
                                                <Button
                                                    icon='chevron down'
                                                    size='tiny'
                                                    data-name=
                                                        'recipients-bottom'
                                                    data-index={i}
                                                    onClick={this.handleClick}
                                                    title={t([
                                                        'templates',
                                                        'fields',
                                                        'getDown'
                                                    ].join('.'))}
                                                    disabled={i===
                                                        data.recipients.length-1
                                                    }
                                                />
                                            }
                                            {data.stage==='published'&&
                                            recipient.notified&&
                                                <Label
                                                    icon='check'
                                                    content={t([
                                                        'workflows',
                                                        'recipient',
                                                        'notification',
                                                        'notified'
                                                    ].join('.'))}
                                                />
                                            }
                                        </Table.Cell>
                                    }
                                    <Table.Cell
                                        width={1}
                                        textAlign='right'
                                    >
                                        {mode==='edit'&&
                                            <Button
                                                icon='times'
                                                size='tiny'
                                                data-name='recipients-remove'
                                                data-index={i}
                                                onClick={this.handleClick}
                                                title={t([
                                                    'templates',
                                                    'fields',
                                                    'remove'
                                                ].join('.'))}
                                                negative
                                            />
                                        }
                                        {data.stage==='published'&&
                                        !recipient.completed&&
                                        (!data.ordered||is_first())&&
                                            <Button
                                                icon='mail'
                                                size='tiny'
                                                data-name='recipients-notify'
                                                data-index={i}
                                                onClick={this.handleClick}
                                                title={t([
                                                    'workflows',
                                                    'recipient',
                                                    'notification',
                                                    'sendEmail'
                                                ].join('.'))}
                                                secondary
                                            />
                                        }
                                        &nbsp;
                                        &nbsp;
                                        {data.stage==='published'&&
                                            <Icon
                                                name={
                                                    recipient.completed?
                                                        'check':'wait'
                                                }
                                                title={
                                                    recipient.completed?
                                                        t([
                                                            'utils',
                                                            'status',
                                                            'completeRecipient'
                                                        ].join('.')):
                                                        t([
                                                            'utils',
                                                            'status',
                                                            'pendingRecipient'
                                                        ].join('.'))
                                                }
                                            />
                                        }
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                        {mode==='edit'&&
                            <Table.Row>
                                <Table.Cell
                                    width={data.ordered?9:11}
                                    textAlign='left'
                                >
                                    <Input
                                        type='email'
                                        placeholder={t([
                                            'workflows',
                                            'recipient',
                                            'notification',
                                            'email'
                                        ].join('.'))}
                                        value={recipient.email}
                                        data-name='recipient-email'
                                        onChange={this.handleChange}
                                        icon='mail'
                                        iconPosition='left'
                                        fluid
                                    />
                                </Table.Cell>
                                <Table.Cell
                                    width={4}
                                    textAlign='right'
                                >
                                    <Dropdown
                                        options={this.getOptions()}
                                        value={recipient.auth}
                                        data-name='recipient-2fa'
                                        onChange={this.handleChange}
                                    />
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    <Button
                                        icon='cog'
                                        size='tiny'
                                        data-name='recipient-2fa-params'
                                        onClick={this.handleClick}
                                        title={t([
                                            'workflows',
                                            'recipient',
                                            'params'
                                        ].join('.'))}
                                        disabled={{
                                            'email':true,
                                            'phone':false,
                                            'password':false,
                                            'firmanza':true,
                                            'q&a':false
                                        }[recipient.auth]}
                                    />
                                </Table.Cell>
                                {data.ordered&&
                                    <Table.Cell
                                        width={2}
                                        textAlign='center'
                                    >
                                        &nbsp;
                                    </Table.Cell>
                                }
                                <Table.Cell
                                    width={1}
                                    textAlign='right'
                                >
                                    <Button
                                        icon='plus'
                                        size='tiny'
                                        data-name='recipient-add'
                                        onClick={this.handleClick}
                                        title={t('templates.fields.add')}
                                        secondary
                                    />
                                    &nbsp;
                                    &nbsp;
                                </Table.Cell>
                            </Table.Row>
                        }
                    </Table.Body>
                    {mode==='edit'&&
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell
                                    colSpan={
                                        data.ordered||data.stage==='published'?
                                            4:3
                                    }
                                >
                                    <Checkbox
                                        toggle
                                        label={
                                            data.ordered?
                                                t([
                                                    'workflows',
                                                    'recipient',
                                                    'ordered',
                                                    'keep'
                                                ].join('.')):
                                                t([
                                                    'workflows',
                                                    'recipient',
                                                    'ordered',
                                                    'ignore'
                                                ].join('.'))
                                        }
                                        checked={data.ordered}
                                        data-name='ordered'
                                        onChange={this.handleChange}
                                    />
                                    <br />
                                    <br />
                                    <Checkbox
                                        toggle
                                        label={
                                            data.autonotify?
                                                t([
                                                    'workflows',
                                                    'recipient',
                                                    'notification',
                                                    'automatic'
                                                ].join('.')):
                                                t([
                                                    'workflows',
                                                    'recipient',
                                                    'notification',
                                                    'manual'
                                                ].join('.'))
                                        }
                                        checked={data.autonotify}
                                        data-name='autonotify'
                                        onChange={this.handleChange}
                                    />
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    }
                </Table>

                <Form>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label htmlFor='message'>
                                {t('workflows.recipient.message.name')}
                            </label>
                            {mode==='view'?
                                <Segment
                                    name='message'
                                    size='small'
                                >
                                    {data.message||t([
                                        'workflows',
                                        'recipient',
                                        'message',
                                        'empty'
                                    ].join('.'))}
                                </Segment>:
                                <TextArea
                                    name='message'
                                    rows='5'
                                    value={data.message}
                                    onChange={this.handleChange}
                                />
                            }
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label htmlFor='deadline'>
                                {t('workflows.recipient.maxDate')}
                                &nbsp;
                                ({timezone})
                            </label>
                            {mode==='view'?
                                <Segment
                                    name='deadline'
                                    size='small'
                                >
                                    {data.deadline?
                                        moment(data.deadline)
                                        .tz(timezone)
                                        .format('YYYY-MM-DD HH:mm:ss'):
                                        t('workflows.recipient.unlimited')
                                    }
                                </Segment>:
                                <DatePicker
                                    name='deadline'
                                    selected={data.deadline}
                                    disabled={data.unlimited}
                                    dateFormat='yyyy-MM-dd HH:mm:ss'
                                    locale='es'
                                    onChange={this.handleDatePicker}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    showTimeSelect
                                    dropdownMode='select'
                                />
                            }
                        </Form.Field>
                        <Form.Field>
                            {mode==='edit'&&
                                <Checkbox
                                    toggle
                                    label={t('workflows.recipient.unlimited')}
                                    checked={data.unlimited}
                                    data-name='unlimited'
                                    onChange={this.handleChange}
                                />
                            }
                        </Form.Field>
                    </Form.Group>
                </Form>
            </Fragment>
        );
    }

    renderGuides(){
        const {
            data,
            viewer,
            selected
        }=this.state;

        const coordinates=data
            .documents[data.currentDocument]
            .fields[selected.field]
            .positions[selected.position]
            .coordinates,
            page=data.documents[data.currentDocument].pages[viewer.page],
            d1=Math.round(coordinates.x*100)/100,
            d2=Math.round(coordinates.y*100)/100,
            d3=Math.round((page.width-(coordinates.x+coordinates.w))*100)/100,
            d4=Math.round((page.height-(coordinates.y+coordinates.h))*100)/100;

        return (
            <g>
                <rect
                    x={0}
                    y={coordinates.y}
                    width={coordinates.x}
                    height={coordinates.h}
                    style={{
                        fill:'#000',
                        fillOpacity:0.15,
                    }}
                />
                <line
                    x1={0}
                    y1={coordinates.y+0.5*coordinates.h}
                    x2={coordinates.x}
                    y2={coordinates.y+0.5*coordinates.h}
                    style={{
                        stroke:'#000',
                        strokeWidth:1.0
                    }}
                />
                <rect
                    x={0.5*coordinates.x-4-String(d1).length*3}
                    y={coordinates.y+0.5*coordinates.h-8}
                    width={8+String(d1).length*6}
                    height={16}
                    style={{
                        fill:'#000',
                    }}
                />
                <text
                    x={coordinates.x/2-(String(d1).length*3)}
                    y={coordinates.y+0.5*coordinates.h+3}
                    style={{
                        fontFamily:'monospace',
                        fontSize:10,
                        fontWeight:'bold',
                        fill:'#fff'
                    }}
                >
                    {d1}
                </text>

                <rect
                    x={coordinates.x}
                    y={0}
                    width={coordinates.w}
                    height={coordinates.y}
                    style={{
                        fill:'#000',
                        fillOpacity:0.15,
                    }}
                />
                <line
                    x1={coordinates.x+0.5*coordinates.w}
                    y1={0}
                    x2={coordinates.x+0.5*coordinates.w}
                    y2={coordinates.y}
                    style={{
                        stroke:'#000',
                        strokeWidth:1.0
                    }}
                />
                <rect
                    x={coordinates.x+0.5*coordinates.w-4-String(d2).length*3}
                    y={0.5*coordinates.y-8}
                    width={8+String(d2).length*6}
                    height={16}
                    style={{
                        fill:'#000',
                    }}
                />
                <text
                    x={coordinates.x+0.5*coordinates.w-(String(d2).length*3)
                    }
                    y={0.5*coordinates.y+3}
                    style={{
                        fontFamily:'monospace',
                        fontSize:10,
                        fontWeight:'bold',
                        fill:'#fff'
                    }}
                >
                    {d2}
                </text>

                <rect
                    x={coordinates.x+coordinates.w}
                    y={coordinates.y}
                    width={page.width-coordinates.w-coordinates.x}
                    height={coordinates.h}
                    style={{
                        fill:'#000',
                        fillOpacity:0.15,
                    }}
                />
                <line
                    x1={coordinates.x+coordinates.w}
                    y1={coordinates.y+0.5*coordinates.h}
                    x2={page.width}
                    y2={coordinates.y+0.5*coordinates.h}
                    style={{
                        stroke:'#000',
                        strokeWidth:1.0
                    }}
                />
                <rect
                    x={coordinates.x+coordinates.w+
                        0.5*(page.width-(coordinates.x+coordinates.w))-4-
                        String(d3).length*3
                    }
                    y={coordinates.y+0.5*coordinates.h-8}
                    width={8+String(d3).length*6}
                    height={16}
                    style={{
                        fill:'#000',
                    }}
                />
                <text
                    x={coordinates.x+coordinates.w+
                        0.5*(page.width-(coordinates.x+coordinates.w))-
                        (String(d3).length*3)
                    }
                    y={coordinates.y+0.5*coordinates.h+3}
                    style={{
                        fontFamily:'monospace',
                        fontSize:10,
                        fontWeight:'bold',
                        fill:'#fff'
                    }}
                >
                    {d3}
                </text>

                <rect
                    x={coordinates.x}
                    y={coordinates.y+coordinates.h}
                    width={coordinates.w}
                    height={page.height-coordinates.h-coordinates.y}
                    style={{
                        fill:'#000',
                        fillOpacity:0.15,
                    }}
                />
                <line
                    x1={coordinates.x+0.5*coordinates.w}
                    y1={coordinates.y+coordinates.h}
                    x2={coordinates.x+0.5*coordinates.w}
                    y2={page.height}
                    style={{
                        stroke:'#000',
                        strokeWidth:1.0
                    }}
                />
                <rect
                    x={coordinates.x+0.5*coordinates.w-4-String(d4).length*3}
                    y={coordinates.y+coordinates.h+
                        0.5*(page.height-(coordinates.y+coordinates.h))-8
                    }
                    width={8+String(d4).length*6}
                    height={16}
                    style={{
                        fill:'#000',
                    }}
                />
                <text
                    x={coordinates.x+0.5*coordinates.w-
                        (String(d4).length*3)
                    }
                    y={coordinates.y+coordinates.h+
                        0.5*(page.height-(coordinates.y+coordinates.h))+3
                    }
                    style={{
                        fontFamily:'monospace',
                        fontSize:10,
                        fontWeight:'bold',
                        fill:'#fff'
                    }}
                >
                    {d4}
                </text>
            </g>
        );
    }

    renderFields(index,readonly){
        const {
            mode,
            data,
            viewer,
            selected
        }=this.state;

        if(data.currentDocument>=0&&data.documents[data.currentDocument]){
            const page=data.documents[data.currentDocument].pages[index],
                fields=data.documents[data.currentDocument].fields;

            const renderPosition=(position,j,field,i)=>{
                    return (
                        <g
                            key={'editor-position-'+j}
                        >
                            <rect
                                x={position.coordinates.x}
                                y={position.coordinates.y}
                                width={position.coordinates.w}
                                height={position.coordinates.h}
                                style={{
                                    fill:'#ffe680',
                                    fillOpacity:0.75,
                                    stroke:field.mandatory?'#db2828':'#2185d0',
                                    strokeOpacity:1,
                                    strokeWidth:1
                                }}
                                data-field={i}
                                data-position={j}
                                onPointerDown={
                                    field.readonly||readonly||mode==='view'?
                                        null:this.handlePointerDown
                                }
                                onPointerMove={
                                    field.readonly||readonly||mode==='view'?
                                        null:this.handlePointerMove
                                }
                                onPointerUp={
                                    field.readonly||readonly||mode==='view'?
                                        null:this.handlePointerUp
                                }
                                onPointerOver={this.handlePointerOver}
                                onPointerOut={this.handlePointerOut}
                            />
                            {[
                                'text',
                                'list',
                                'combo',
                                'signature',
                                'signature-image',
                                'image'
                            ].includes(field.type)&&
                                <text
                                    x={position.coordinates.x+4}
                                    y={position.coordinates.y+12}
                                    style={{
                                        fontSize:10,
                                        fill:field.mandatory?'#db2828':'#2185d0'
                                    }}
                                >
                                    {
                                        field.name+' ('+
                                        (Math.round(position.coordinates.w*
                                        100)/100)+
                                        'x'+
                                        (Math.round(position.coordinates.h*
                                        100)/100)+
                                        ')'
                                    }
                                </text>
                            }
                        </g>
                    );
                },
                renderField=(field,i)=>{
                    return (
                        <g key={'editor-field-'+i}>
                            {
                                field.positions
                                .filter((position,j)=>{
                                    return position.page===viewer.page;
                                })
                                .map((position,j)=>{
                                    return renderPosition(position,j,field,i);
                                })
                            }
                        </g>
                    );
                };

            return (
                <svg
                    viewBox={'0 0 '+page.width+' '+page.height}
                    style={{
                        width:((1080/page.height)*page.width)+'px',
                        height:'1080px'
                    }}
                >
                    {!readonly&&
                        <g>
                            {[...Array(Math.ceil(page.width*2.54/72))]
                            .map((x,i)=>{
                                return (
                                    <line
                                        key={'gridx-'+i}
                                        x1={i*72/2.54}
                                        y1={0}
                                        x2={i*72/2.54}
                                        y2={page.height}
                                        style={{
                                            stroke:'#a0a0a0',
                                            strokeWidth:0.5
                                        }}
                                    />
                                );
                            })}
                            {[...Array(Math.ceil(page.height*2.54/72))]
                            .map((y,j)=>{
                                return (
                                    <line
                                        key={'gridy-'+j}
                                        x1={0}
                                        y1={j*72/2.54}
                                        x2={page.width}
                                        y2={j*72/2.54}
                                        style={{
                                            stroke:'#a0a0a0',
                                            strokeWidth:0.5
                                        }}
                                    />
                                );
                            })}
                        </g>
                    }
                    {fields.map(renderField)}
                    {selected.active&&this.renderGuides()}
                </svg>
            );
        }
    }

    renderViewer(readonly=false,mode='svg'){
        const {
                data,
                viewer
            }=this.state,
            {t}=this.props;

        return (
            <Fragment>
                <Menu
                    size='mini'
                    icon
                    compact
                    borderless
                    inverted
                    widths={5}
                    fluid
                >
                    <Menu.Item
                        icon='step backward'
                        title={t('templates.fields.first')}
                        data-name='fields-page-start'
                        onClick={this.handleClick}
                    />
                    <Menu.Item
                        icon='angle left'
                        title={t('templates.fields.previous')}
                        data-name='fields-page-previous'
                        onClick={this.handleClick}
                    />
                    <Menu.Item>
                        {viewer.filepath&&
                            <strong>
                                {Math.round((
                                    data.documents[data.currentDocument]
                                    .pages[viewer.page].width*254/72
                                ))/100+'cm x '+
                                Math.round((
                                    data.documents[data.currentDocument]
                                    .pages[viewer.page].height*254/72
                                ))/100+'cm '}
                                &nbsp;&nbsp;|&nbsp;&nbsp;
                                {viewer.page+1}
                                &nbsp;/&nbsp;
                                {viewer.total}
                            </strong>
                        }
                    </Menu.Item>
                    <Menu.Item
                        icon='angle right'
                        title={t('templates.fields.next')}
                        data-name='fields-page-next'
                        onClick={this.handleClick}
                    />
                    <Menu.Item
                        icon='step forward'
                        title={t('templates.fields.last')}
                        data-name='fields-page-end'
                        onClick={this.handleClick}
                    />
                </Menu>
                <div id='designer'>
                    {viewer.filepath&&
                        <Fragment>
                            <Document
                                className='editor'
                                renderMode={mode}
                                onLoadSuccess={this.handleViewer}
                                file={'/api/documents/view?path='+
                                    encodeURIComponent(viewer.filepath)
                                }
                            >
                                <Page
                                    loading={t('api.document')}
                                    pageNumber={viewer.page+1}
                                    renderMode={mode}
                                    height={1080}
                                />
                            </Document>
                            <div id='fields-editor'>
                                {this.renderFields(viewer.page,readonly)}
                            </div>
                        </Fragment>
                    }
                </div>
            </Fragment>
        );
    }

    formField(){
        const {
                mode,
                data,
                viewer,
                field,
                value,
                position
            }=this.state,
            {t}=this.props;

        return (
            <Form inverted>
                <Form.Checkbox
                    label={t('templates.fields.readOnly')}
                    checked={field.readonly}
                    disabled
                />
                <Form.Group widths='equal'>
                    <Form.Input
                        label={t('templates.fields.name')}
                        type='text'
                        placeholder={t([
                            'templates',
                            'fields',
                            'placeholderName'
                        ].join('.'))}
                        name='field-name'
                        data-name='field-name'
                        value={field.name}
                        onChange={this.handleChange}
                        required
                        fluid
                    />
                    <Form.Select
                        label={t('templates.fields.type')}
                        placeholder={t('templates.fields.type')}
                        options={this.getTypes()}
                        name='field-type'
                        value={field.type}
                        data-name='field-type'
                        onChange={this.handleChange}
                        required
                        fluid
                    />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Select
                        label={t('templates.signer')}
                        placeholder={t('templates.signer')}
                        name='field-recipient'
                        options={this.getRecipients()}
                        value={field.recipient}
                        data-name='field-recipient'
                        onChange={this.handleChange}
                        required
                        fluid
                    />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.TextArea
                        label={t('templates.fields.instructions')}
                        rows='5'
                        value={field.description}
                        data-name='field-description'
                        onChange={this.handleChange}
                    />
                </Form.Group>
                {['checkbox','radio','combo','list'].includes(field.type)&&
                    <Fragment>
                        <label htmlFor='values'>
                            {field.type==='checkbox'?
                                t('templates.fields.possibleValue'):
                                t('templates.fields.possibleValues')
                            }
                        </label>
                        <Table
                            name='values'
                            size='small'
                            inverted
                        >
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell
                                        textAlign='center'
                                        width={1}
                                    >
                                        #
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        width={12}
                                        textAlign='center'
                                    >
                                        {t('templates.fields.value')}
                                    </Table.HeaderCell>
                                    {mode==='edit'&&
                                        <Table.HeaderCell
                                            textAlign='right'
                                            width={3}
                                        >
                                            &nbsp;
                                        </Table.HeaderCell>
                                    }
                                    {mode==='edit'&&
                                        <Table.HeaderCell
                                            textAlign='right'
                                            width={1}
                                        >
                                            &nbsp;
                                        </Table.HeaderCell>
                                    }
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {field.values.map((value,i)=>{
                                    return (
                                        <Table.Row
                                            key={'field-value-'+i}
                                        >
                                            <Table.Cell
                                                textAlign='center'
                                                width={1}
                                            >
                                                {i+1}
                                            </Table.Cell>
                                            <Table.Cell
                                                textAlign='center'
                                            >
                                                {value}
                                            </Table.Cell>
                                            {mode==='edit'&&
                                                <Table.Cell
                                                    textAlign='right'
                                                    width={3}
                                                >
                                                    <Button
                                                        icon='chevron up'
                                                        size='tiny'
                                                        title={t([
                                                            'templates',
                                                            'fields',
                                                            'moveUp'
                                                        ].join('.'))}
                                                        data-name='value-up'
                                                        data-index={i}
                                                        onClick={
                                                            this.handleClick
                                                        }
                                                        disabled={
                                                            field.readonly||
                                                            i===0
                                                        }
                                                    />
                                                    <Button
                                                        icon='chevron down'
                                                        size='tiny'
                                                        title={t([
                                                            'templates',
                                                            'fields',
                                                            'getDown'
                                                        ].join('.'))}
                                                        data-name='value-down'
                                                        data-index={i}
                                                        onClick={
                                                            this.handleClick
                                                        }
                                                        disabled={
                                                            field.readonly||
                                                            i===field.values
                                                            .length-1
                                                        }
                                                    />
                                                </Table.Cell>
                                            }
                                            {mode==='edit'&&
                                                <Table.Cell
                                                    textAlign='right'
                                                    width={1}
                                                >
                                                    <Button
                                                        icon='times'
                                                        size='tiny'
                                                        title={t([
                                                            'templates',
                                                            'fields',
                                                            'remove'
                                                        ].join('.'))}
                                                        data-name='value-remove'
                                                        data-index={i}
                                                        onClick={
                                                            this.handleClick
                                                        }
                                                        disabled={
                                                            field.readonly||
                                                            mode==='view'
                                                        }
                                                        negative
                                                    />
                                                </Table.Cell>
                                            }
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                            {!field.readonly&&
                            mode!=='view'&&
                            !(
                                field.type==='checkbox'&&
                                field.values.length>0
                            )&&
                                <Table.Footer>
                                    <Table.Row>
                                        <Table.Cell>&nbsp;</Table.Cell>
                                        <Table.Cell
                                            textAlign='center'
                                        >
                                            <Input
                                                type='text'
                                                placeholder={t([
                                                    'templates',
                                                    'fields',
                                                    'possibleValue'
                                                ].join('.'))}
                                                value={value}
                                                data-name='field-value'
                                                onChange={this.handleChange}
                                                fluid
                                            />
                                        </Table.Cell>
                                        <Table.Cell>&nbsp;</Table.Cell>
                                        <Table.Cell
                                            textAlign='right'
                                        >
                                            <Button
                                                icon='plus'
                                                size='tiny'
                                                title={t([
                                                    'templates',
                                                    'fields',
                                                    'add'
                                                ].join('.'))}
                                                data-name='value-add'
                                                onClick={this.handleClick}
                                                secondary
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Footer>
                            }
                        </Table>
                    </Fragment>
                }
                <label htmlFor='positions'>
                    {t('templates.fields.positioning')}
                    (pts) [28.35 pts = 1 cm]
                </label>
                <Table
                    name='positions'
                    size='small'
                    inverted
                >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                textAlign='center'
                                width={1}
                            >
                                #
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                textAlign='center'
                            >
                                {t('templates.fields.page')}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                textAlign='center'
                            >
                                X
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                textAlign='center'
                            >
                                Y
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                textAlign='center'
                            >
                                {t('templates.fields.width')}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                textAlign='center'
                            >
                                {t('templates.fields.height')}
                            </Table.HeaderCell>
                            {mode==='edit'&&
                                <Table.HeaderCell
                                    textAlign='right'
                                    width={3}
                                >
                                    &nbsp;
                                </Table.HeaderCell>
                            }
                            {mode==='edit'&&
                                <Table.HeaderCell
                                    textAlign='right'
                                    width={1}
                                >
                                    &nbsp;
                                </Table.HeaderCell>
                            }
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {field.positions.map((_position,i)=>{
                            return (
                                <Table.Row
                                    key={'field-position-'+i}
                                >
                                    <Table.Cell
                                        textAlign='center'
                                    >
                                        {i+1}
                                    </Table.Cell>
                                    <Table.Cell
                                        textAlign='center'
                                    >
                                        {position.edit&&position.edit_index===i?
                                            <Input
                                                type='text'
                                                value={position.page+1}
                                                data-name='position-page'
                                                onChange={this.handleChange}
                                                fluid
                                            />:
                                            <Fragment>
                                                {_position.page+1}
                                                &nbsp;({
                                                    data.documents[
                                                    data.currentDocument
                                                    ].pages[viewer.page].width
                                                }x{
                                                    data.documents[
                                                    data.currentDocument
                                                    ].pages[viewer.page].height
                                                })
                                            </Fragment>
                                        }
                                    </Table.Cell>
                                    <Table.Cell
                                        textAlign='center'
                                    >
                                        {position.edit&&position.edit_index===i?
                                            <Input
                                                type='text'
                                                value={position.coordinates.x}
                                                data-name='position-x'
                                                onChange={this.handleChange}
                                                fluid
                                            />:
                                            _position.coordinates.x
                                        }
                                    </Table.Cell>
                                    <Table.Cell
                                        textAlign='center'
                                    >
                                        {position.edit&&position.edit_index===i?
                                            <Input
                                                type='text'
                                                value={position.coordinates.y}
                                                data-name='position-y'
                                                onChange={this.handleChange}
                                                fluid
                                            />:
                                            _position.coordinates.y
                                        }
                                    </Table.Cell>
                                    <Table.Cell
                                        textAlign='center'
                                    >
                                        {position.edit&&position.edit_index===i?
                                            <Input
                                                type='text'
                                                value={position.coordinates.w}
                                                data-name='position-w'
                                                onChange={this.handleChange}
                                                fluid
                                            />:
                                            _position.coordinates.w
                                        }
                                    </Table.Cell>
                                    <Table.Cell
                                        textAlign='center'
                                    >
                                        {position.edit&&position.edit_index===i?
                                            <Input
                                                type='text'
                                                value={position.coordinates.h}
                                                data-name='position-h'
                                                onChange={this.handleChange}
                                                fluid
                                            />:
                                            _position.coordinates.h
                                        }
                                    </Table.Cell>
                                    {mode==='edit'&&
                                        <Table.Cell
                                            textAlign='right'
                                            width={3}
                                        >
                                            <Button
                                                icon='chevron up'
                                                size='tiny'
                                                title={t([
                                                    'templates',
                                                    'fields',
                                                    'moveUp'
                                                ].join('.'))}
                                                data-name='position-up'
                                                data-index={i}
                                                onClick={this.handleClick}
                                                disabled={
                                                    field.readonly||
                                                    i===0
                                                }
                                            />
                                            <Button
                                                icon='chevron down'
                                                size='tiny'
                                                title={t([
                                                    'templates',
                                                    'fields',
                                                    'getDown'
                                                ].join('.'))}
                                                data-name='position-down'
                                                data-index={i}
                                                onClick={this.handleClick}
                                                disabled={
                                                    field.readonly||
                                                    i===field.positions.length-1
                                                }
                                            />
                                        </Table.Cell>
                                    }
                                    {mode==='edit'&&
                                        <Table.Cell
                                            textAlign='right'
                                            width={3}
                                        >
                                            {position.edit&&
                                            position.edit_index===i?
                                                <Button
                                                    icon='save'
                                                    size='tiny'
                                                    title={t([
                                                        'templates',
                                                        'fields',
                                                        'save'
                                                    ].join('.'))}
                                                    data-name='position-save'
                                                    data-index={i}
                                                    onClick={this.handleClick}
                                                    positive
                                                />:
                                                <Button
                                                    icon='edit'
                                                    size='tiny'
                                                    title={t([
                                                        'templates',
                                                        'fields',
                                                        'modify'
                                                    ].join('.'))}
                                                    data-name='position-edit'
                                                    data-index={i}
                                                    onClick={this.handleClick}
                                                    disabled={
                                                        field.readonly||
                                                        mode==='view'
                                                    }
                                                />
                                            }
                                            <Button
                                                icon='times'
                                                size='tiny'
                                                title={t([
                                                    'templates',
                                                    'fields',
                                                    'remove'
                                                ].join('.'))}
                                                data-name='position-remove'
                                                data-index={i}
                                                onClick={this.handleClick}
                                                disabled={
                                                    field.readonly||
                                                    mode==='view'||
                                                    position.edit
                                                }
                                                negative
                                            />
                                        </Table.Cell>
                                    }
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                    {!field.readonly&&
                    !position.edit&&
                    mode==='edit'&&
                    (
                        ([
                            'checkbox',
                            'radio'
                        ].includes(field.type)&&field.positions.length<
                            field.values.length)||
                        ([
                            'text',
                            'list',
                            'combo',
                            'signature',
                            'signature-image',
                            'image'
                        ].includes(field.type)&&field.positions.length<1)
                    )&&
                        <Table.Footer>
                            <Table.Row>
                                <Table.Cell
                                    textAlign='center'
                                >
                                    &nbsp;
                                </Table.Cell>
                                <Table.Cell
                                    textAlign='center'
                                >
                                    <Input
                                        type='text'
                                        value={position.page+1}
                                        data-name='position-page'
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Table.Cell>
                                <Table.Cell
                                    textAlign='center'
                                >
                                    <Input
                                        type='text'
                                        value={position.coordinates.x}
                                        data-name='position-x'
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Table.Cell>
                                <Table.Cell
                                    textAlign='center'
                                >
                                    <Input
                                        type='text'
                                        value={position.coordinates.y}
                                        data-name='position-y'
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Table.Cell>
                                <Table.Cell
                                    textAlign='center'
                                >
                                    <Input
                                        type='text'
                                        value={position.coordinates.w}
                                        data-name='position-w'
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Table.Cell>
                                <Table.Cell
                                    textAlign='center'
                                >
                                    <Input
                                        type='text'
                                        value={position.coordinates.h}
                                        data-name='position-h'
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    &nbsp;
                                </Table.Cell>
                                <Table.Cell
                                    textAlign='right'
                                >
                                    <Button
                                        icon='plus'
                                        size='tiny'
                                        title={t('templates.fields.add')}
                                        data-name='position-add'
                                        onClick={this.handleClick}
                                        secondary
                                    />
                                </Table.Cell>
                            </Table.Row>
                        </Table.Footer>
                    }
                </Table>
                <Form.Checkbox
                    label={t('templates.fields.required')}
                    checked={field.mandatory}
                    data-name='field-mandatory'
                    onChange={this.handleChange}
                    disabled={mode==='view'}
                />
            </Form>
        );
    }

    renderStep3(){
        const {
                mode,
                data,
                selected,
                templateFields
            }=this.state,
            {t}=this.props;

        const newField=()=>{
                return (
                    <List.Item>
                        <List.Icon
                            name='square outline'
                            size='large'
                            verticalAlign='middle'
                        />
                        <List.Content>
                            <List.Header>
                                {t('templates.fields.field.new')}
                            </List.Header>
                            <List.Description>
                                {t('utils.fieldMandatory.optional')}
                            </List.Description>
                        </List.Content>
                        <List.Content floated='right'>
                            <Button
                                icon='plus'
                                size='tiny'
                                title={t('templates.fields.add')}
                                data-name='field-add'
                                onClick={this.handleClick}
                                secondary
                            />
                            <Button
                                icon='trash'
                                size='tiny'
                                data-name='field-clean'
                                onClick={this.handleClick}
                                title={t('templates.fields.clean')}
                                negative
                            />
                        </List.Content>
                    </List.Item>
                );
            },
            templateField=()=>{
                return (
                    <List.Item>
                        <List.Icon
                            name='database'
                            size='large'
                            verticalAlign='middle'
                        />
                        <List.Content>
                            <List.Header>
                                <Dropdown
                                    button
                                    selection
                                    fluid
                                    options={this.getTemplatesFields()}
                                    value={templateFields}
                                    data-name='template-fields'
                                    onChange={this.handleChange}
                                />
                            </List.Header>
                            <List.Description>
                                &nbsp;
                            </List.Description>
                        </List.Content>
                        <List.Content floated='right'>
                            <Button
                                icon='refresh'
                                size='tiny'
                                data-name='template-fields-load'
                                title={t('workflows.template.load')}
                                onClick={this.handleClick}
                                disabled={templateFields===-1}
                            />
                            <Button
                                icon='save'
                                size='tiny'
                                data-name='template-fields-save'
                                title={t('workflows.template.save')}
                                onClick={this.handleClick}
                            />
                        </List.Content>
                    </List.Item>
                );
            },
            renderField=(field,i,fields)=>{
                return (
                    <List.Item
                        key={'step3-'+i}
                        className={[
                            'field',
                            selected.active&&selected.field===i?'selected':null
                        ].join(' ')}
                    >
                        <List.Icon
                            name={{
                                'checkbox':'check square',
                                'radio':'radio',
                                'text':'text cursor',
                                'list':'list ul',
                                'combo':'caret square down',
                                'signature':'edit',
                                'signature-image':'signing',
                                'image':'image'
                            }[field.type]}
                            size='large'
                            color={field.mandatory?'red':'blue'}
                            verticalAlign='middle'
                        />
                        <List.Content>
                            <List.Header>
                                {
                                    field.name+' ('+
                                    (field.mandatory?
                                        t('utils.fieldMandatory.required'):
                                        t('utils.fieldMandatory.optional')
                                    )+')'
                                }
                            </List.Header>
                            <List.Description>
                                {data.recipients.length>0&&
                                    data.recipients[field.recipient]?
                                    data.recipients[field.recipient].email:
                                    t('workflows.template.nosigner')
                                }
                            </List.Description>
                            <br />
                        </List.Content>
                        <List.Content
                            className='hidden'
                            floated='right'
                        >
                            {mode==='edit'&&
                                <Fragment>
                                    <Button
                                        icon='chevron up'
                                        size='tiny'
                                        title={t('templates.fields.moveUp')}
                                        data-name='field-up'
                                        data-index={i}
                                        onClick={this.handleClick}
                                        disabled={i===0}
                                    />
                                    <Button
                                        icon='chevron down'
                                        size='tiny'
                                        title={t('templates.fields.getDown')}
                                        data-name='field-bottom'
                                        data-index={i}
                                        onClick={this.handleClick}
                                        disabled={i===fields.length-1}
                                    />
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    <Button
                                        type='button'
                                        icon='clone'
                                        size='tiny'
                                        title={t('templates.fields.duplicate')}
                                        data-name='field-clone'
                                        data-index={i}
                                        onClick={this.handleClick}
                                    />
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    <Button
                                        icon='cog'
                                        size='tiny'
                                        title={t('templates.fields.configure')}
                                        data-name='field'
                                        data-index={i}
                                        onClick={this.handleClick}
                                    />
                                    <Button
                                        icon='times'
                                        size='tiny'
                                        title={t('templates.fields.remove')}
                                        data-name='field-remove'
                                        data-index={i}
                                        onClick={this.handleClick}
                                        disabled={field.readonly}
                                        negative
                                    />
                                </Fragment>
                            }
                            {mode==='view'&&
                                <Button
                                    icon='eye'
                                    size='tiny'
                                    title={t('templates.fields.display')}
                                    data-name='field'
                                    data-index={i}
                                    onClick={this.handleClick}
                                />
                            }
                            {data.stage!=='draft'&&
                            mode==='view'&&
                                <Label color={field.filled?'green':'grey'}>
                                    {field.filled?
                                        t('workflows.field.filled'):
                                        t('workflows.field.pending')
                                    }
                                </Label>
                            }
                        </List.Content>
                    </List.Item>
                );
            };

        return (
            <Fragment>
                <Header as='h3' dividing>
                    {t('workflows.field.title')}
                </Header>
                <Grid centered columns={2}>
                    <Grid.Column width={4}>
                        <Menu vertical fluid>
                            <Menu.Item>
                                <Dropdown
                                    selection
                                    placeholder={t([
                                        'workflows',
                                        'template',
                                        'nodocument'
                                    ].join('.'))}
                                    options={this.getDocuments()}
                                    value={data.currentDocument}
                                    data-name='fields-document'
                                    onChange={this.handleChange}
                                    fluid
                                />
                            </Menu.Item>
                        </Menu>
                        {data.currentDocument>=0&&
                            data.documents[data.currentDocument]&&
                            <List divided relaxed>
                                {data.documents[data.currentDocument].fields
                                .map(renderField)}
                                {mode==='edit'&&
                                    <Fragment>
                                        {newField()}
                                        {templateField()}
                                    </Fragment>
                                }
                            </List>
                        }
                    </Grid.Column>
                    <Grid.Column width={12}>
                        {this.renderViewer()}
                    </Grid.Column>
                </Grid>
            </Fragment>
        );
    }

    renderStep4(){
        const {
                data,
                timezone
            }=this.state,
            {t}=this.props;

        const renderField=(field,i,j)=>{
                return (
                    <List.Item
                        key={'step4-field-'+i+'-'+j}
                    >
                        <List.Icon
                            name={{
                                'checkbox':'check square',
                                'radio':'radio',
                                'text':'text cursor',
                                'list':'list ul',
                                'combo':'caret square down',
                                'signature':'edit',
                                'signature-image':'signing',
                                'image':'image'
                            }[field.type]}
                            size='large'
                            color={field.mandatory?'red':'blue'}
                            verticalAlign='top'
                        />
                        <List.Content>
                            <List.Header>
                                {
                                    field.name+' ('+
                                    (field.mandatory?
                                        t('utils.fieldMandatory.required'):
                                        t('utils.fieldMandatory.optional')
                                    )+')'
                                }
                            </List.Header>
                            <List.Description>
                                {data.recipients.length>0&&
                                    data.recipients[field.recipient]?
                                    data.recipients[field.recipient].email:
                                    t('workflows.template.nosigner')
                                }

                                {[
                                    'checkbox',
                                    'radio',
                                    'text',
                                    'list',
                                    'combo'
                                ].includes(field.type)&&
                                field.selected&&
                                field.selected.length!==0&&
                                <Fragment>
                                    <br />
                                    &laquo;{field.selected.join('|')}&raquo;
                                </Fragment>
                                }
                                {field.filled&&
                                [
                                    'image',
                                    'signature',
                                    'signature-image'
                                ].includes(field.type)&&
                                    <Fragment>
                                        <br />
                                        &laquo;
                                        {t('workflows.template.binary')}
                                        &raquo;
                                    </Fragment>
                                }
                            </List.Description>
                        </List.Content>
                    </List.Item>
                );
            },
            renderDocument=(document,i)=>{
                return (
                    <Segment
                        key={'step4-document-'+i}
                    >
                        <Label
                            ribbon='right'
                            color={i===data.currentDocument?'red':'black'}
                            data-name='summary-document'
                            data-index={i}
                            onClick={this.handleClick}
                        >
                            <Icon name='file pdf' />
                            {basename(document.filepath)}
                        </Label>
                        <List
                            animated
                            celled
                            relaxed
                        >
                            {document.fields.map((field,j)=>{
                                return renderField(field,i,j);
                            })}
                        </List>
                    </Segment>
                );
            };

        return (
            <Fragment>
                <Header as='h3' dividing>
                    {t('workflows.workflow.summary.name')}
                </Header>
                <Grid centered columns={2}>
                    <Grid.Column width={4}>
                        <Segment.Group raised>
                            {data.documents.map(renderDocument)}
                        </Segment.Group>
                    </Grid.Column>
                    <Grid.Column width={12}>
                        {this.renderViewer(true,'canvas')}
                    </Grid.Column>
                </Grid>
                <Header as='h4' dividing>
                    {t('workflows.workflow.summary.message')}
                </Header>
                <p>{data.message}</p>
                <Header as='h4' dividing>
                    {t('workflows.workflow.summary.dateline')}
                </Header>
                <p>
                    {data.deadline?
                        moment(data.deadline)
                        .tz(timezone)
                        .format('YYYY-MM-DD HH:mm:ss'):
                        t('workflows.workflow.summary.unlimited')
                    }
                </p>
            </Fragment>
        );
    }

    renderStep5(){
        const {
                data,
                timezone
            }=this.state,
            {t}=this.props;

        return (
            <Fragment>
                <Header as='h3' dividing>
                    {t('workflows.workflow.history')}
                </Header>
                <Table size='small'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={1}>
                                {t('utils.headers.date')}
                            </Table.HeaderCell>
                            <Table.HeaderCell width={1}>
                                {t('utils.headers.state')}
                            </Table.HeaderCell>
                            <Table.HeaderCell width={2}>
                                {t('utils.headers.modification')}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t('utils.headers.details')}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {data.changes.map((change,i)=>{
                            return (
                                <Table.Row key={'step5-'+i}>
                                    <Table.Cell width={1}>
                                        {moment(change.ts)
                                        .tz(timezone)
                                        .format('YYYY-MM-DD HH:mm:ss')}
                                    </Table.Cell>
                                    <Table.Cell width={2}>
                                        {humanStatus(change.stage,t)}
                                    </Table.Cell>
                                    <Table.Cell width={1}>
                                        {humanStatus(change.event.name,t)}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <pre style={{
                                            textAlign:'left',
                                            marginTop:0,
                                            marginBottom:0,
                                            fontSize:'.9em',
                                            width:'600px',
                                            overflow:'auto'
                                        }}>
                                            {jsonPrint(
                                                change.event.details||{}
                                            )}
                                        </pre>
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            </Fragment>
        );
    }

    render(){
        const {
                mode,
                id,
                data,
                drive,
                document,
                templateQA,
                modal,
                modal_type,
                loading
            }=this.state,
            {t}=this.props;

        if(['add','edit'].includes(mode)){
            if(
                !this.props.auth.logged||
                !acl(this.props.auth,[['workflow:create']])
            ){
                return (
                    <Redirect to='/' />
                );
            }
        }else if(['view'].includes(mode)){
            if(
                !this.props.auth.logged||
                !acl(this.props.auth,[['workflow:list']])
            ){
                return (
                    <Redirect to='/' />
                );
            }
        }

        return (
            <Container fluid>
                <Header as='h1'>{t('menubar.workflows')}</Header>

                <div className='ui breadcrumb'>
                    <a href='/' className='section'>
                        {t('menubar.home')}
                    </a>
                    <i aria-hidden='true'
                        className='right angle icon divider'></i>
                    <a href='/workflows' className='section'>
                        {t('menubar.workflows')}
                    </a>
                    <i aria-hidden='true'
                        className='right angle icon divider'></i>
                    {
                        {
                            'view':<div className='active section'>
                                {t('workflows.view')}
                            </div>,
                            'edit':<div className='active section'>
                                {t('workflows.edit')}
                            </div>
                        }[mode]
                    }
                </div>
                <br />

                <Menu tabular>
                    <Menu.Item
                        as='a'
                        href='/workflows'
                        name={t('workflows.active')}
                    />
                    {acl(this.props.auth,[['workflow:archive']])&&
                        <Menu.Item
                            as='a'
                            href='/workflows/archive'
                            name={t('workflows.archived')}
                        />
                    }
                    {{
                        'view':
                            <Menu.Item
                                as='a'
                                href={'/workflows/'+id+'/view'}
                                name={t('workflows.view')}
                                active
                            />,
                        'edit':
                            <Menu.Item
                                as='a'
                                href='/workflows/edit'
                                name={t('workflows.edit')}
                                active
                            />,
                    }[mode]}
                </Menu>

                <Form>
                    {mode==='view'?
                        <Segment
                            name='text'
                            size='small'
                            textAlign='center'
                            color='black'
                            inverted
                        >
                            <i>&laquo;{{
                                'draft':t('utils.status.draft'),
                                'published':t('utils.status.published'),
                                'expired':t('utils.status.expired'),
                                'completed':t('utils.status.completed'),
                                'finished':t('utils.status.finished')
                            }[data.stage]}&raquo;</i>
                            &nbsp;
                            {data.name}
                        </Segment>:
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <Input
                                    name='name'
                                    placeholder={t('workflows.workflow.name')}
                                    value={data.name}
                                    onChange={this.handleChange}
                                    action={
                                        <Button
                                            data-name='title'
                                            title={t('workflows.workflow.save')}
                                            onClick={this.handleClick}
                                            primary
                                        >
                                            {t('workflows.workflow.rename')}
                                        </Button>
                                    }
                                    icon='list'
                                    iconPosition='left'
                                    fluid
                                />
                            </Form.Field>
                        </Form.Group>
                    }
                </Form>

                {this.renderSteps(data)}

                <Dimmer.Dimmable dimmed={loading}>
                    {[
                        this.renderStep1(),
                        this.renderStep2(),
                        this.renderStep3(),
                        this.renderStep4(),
                        this.renderStep5()
                    ][data.currentStep]}

                    <Divider />

                    {data.currentStep===this.steps&&
                        data.stage==='draft'&&
                        <Button
                            icon
                            color='green'
                            labelPosition='right'
                            floated='right'
                            type='submit'
                            title={t('utils.actions.publish')}
                            data-name='publish'
                            onClick={this.handleClick}
                        >
                            <Icon name='send' />
                            {t('utils.actions.publish')}
                        </Button>
                    }
                    {data.currentStep===this.steps&&
                        ['publised','completed'].includes(data.stage)&&
                        <Button
                            icon
                            color='violet'
                            labelPosition='right'
                            floated='right'
                            type='submit'
                            title={t('utils.actions.finalize')}
                            data-name='finalize'
                            onClick={this.handleClick}
                        >
                            <Icon name='lock' />
                            {t('utils.actions.finalize')}
                        </Button>
                    }
                    {data.currentStep<this.steps&&
                        <Button
                            icon
                            color='blue'
                            labelPosition='right'
                            floated='right'
                            type='button'
                            data-name='next'
                            onClick={this.handleClick}
                            title={t('workflows.workflow.next')}
                        >
                            <Icon name='chevron right' />
                            {t('utils.actions.next')}
                        </Button>
                    }
                    {data.currentStep!==0&&
                        <Button
                            icon
                            labelPosition='left'
                            floated='right'
                            type='button'
                            data-name='previous'
                            onClick={this.handleClick}
                            title={t('workflows.workflow.previous')}
                        >
                            <Icon name='chevron left' />
                            {t('utils.actions.previous')}
                        </Button>
                    }
                    <Dropdown
                        text={t('utils.actions.options')}
                        icon='tasks'
                        className='icon'
                        labeled
                        button
                    >
                        <Dropdown.Menu>
                            {mode==='view'&&data.stage==='draft'&&
                                <Dropdown.Item
                                    icon='save'
                                    content={t('workflows.edit')}
                                    data-name='edit'
                                    onClick={this.handleClick}
                                />
                            }
                            {modal==='edit'&&
                                <Dropdown.Item
                                    icon='save'
                                    content={t('workflows.workflow.saveDraft')}
                                    data-name='save-draft'
                                    onClick={this.handleClick}
                                />
                            }
                            <Dropdown.Item
                                icon='box'
                                content={t('workflows.archive')}
                                data-name='archive'
                                onClick={this.handleClick}
                            />
                        </Dropdown.Menu>
                    </Dropdown>

                    <Modal
                        basic
                        size='small'
                        open={modal}
                        onClose={this.handleClose}
                    >
                        {modal_type==='drive'&&
                            <Header icon='disk'
                                content={t([
                                    'workflows',
                                    'confirm',
                                    'drive',
                                    'title'
                                ].join('.'))}
                            />
                        }
                        {modal_type==='recipient-phone'&&
                            <Header icon='mobile alternate'
                                content={t([
                                    'workflows',
                                    'confirm',
                                    'recipient-phone',
                                    'title'
                                ].join('.'))}
                            />
                        }
                        {modal_type==='recipient-password'&&
                            <Header icon='key'
                                content={t([
                                    'workflows',
                                    'confirm',
                                    'recipient-password',
                                    'title'
                                ].join('.'))}
                            />
                        }
                        {modal_type==='recipient-q&a'&&
                            <Header icon='question circle'
                                content={t([
                                    'workflows',
                                    'confirm',
                                    'recipient-q&a',
                                    'title'
                                ].join('.'))}
                            />
                        }
                        {modal_type==='field'&&mode==='edit'&&
                            <Header icon='signup'
                                content={t([
                                    'templates',
                                    'fields',
                                    'confirm',
                                    'add',
                                    'title'
                                ].join('.'))}
                            />
                        }
                        {modal_type==='field'&&mode==='view'&&
                            <Header icon='signup'
                                content={t([
                                    'templates',
                                    'fields',
                                    'confirm',
                                    'edit',
                                    'title'
                                ].join('.'))}
                            />
                        }
                        {modal_type==='archive'&&
                            <Header icon='archive'
                                content={t([
                                    'workflows',
                                    'confirm',
                                    'archive',
                                    'title'
                                ].join('.'))}
                            />
                        }

                        <Modal.Content>
                            {modal_type==='drive'&&
                                <Segment>
                                    <List selection>
                                        {this.renderDrive(drive)}
                                    </List>
                                </Segment>
                            }
                            {modal_type==='recipient-phone'&&
                                this.recipientPhone()
                            }
                            {modal_type==='recipient-password'&&
                                this.recipientPassword()
                            }
                            {modal_type==='recipient-q&a'&&
                                this.recipientQA()
                            }
                            {modal_type==='file'&&
                                <Document
                                    className='viewer'
                                    renderMode='canvas'
                                    onLoadSuccess={this.handleDocument}
                                    file={
                                        '/api/documents/view?path='+
                                        encodeURIComponent(document.path)
                                    }
                                >
                                    <Page
                                        loading={t('api.document')}
                                        pageNumber={document.page+1}
                                        renderMode='canvas'
                                        height={760}
                                    />
                                </Document>
                            }
                            {modal_type==='field'&&
                                this.formField()
                            }
                            {modal_type==='archive'&&
                                <p>
                                    {t('workflows.confirm.archive.content')}
                                </p>
                            }
                        </Modal.Content>
                        <Modal.Actions>
                            <Fragment>
                                {modal_type==='file'&&
                                    <Button.Group floated='left'>
                                        <Button
                                            icon='step backward'
                                            inverted
                                            data-name='file-start'
                                            title={t([
                                                'templates',
                                                'fields',
                                                'first'
                                            ].join('.'))}
                                            onClick={this.handleClick}
                                        />
                                        <Button
                                            icon='angle left'
                                            inverted
                                            data-name='file-previous'
                                            title={t([
                                                'templates',
                                                'fields',
                                                'previous'
                                            ].join('.'))}
                                            onClick={this.handleClick}
                                        />
                                        <Button
                                            basic
                                            inverted
                                        >
                                            {document.page+1}
                                            &nbsp;/&nbsp;
                                            {document.total}
                                        </Button>
                                        <Button
                                            icon='angle right'
                                            inverted
                                            data-name='file-next'
                                            title={t([
                                                'templates',
                                                'fields',
                                                'next'
                                            ].join('.'))}
                                            onClick={this.handleClick}
                                        />
                                        <Button
                                            icon='step forward'
                                            inverted
                                            data-name='file-end'
                                            title={t([
                                                'templates',
                                                'fields',
                                                'last'
                                            ].join('.'))}
                                            onClick={this.handleClick}
                                        />
                                    </Button.Group>
                                }
                                {modal_type==='recipient-q&a'&&
                                mode==='edit'&&
                                    <Button.Group floated='left'>
                                        <Dropdown
                                            button
                                            selection
                                            options={this.getTemplatesQA()}
                                            value={templateQA}
                                            data-name='template-q&a'
                                            onChange={this.handleChange}
                                        />
                                        <Button
                                            basic
                                            icon='refresh'
                                            inverted
                                            data-name='template-q&a-load'
                                            title={t('workflows.template.load')}
                                            onClick={this.handleClick}
                                            disabled={templateQA===-1}
                                        />
                                        <Button
                                            basic
                                            icon='save'
                                            inverted
                                            data-name='template-q&a-save'
                                            title={t('workflows.template.save')}
                                            onClick={this.handleClick}
                                        />
                                    </Button.Group>
                                }
                                <Button.Group floated='right'>
                                    {modal_type==='file'&&
                                        <Button
                                            basic
                                            inverted
                                            href={
                                                '/api/documents/view?path='+
                                                encodeURIComponent(
                                                    document.path
                                                )
                                            }
                                            download={document.name}
                                        >
                                            <Icon name='download' />
                                            {t('workflows.download')}
                                        </Button>
                                    }
                                    <Button
                                        basic
                                        inverted
                                        color={
                                            ['drive','file']
                                            .includes(modal_type)?'green':'red'
                                        }
                                        onClick={this.handleClose}
                                        title={{
                                            'drive':t([
                                                'utils',
                                                'actions',
                                                'close'
                                            ].join('.')),
                                            'recipient-phone':t([
                                                'utils',
                                                'actions',
                                                'close'
                                            ].join('.')),
                                            'recipient-password':t([
                                                'utils',
                                                'actions',
                                                'close'
                                            ].join('.')),
                                            'recipient-q&a':t([
                                                'utils',
                                                'actions',
                                                'close'
                                            ].join('.')),
                                            'field':t([
                                                'utils',
                                                'actions',
                                                'close'
                                            ].join('.')),
                                            'archive':t([
                                                'utils',
                                                'bool',
                                                'no'
                                            ].join('.'))
                                        }[modal_type]}
                                    >
                                        <Icon name='remove' />
                                        {{
                                            'drive':t([
                                                'utils',
                                                'actions',
                                                'close'
                                            ].join('.')),
                                            'recipient-phone':t([
                                                'utils',
                                                'actions',
                                                'close'
                                            ].join('.')),
                                            'recipient-password':t([
                                                'utils',
                                                'actions',
                                                'close'
                                            ].join('.')),
                                            'recipient-q&a':t([
                                                'utils',
                                                'actions',
                                                'close'
                                            ].join('.')),
                                            'field':t([
                                                'utils',
                                                'actions',
                                                'close'
                                            ].join('.')),
                                            'archive':t([
                                                'utils',
                                                'bool',
                                                'no'
                                            ].join('.'))
                                        }[modal_type]}
                                    </Button>
                                    {[
                                        'recipient-phone',
                                        'recipient-password',
                                        'recipient-q&a'
                                    ].includes(modal_type)&&mode==='edit'&&
                                        <Button
                                            inverted
                                            color='green'
                                            data-name={'modal-'+modal_type}
                                            title={t('utils.actions.save')}
                                            onClick={this.handleClick}
                                        >
                                            <Icon name='checkmark' />
                                            {t('utils.actions.save')}
                                        </Button>
                                    }
                                    {modal_type==='field'&&mode==='edit'&&
                                        <Button
                                            inverted
                                            color='green'
                                            data-name={'modal-'+modal_type}
                                            title={t('utils.actions.save')}
                                            onClick={this.handleClick}
                                        >
                                            <Icon name='checkmark' />
                                            {t('utils.actions.save')}
                                        </Button>
                                    }
                                    {modal_type==='archive'&&
                                        <Button
                                            inverted
                                            color='green'
                                            data-name={'modal-'+modal_type}
                                            onClick={this.handleClick}
                                            title={t('utils.bool.yes')}
                                        >
                                            <Icon name='checkmark' />
                                            {t('utils.bool.yes')}
                                        </Button>
                                    }
                                </Button.Group>
                            </Fragment>
                        </Modal.Actions>
                    </Modal>

                    <Dimmer active={loading}>
                        <Loader
                            active={loading}
                            indeterminate>
                            {t('api.sending')}
                        </Loader>
                    </Dimmer>
                </Dimmer.Dimmable>
            </Container>
        );
    }
}

const mapStateToProps=(state)=>{
    return {
        auth:state.auth,
        data:state.data
    };
};

const mapDispatchToProps=(dispatch)=>{
    return {
        data:(timezone,bookmarks,quota)=>{
            dispatch({
                type:UPDATE,
                timezone:timezone,
                bookmarks:bookmarks,
                quota:quota
            });
        }
    };
};

export default withTranslation('global')(
    connect(mapStateToProps,mapDispatchToProps)(WorkflowViewer)
);

