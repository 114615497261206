import moment from 'moment-timezone';
import React,{Component} from 'react';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {
    Button,
    Container,
    Grid,
    Header,
    Icon,
    Input,
    Menu,
    Message,
    Modal,
    Table
} from 'semantic-ui-react';

import {
    Pagination
} from '../components';
import {
    PAGINATION_LIMIT,
    TIMEZONE_DEFAULT
} from '../config';
import {
    apiFetch,
    message,
    acl
} from '../utils';

class WebcaptureArchive extends Component{
    constructor(props){
        super(props);

        this.state={
            error:null,
            isLoaded:false,
            total:0,
            items:[],
            search:'',
            page:1,
            modal:false,
            archive:null,
            action:null,
            timezone:this.props.data.timezone||TIMEZONE_DEFAULT
        };

        this.handleSearch=this.handleSearch.bind(this);
        this.handlePagination=this.handlePagination.bind(this);
        this.handleClose=this.handleClose.bind(this);
        this.handleClick=this.handleClick.bind(this);
    }

    loadData(search,page){
        const {t}=this.props;

        apiFetch({
            url:'/api/webcaptures/archive?'+[
                'search='+search,
                'offset='+((page-1)*PAGINATION_LIMIT),
                'limit='+PAGINATION_LIMIT
            ].join('&'),
            method:'GET',
            success:(data)=>{
                this.setState({
                    isLoaded:true,
                    total:data.total,
                    items:data.list,
                    search:search,
                    page:page
                });
            },
            error:(error)=>{
                this.setState({
                    isLoaded:true,
                    error:error
                });
            },
            t:t
        });
    }

    componentDidMount(){
        const {t}=this.props;

        apiFetch({
            url:'/api/settings/data',
            method:'GET',
            success:(data)=>{
                let obj=JSON.parse(data.data);

                this.setState({
                    timezone:obj.timezone
                });

                this.loadData('',1);
            },
            error:(error)=>{
                this.setState({
                    error:error
                });
            },
            t:t
        });
    }

    handleSearch(event,data){
        this.loadData(data.value,1);
    }

    handlePagination(event,data){
        const {
            search
        }=this.state;

        this.loadData(search,data.activePage);
    }

    handleClose(){
        this.setState({
            modal:false
        });
    }

    handleClick(event,data){
        const {
                items,
                archive
            }=this.state,
            {t}=this.props;

        event.stopPropagation();

        let index=+data['data-index'];

        switch(data['data-name']){
            case 'unarchive':
                this.setState({
                    modal:true,
                    archive:items[index]._id,
                    action:'unarchive'
                });

                break;
            case 'modal-unarchive':
                apiFetch({
                    url:'/api/webcaptures/'+archive+'/unarchive',
                    method:'POST',
                    success:(data)=>{
                        this.setState({
                            modal:false,
                            archive:null,
                            action:null
                        });

                        message(data.ok,data.message,t);

                        this.componentDidMount();
                    },
                    error:(error)=>{
                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            case 'delete':
                this.setState({
                    modal:true,
                    archive:items[index]._id,
                    action:'delete'
                });

                break;
            case 'modal-delete':
                apiFetch({
                    url:'/api/webcaptures/'+archive,
                    method:'DELETE',
                    success:(data)=>{
                        this.setState({
                            modal:false,
                            archive:null,
                            action:null
                        });

                        message(data.ok,data.message,t);

                        this.componentDidMount();
                    },
                    error:(error)=>{
                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            default:
                break;
        }
    }

    render(){
        const {
                action,
                error,
                isLoaded,
                items,
                modal,
                page,
                total,
                timezone
            }=this.state,
            {t}=this.props;

        if(
            !this.props.auth.logged||
            !acl(this.props.auth,[['webcapture:archive']])
        ){
            return (
                <Redirect to='/' />
            );
        }

        return (
            <Container fluid>
                <Header as='h1'>{t('menubar.webcaptures')}</Header>

                <div className='ui breadcrumb'>
                    <a href='/' className='section'>
                        {t('menubar.home')}
                    </a>
                    <i aria-hidden='true'
                        className='right angle icon divider'></i>
                    <a href='/webcaptures' className='section'>
                        {t('menubar.webcaptures')}
                    </a>
                    <i aria-hidden='true'
                        className='right angle icon divider'></i>
                    <div className='active section'>
                        {t('webcaptures.archived')}
                    </div>
                </div>
                <br />

                <Menu tabular>
                    <Menu.Item
                        as='a'
                        href='/webcaptures'
                        name={t('webcaptures.active')}
                    />
                    <Menu.Item
                        as='a'
                        href='/webcaptures/archive'
                        name={t('webcaptures.archived')}
                        active
                    />
                    {acl(this.props.auth,[['webcapture:generate']])&&
                        <Menu.Item
                            as='a'
                            href='/webcaptures/add'
                            name={t('webcaptures.new')}
                        />
                    }
                </Menu>

                <Grid>
                    <Grid.Column width={12}>
                        &nbsp;
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Input
                            className='search-input'
                            size='mini'
                            icon={{name:'search',
                                circular:true,
                                link:true
                            }}
                            placeholder={t('api.search')}
                            onChange={this.handleSearch}
                        />
                    </Grid.Column>
                </Grid>
                <Table compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                {t('utils.headers.details')}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                textAlign='center'
                                width={3}
                            >
                                {t('utils.headers.date')}
                            </Table.HeaderCell>
                            <Table.HeaderCell width={2}>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {error&&
                            <Table.Row>
                                <Table.Cell colSpan='3'>
                                    <Message negative>
                                        <Message.Header>
                                            {t('api.emptytable')}
                                        </Message.Header>
                                        <p>
                                            {t(error.body.message)}
                                        </p>
                                    </Message>
                                </Table.Cell>
                            </Table.Row>
                        }
                        {!isLoaded&&
                            <Table.Row>
                                <Table.Cell colSpan='3'>
                                    <Message icon info>
                                        <Icon name='circle notched' loading />
                                        <Message.Content>
                                            <Message.Header>
                                                {t('api.wait')}
                                            </Message.Header>
                                            <p>
                                                {t('api.loading')}
                                            </p>
                                        </Message.Content>
                                    </Message>
                                </Table.Cell>
                            </Table.Row>
                        }
                        {items.map((item,i)=>(
                            <Table.Row
                                key={item._id}
                                className={item.status==='inactive'?'error':''}
                            >
                                <Table.Cell>
                                    <Header as='h4' image>
                                        <Header.Content>
                                            {item.url}
                                            <Header.Subheader>
                                                {item.params.width}
                                                x
                                                {item.params.height}
                                            </Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell textAlign='center'>
                                    {moment(item.ts)
                                    .tz(timezone)
                                    .format('YYYY-MM-DD HH:mm:ss')}
                                </Table.Cell>
                                <Table.Cell textAlign='right'>
                                    <Button.Group basic size='small'>
                                        <Button
                                            icon='undo'
                                            title={t('webcaptures.unarchive')}
                                            data-name='unarchive'
                                            data-index={i}
                                            onClick={this.handleClick}
                                        />
                                        {acl(
                                            this.props.auth,
                                            [['webcapture:delete']]
                                        )&&
                                            <Button
                                                icon='trash'
                                                title={t('webcaptures.trash')}
                                                data-name='delete'
                                                data-index={i}
                                                onClick={this.handleClick}
                                            />
                                        }
                                    </Button.Group>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>

                    <Pagination
                        total={total}
                        limit={PAGINATION_LIMIT}
                        page={page}
                        colspan={3}
                        handlePagination={this.handlePagination}
                    />
                </Table>

                <Modal
                    basic
                    size='small'
                    open={modal}
                    onClose={this.handleClose}
                >
                    <Header
                        icon={action==='unarchive'?'undo':'trash'}
                        content={
                            action==='unarchive'?
                                t('webcaptures.confirm.unarchive.title'):
                                t('webcaptures.confirm.trash.title')
                        }
                    />

                    <Modal.Content>
                        {action==='unarchive'?
                            <p>
                                {t('webcaptures.confirm.unarchive.content')}
                            </p>:
                            <p>
                                {t('webcaptures.confirm.trash.content')}
                            </p>
                        }
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            basic
                            inverted
                            color='red'
                            onClick={this.handleClose}
                        >
                            <Icon name='remove' />
                            {t('utils.bool.no')}
                        </Button>
                        {action==='unarchive'?
                            <Button
                                inverted
                                color='green'
                                data-name='modal-unarchive'
                                onClick={this.handleClick}
                            >
                                <Icon name='checkmark' />
                                {t('utils.bool.yes')}
                            </Button>:
                            <Button
                                inverted
                                color='green'
                                data-name='modal-delete'
                                onClick={this.handleClick}
                            >
                                <Icon name='checkmark' />
                                {t('utils.bool.yes')}
                            </Button>
                        }
                    </Modal.Actions>
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps=(state)=>{
    return {
        auth:state.auth,
        data:state.data
    };
};

export default withTranslation('global')(
    connect(mapStateToProps)(WebcaptureArchive)
);

