import moment from 'moment-timezone';
import React,{Component} from 'react';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {
    Button,
    Container,
    Grid,
    Header,
    Icon,
    Input,
    Label,
    Menu,
    Message,
    Modal,
    Progress,
    Table
} from 'semantic-ui-react';

import {
    Pagination
} from '../components';
import {
    PAGINATION_LIMIT,
    TIMEZONE_DEFAULT
} from '../config';
import {
    apiFetch,
    humanStatus,
    message,
    acl
} from '../utils';

class WorkflowList extends Component{
    constructor(props){
        super(props);

        this.state={
            error:null,
            isLoaded:false,
            total:0,
            items:[],
            search:'',
            page:1,
            modal:false,
            archive:null,
            timezone:this.props.data.timezone||TIMEZONE_DEFAULT
        };

        this.handleSearch=this.handleSearch.bind(this);
        this.handlePagination=this.handlePagination.bind(this);
        this.handleClose=this.handleClose.bind(this);
        this.handleClick=this.handleClick.bind(this);
    }

    loadData(search,page){
        const {t}=this.props;

        apiFetch({
            url:'/api/workflows?'+[
                'search='+search,
                'offset='+((page-1)*PAGINATION_LIMIT),
                'limit='+PAGINATION_LIMIT
            ].join('&'),
            method:'GET',
            success:(data)=>{
                this.setState({
                    isLoaded:true,
                    total:data.total,
                    items:data.list,
                    search:search,
                    page:page
                });
            },
            error:(error)=>{
                this.setState({
                    isLoaded:true,
                    error:error
                });
            },
            t:t
        });
    }

    componentDidMount(){
        const {t}=this.props;

        apiFetch({
            url:'/api/settings/data',
            method:'GET',
            success:(data)=>{
                let obj=JSON.parse(data.data);

                this.setState({
                    timezone:obj.timezone
                });

                this.loadData('',1);
            },
            error:(error)=>{
                this.setState({
                    error:error
                });
            },
            t:t
        });
    }

    handleSearch(event,data){
        this.loadData(data.value,1);
    }

    handlePagination(event,data){
        const {
            search
        }=this.state;

        this.loadData(search,data.activePage);
    }

    handleClose(){
        this.setState({
            modal:false
        });
    }

    handleClick(event,data){
        const {
                items,
                archive
            }=this.state,
            {t}=this.props;

        event.stopPropagation();

        let index=+data['data-index'];

        switch(data['data-name']){
            case 'add':
                apiFetch({
                    url:'/api/workflows/',
                    method:'POST',
                    success:(data)=>{
                        this.props.history.push('/workflows/'+data.id+'/edit');

                        message(data.ok,data.message,t);
                    },
                    error:(error)=>{
                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            case 'archive':
                this.setState({
                    modal:true,
                    archive:items[index]._id
                });

                break;
            case 'modal-archive':
                apiFetch({
                    url:'/api/workflows/'+archive+'/archive',
                    method:'POST',
                    success:(data)=>{
                        this.setState({
                            modal:false,
                            archive:null
                        });

                        message(data.ok,data.message,t);

                        this.componentDidMount();
                    },
                    error:(error)=>{
                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            default:
                return;
        }
    }

    render(){
        const {
                error,
                isLoaded,
                items,
                modal,
                page,
                total,
                timezone
            }=this.state,
            {t}=this.props;

        if(
            !this.props.auth.logged||
            !acl(this.props.auth,[['workflow:list']])
        ){
            return (
                <Redirect to='/' />
            );
        }

        return (
            <Container fluid>
                <Header as='h1'>{t('menubar.workflows')}</Header>

                <div className='ui breadcrumb'>
                    <a href='/' className='section'>
                        {t('menubar.home')}
                    </a>
                    <i aria-hidden='true'
                        className='right angle icon divider'></i>
                    <div className='active section'>
                        {t('menubar.workflows')}
                    </div>
                </div>
                <br />

                <Menu tabular>
                    <Menu.Item
                        as='a'
                        href='/workflows'
                        name={t('workflows.active')}
                        active
                    />
                    {acl(this.props.auth,[['workflow:archive']])&&
                        <Menu.Item
                            as='a'
                            href='/workflows/archive'
                            name={t('workflows.archived')}
                        />
                    }
                    {acl(this.props.auth,[['workflow:create']])&&
                        <Menu.Menu position='right'>
                            <Menu.Item
                                data-name='add'
                                onClick={this.handleClick}
                            >
                                <Icon name='add' />
                                {t('workflows.new')}
                            </Menu.Item>
                        </Menu.Menu>
                    }
                </Menu>

                <Grid>
                    <Grid.Column width={12}>
                        &nbsp;
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Input
                            className='search-input'
                            size='small'
                            icon={{
                                name:'search',
                                circular:true,
                                link:true
                            }}
                            placeholder={t('api.search')}
                            onChange={this.handleSearch}
                        />
                    </Grid.Column>
                </Grid>

                <Table compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                {t('utils.headers.details')}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                textAlign='center'
                                width={1}
                            >
                                {t('utils.headers.date')}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                textAlign='center'
                                width={1}
                            >
                                {t('utils.headers.expiration')}
                            </Table.HeaderCell>
                            <Table.HeaderCell width={3}></Table.HeaderCell>
                            <Table.HeaderCell width={3}></Table.HeaderCell>
                            <Table.HeaderCell width={2}></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {error&&
                            <Table.Row>
                                <Table.Cell colSpan='6'>
                                    <Message negative>
                                        <Message.Header>
                                            {t('api.emptytable')}
                                        </Message.Header>
                                        <p>
                                            {t(error.body.message)}
                                        </p>
                                    </Message>
                                </Table.Cell>
                            </Table.Row>
                        }
                        {!isLoaded&&
                            <Table.Row>
                                <Table.Cell colSpan='6'>
                                    <Message icon info>
                                        <Icon name='circle notched' loading />
                                        <Message.Content>
                                            <Message.Header>
                                                {t('api.wait')}
                                            </Message.Header>
                                            <p>
                                                {t('api.loading')}
                                            </p>
                                        </Message.Content>
                                    </Message>
                                </Table.Cell>
                            </Table.Row>
                        }
                        {items.map((item,i)=>(
                            <Table.Row
                                key={item._id}
                                warning={item.stage==='draft'}
                                negative={item.stage==='expired'}
                                positive={
                                    item.stage==='completed'||
                                    item.stage==='finished'
                                }
                            >
                                <Table.Cell>
                                    <Header as='h4' image>
                                        <Header.Content>
                                            {item.name}
                                            <Header.Subheader>
                                                {humanStatus(item.stage,t)}
                                            </Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell textAlign='center'>
                                    {moment(item.ts)
                                    .tz(timezone)
                                    .format('YYYY-MM-DD HH:mm:ss')}
                                </Table.Cell>
                                <Table.Cell textAlign='center'>
                                    {{
                                        'draft':item.deadline?
                                            moment(item.deadline)
                                            .tz(timezone)
                                            .diff(moment(),'days')+' '+
                                                t('workflows.days'):
                                            '∞',
                                        'published':item.deadline?
                                            moment(item.deadline)
                                            .tz(timezone)
                                            .diff(moment(),'days')+' '+
                                                t('workflows.days'):
                                            '∞',
                                        'expired':'--',
                                        'completed':'--',
                                        'finished':'--'
                                    }[item.stage]}
                                </Table.Cell>
                                <Table.Cell textAlign='center'>
                                    <Label
                                        color={
                                            item.documents===0?
                                                'red':'grey'
                                        }
                                    >
                                        <Icon name='file pdf' />
                                        {item.documents}
                                    </Label>
                                    <Label
                                        color={
                                            item.recipients===0?
                                                'red':'grey'
                                        }
                                    >
                                        <Icon name='users' />
                                        {item.recipients}
                                    </Label>
                                    <Label
                                        color={
                                            item.fields===0?
                                                'red':'grey'
                                        }
                                    >
                                        <Icon name='signup' />
                                        {item.fields}
                                    </Label>
                                </Table.Cell>
                                <Table.Cell textAlign='right'>
                                    {item.stage!=='draft'&&
                                        <Progress
                                            value={item.filled}
                                            total={item.fields}
                                            progress='ratio'
                                            size='small'
                                            active={item.stage==='published'}
                                            success={item.stage==='completed'}
                                        >
                                            {t('workflows.progress')}
                                        </Progress>
                                    }
                                </Table.Cell>
                                <Table.Cell textAlign='right'>
                                    <Button.Group basic size='small'>
                                        <Button
                                            icon='eye'
                                            title={t('workflows.view')}
                                            href={
                                                '/workflows/'+item._id+'/view'
                                            }
                                        />
                                        {acl(
                                            this.props.auth,
                                            [['workflow:create']]
                                        )&&
                                            <Button
                                                icon='save'
                                                title={t('workflows.edit')}
                                                href={
                                                    '/workflows/'+item._id+
                                                    '/edit'
                                                }
                                                disabled={item.stage!=='draft'}
                                            />
                                        }
                                        {acl(
                                            this.props.auth,
                                            [['workflow:archive']]
                                        )&&
                                            <Button
                                                icon='box'
                                                title={t('workflows.archive')}
                                                data-name='archive'
                                                data-index={i}
                                                onClick={this.handleClick}
                                            />
                                        }
                                    </Button.Group>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>

                    <Pagination
                        total={total}
                        limit={PAGINATION_LIMIT}
                        page={page}
                        colspan={6}
                        handlePagination={this.handlePagination}
                    />
                </Table>

                <Modal
                    basic
                    size='small'
                    open={modal}
                    onClose={this.handleClose}
                >
                    <Header
                        icon='archive'
                        content={t('workflows.confirm.archive.title')}
                    />
                    <Modal.Content>
                        <p>
                            {t('workflows.confirm.archive.content')}
                        </p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            basic
                            inverted
                            color='red'
                            onClick={this.handleClose}
                        >
                            <Icon name='remove' />
                            {t('utils.bool.no')}
                        </Button>
                        <Button
                            inverted
                            color='green'
                            data-name='modal-archive'
                            onClick={this.handleClick}
                        >
                            <Icon name='checkmark' />
                            {t('utils.bool.yes')}
                        </Button>
                    </Modal.Actions>
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps=(state)=>{
    return {
        auth:state.auth,
        data:state.data
    };
};

export default withTranslation('global')(
    connect(mapStateToProps)(WorkflowList)
);

