import Cookies from 'js-cookie';
import React,{Component} from 'react';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {Redirect} from 'react-router-dom';
import {
    Button,
    Container,
    Image,
    Input,
    Form
} from 'semantic-ui-react';

import {
    AUTH_IN
} from '../constants';
import {
    apiFetch,
    message
} from '../utils';
 
class Signin extends Component{
    constructor(props){
        super(props);

        this.state={
            email:'',
            password:''
        };

        this.handleChange=this.handleChange.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
    }

    handleChange(event,data){
        this.setState({
            [data.name]:data.value
        });
    }

    handleSubmit(event){
        const {
                email,
                password
            }=this.state,
            {t}=this.props;

        event.preventDefault();

        apiFetch({
            url:'/api/_session',
            method:'POST',
            data:{
                email:email,
                password:password
            },
            success:(data)=>{
                let _permissions=Cookies.get('firmanza.user.permissions')||'[]',
                    _data=Cookies.get('firmanza.user.data')||'{}';

                this.props.login(JSON.parse(_permissions),JSON.parse(_data));
                this.props.history.push('/');

                message(data.ok,data.message,t);
            },
            error:(error)=>{
                message(false,error.body.message,t);
            },
            t:t
        });
    }

    render(){
        const {
                email,
                password
            }=this.state,
            {t}=this.props;

        if(this.props.auth.logged){
            return (
                <Redirect to='/' />
            );
        }

        return (
            <Container id='central'>
                <Image src='/firmanza.svg'
                    size='medium'
                    centered
                />

                <Form onSubmit={this.handleSubmit}>
                    <Form.Field>
                        <label htmlFor='email'>
                            {t('signin.email')}
                        </label>
                        <Input
                            name='email'
                            type='email'
                            value={email}
                            onChange={this.handleChange}
                            icon='mail'
                            iconPosition='left'
                            required
                        />
                    </Form.Field>
                    <Form.Field>
                        <label htmlFor='password'>
                            {t('signin.password')}
                        </label>
                        <Input
                            name='password'
                            type='password'
                            value={password}
                            onChange={this.handleChange}
                            icon='key'
                            iconPosition='left'
                            required
                        />
                    </Form.Field>
                    <Form.Field>
                        <a href='/forgot'>
                            {t('signin.forgot')}
                        </a>
                    </Form.Field>
                    <Button
                        color='black'
                        fluid
                    >
                        {t('utils.actions.signin')}
                    </Button>
                </Form>
            </Container>
        );
    }
}

const mapStateToProps=(state)=>{
    return {
        auth:state.auth
    };
};

const mapDispatchToProps=(dispatch)=>{
    return {
        login:(permissions,data)=>{
            dispatch({
                type:AUTH_IN,
                permissions:permissions,
                data:data
            });
        }
    };
};

export default withTranslation('global')(
    withRouter(connect(mapStateToProps,mapDispatchToProps)(Signin))
);

