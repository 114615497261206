import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import React from 'react';
import ReactDOM from 'react-dom';
import {I18nextProvider} from 'react-i18next';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';
import {Provider} from 'react-redux';
import {SemanticToastContainer} from 'react-semantic-toasts';
import {createStore} from 'redux';
import {Sidebar} from 'semantic-ui-react';

import {
    Menubar,
    Offcanvas
} from './components';
import {
    CertificateArchive,
    CertificateList,
    CertificateViewer,
    DocumentList,
    Forgot,
    Home,
    MailArchive,
    MailList,
    MailViewer,
    Reset,
    Settings,
    Signer,
    Signin,
    TemplateFieldsList,
    TemplateFieldsViewer,
    TemplateQA,
    WebcaptureArchive,
    WebcaptureList,
    WebcaptureViewer,
    WorkflowArchive,
    WorkflowList,
    WorkflowViewer
} from './containers';

import allReducers from './reducers';
import * as serviceWorker from './serviceWorker';

import 'semantic-ui-css/components/reset.min.css';
import 'semantic-ui-css/components/site.min.css';
import 'semantic-ui-css/components/container.min.css';
import 'semantic-ui-css/components/icon.min.css';
import 'semantic-ui-css/components/message.min.css';
import 'semantic-ui-css/components/header.min.css';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import './index.css';

import en from './translations/strings.en.json';
import es from './translations/strings.es.json';

const store=createStore(
    allReducers,
    window.__REDUX_DEVTOOLS_EXTENSION__&&
    window.__REDUX_DEVTOOLS_EXTENSION__()
);

i18next
.use(LanguageDetector)
.init({
    lng:localStorage.getItem('lang')||'es',
    fallbackLng:'es',
    debug:false,
    resources:{
        es:{
            global:es
        },
        en:{
            global:en
        }
    },
    detection:{
        order:['localStorage','navigator'],
        lookupQuerystring:'lng',
        lookupLocalStorage:'lang',
        caches:['localStorage']
    },
    interpolation:{
        escapeValue:false
    }
});

document.getElementById('root')&&
    ReactDOM.render(
        <Provider store={store}>
            <I18nextProvider i18n={i18next}>
                <Router>
                    <Menubar />
                    <Sidebar.Pushable>
                        <Offcanvas />
                        <Sidebar.Pusher>
                            <Switch>
                                <Route exact
                                    path='/'
                                    component={Home}
                                />
                                <Route exact
                                    path='/signin'
                                    component={Signin}
                                />

                                <Route exact
                                    path='/forgot'
                                    component={Forgot}
                                />
                                <Route exact
                                    path='/reset/:key'
                                    component={Reset}
                                />

                                <Route exact
                                    path='/certificates'
                                    component={CertificateList}
                                />
                                <Route exact
                                    path='/certificates/archive'
                                    component={CertificateArchive}
                                />
                                <Route exact
                                    path='/certificates/add'
                                    render={(props)=>
                                        <CertificateViewer
                                            mode='add'
                                            {...props}
                                        />
                                    }
                                />
                                <Route exact
                                    path='/certificates/:resource/view'
                                    render={(props)=>
                                        <CertificateViewer
                                            mode='view'
                                            {...props}
                                        />
                                    }
                                />
                                <Route exact
                                    path='/certificates/:resource/edit'
                                    render={(props)=>
                                        <CertificateViewer
                                            mode='edit'
                                            {...props}
                                        />
                                    }
                                />

                                <Route exact
                                    path='/documents'
                                    component={DocumentList}
                                />

                                <Route exact
                                    path='/mails'
                                    component={MailList}
                                />
                                <Route exact
                                    path='/mails/archive'
                                    component={MailArchive}
                                />
                                <Route exact
                                    path='/mails/:resource/view'
                                    render={(props)=>
                                        <MailViewer
                                            mode='view'
                                            {...props}
                                        />
                                    }
                                />

                                <Route exact
                                    path='/webcaptures'
                                    component={WebcaptureList}
                                />
                                <Route exact
                                    path='/webcaptures/archive'
                                    component={WebcaptureArchive}
                                />
                                <Route exact
                                    path='/webcaptures/add'
                                    render={(props)=>
                                        <WebcaptureViewer
                                            mode='add'
                                            {...props}
                                        />
                                    }
                                />
                                <Route exact
                                    path='/webcaptures/:resource/view'
                                    render={(props)=>
                                        <WebcaptureViewer
                                            mode='view'
                                            {...props}
                                        />
                                    }
                                />
                                <Route exact
                                    path='/webcaptures/:resource/edit'
                                    render={(props)=>
                                        <WebcaptureViewer
                                            mode='edit'
                                            {...props}
                                        />
                                    }
                                />

                                <Route exact
                                    path='/workflows'
                                    component={WorkflowList}
                                />
                                <Route exact
                                    path='/workflows/archive'
                                    component={WorkflowArchive}
                                />
                                <Route exact
                                    path='/workflows/:resource/view'
                                    render={(props)=>
                                        <WorkflowViewer
                                            mode='view'
                                            {...props}
                                        />
                                    }
                                />
                                <Route exact
                                    path='/workflows/:resource/edit'
                                    render={(props)=>
                                        <WorkflowViewer
                                            mode='edit'
                                            {...props}
                                        />
                                    }
                                />

                                <Route exact
                                    path='/templates/qa'
                                    component={TemplateQA}
                                />
                                <Route exact
                                    path='/templates/fields'
                                    component={TemplateFieldsList}
                                />
                                <Route exact
                                    path='/templates/fields/add'
                                    render={(props)=>
                                        <TemplateFieldsViewer
                                            mode='add'
                                            {...props}
                                        />
                                    }
                                />
                                <Route exact
                                    path='/templates/fields/:template/view'
                                    render={(props)=>
                                        <TemplateFieldsViewer
                                            mode='view'
                                            {...props}
                                        />
                                    }
                                />
                                <Route exact
                                    path='/templates/fields/:template/edit'
                                    render={(props)=>
                                        <TemplateFieldsViewer
                                            mode='edit'
                                            {...props}
                                        />
                                    }
                                />

                                <Route exact
                                    path='/signer/:key'
                                    component={Signer}
                                />

                                <Route exact
                                    path='/settings'
                                    component={Settings}
                                />
                            </Switch>
                        </Sidebar.Pusher>
                    </Sidebar.Pushable>
                    <SemanticToastContainer
                        position='bottom-center'
                        className='snackbar'
                    />
                </Router>
            </I18nextProvider>
        </Provider>,
        document.getElementById('root')
    );

serviceWorker.unregister();

