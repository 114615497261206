import React,{Component} from 'react';
import {withTranslation} from 'react-i18next';
import {loadReCaptcha,ReCaptcha} from 'react-recaptcha-v3';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {
    Button,
    Container,
    Header,
    Input,
    Form,
    Label
} from 'semantic-ui-react';

import {
    CAPTCHA_PUBLIC
} from '../config';
import {
    apiFetch,
    message
} from '../utils';

class Forgot extends Component{
    constructor(props){
        super(props);

        this.state={
            email1:'',
            email2:'',
            captcha:'',
            invalid_email:false,
            invalid_email_text:''
        };

        this.handleCaptcha=this.handleCaptcha.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
    }

    componentDidMount(){
        loadReCaptcha(CAPTCHA_PUBLIC);
    }

    handleCaptcha(token){
        this.setState({
            captcha:token
        });
    }

    handleChange(event,data){
        const {
            email1,
            email2
        }=this.state;

        this.setState({
            [data.name]:data.value
        });

        if(data.name==='email1'){
            this.setState({
                invalid_email:(email2!==data.value)
            });
        }

        if(data.name==='email2'){
            this.setState({
                invalid_email:(email1!==data.value)
            });
        }
    }

    handleSubmit(event){
        const {
                email1,
                captcha
            }=this.state,
            {t}=this.props;

        event.preventDefault();

        apiFetch({
            url:'/api/forgot',
            method:'POST',
            data:{
                email:email1,
                captcha:captcha
            },
            success:(data)=>{
                message(data.ok,data.message,t);
            },
            error:(error)=>{
                message(false,error.body.message,t);
            },
            t:t
        });
    }

    render(){
        const {
                email1,
                email2,
                invalid_email
            }=this.state,
            {t}=this.props;

        if(this.props.auth.logged){
            return (
                <Redirect to='/' />
            );
        }

        return (
            <Container id='central'>
                <Header as='h1'>{t('forgot.title')}</Header>

                <Form onSubmit={this.handleSubmit}>
                    <Form.Field>
                        <label htmlFor='email1'>
                            {t('forgot.email')}
                        </label>
                        <Input
                            name='email1'
                            type='email'
                            value={email1}
                            onChange={this.handleChange}
                            icon='mail'
                            iconPosition='left'
                            required
                        />
                        {invalid_email&&
                            <Label basic color='red' pointing>
                                {t('forgot.message.invalid')}
                            </Label>
                        }
                    </Form.Field>
                    <Form.Field>
                        <label htmlFor='email2'>
                            {t('forgot.repeat')}
                        </label>
                        <Input
                            name='email2'
                            type='email'
                            value={email2}
                            onChange={this.handleChange}
                            icon='mail'
                            iconPosition='left'
                            required
                        />
                        {invalid_email&&
                            <Label basic color='red' pointing>
                                {t('forgot.message.invalid')}
                            </Label>
                        }
                    </Form.Field>
                    <Form.Field>
                        <a href='/signin'>{t('forgot.back')}</a>
                    </Form.Field>
                    <Button
                        color='black'
                        fluid
                    >
                        {t('utils.actions.send')}
                    </Button>
                </Form>

                <ReCaptcha
                    sitekey={CAPTCHA_PUBLIC}
                    action='forgot'
                    verifyCallback={this.handleCaptcha}
                />
            </Container>
        );
    }
}

const mapStateToProps=(state)=>{
    return {
        auth:state.auth
    };
};

export default withTranslation('global')(connect(mapStateToProps)(Forgot));

