export const PAGINATION_LIMIT=50;
export const COUNTRIES_WITHOUT_FLAG=[
    'aq','bl','bq','cw','gg','je','im','mf','ss','sx','xk'
];
export const CAPTCHA_PUBLIC='6LetY6YZAAAAAGkEdNKfxTwina8_uZek4G5HtT3s';

export const RESPONSIVE_TRIGGER=6*125;
export const TIMEZONE_DEFAULT='America/Panama';
export const LANGUAGE_DEFAULT='es';

export const CERTIFICATES='9fb3eeeaf87d';
export const WEBCAPTURES='0aef7788edbb';
export const MAILS='f22b48fc9eb2';
export const WORKFLOWS='7d7593da6a48';

export const URL_MANUAL=
    'https://www.firmanza.com/docs/manual de usuario firmanza.pdf';

