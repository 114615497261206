import moment from 'moment-timezone';
import React,{Component,Fragment} from 'react';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {
    Button,
    Container,
    Divider,
    Flag,
    Grid,
    Header,
    Icon,
    Image,
    Label,
    List,
    Reveal,
    Statistic
} from 'semantic-ui-react';
import {
    VictoryAxis,
    VictoryBar,
    VictoryChart,
    VictoryTheme
} from 'victory';

import {
    URL_MANUAL
} from '../config';
import {
    apiFetch,
    message,
    acl
} from '../utils';

class Home extends Component{
    constructor(props){
        super(props);

        this.state={
            profile:{
                surname:'',
                name:'',
                email:'',
                photo:''
            },
            timezone:'',
            enterprise:null,
            quota:0,
            documents:0,
            bookmarks:0,
            mails:0,
            webcaptures:0,
            certificates:[],
            workflows:[],
            templatesqa:0,
            templatesfields:0
        };
    }

    componentDidMount(){
        const {t,i18n}=this.props;

        if(this.props.auth.logged){
            apiFetch({
                url:'/api/settings/stats',
                method:'GET',
                success:(data)=>{
                    this.setState(data);

                    i18n.changeLanguage(data.language);
                },
                error:(error)=>{
                    message(false,error.body.message,t);
                },
                t:t
            });
        }
    }

    renderMenu(){
        const {
                certificates,
                workflows,
                templatesqa,
                templatesfields
            }=this.state,
            {t}=this.props;

        return (
            <List
                divided
                relaxed
                verticalAlign='middle'
            >
                {acl(this.props.auth,[['document:list']])&&
                    <Fragment>
                        <Header as='h3'>{t('menubar.documents')}</Header>
                        <List.Item>
                            <List.Icon
                                name='file alternate'
                                size='big'
                            />
                            <List.Content>
                                <List.Header
                                    as='a'
                                    href='/documents'
                                >
                                    {t('menubar.documents')}
                                </List.Header>
                            </List.Content>
                        </List.Item>
                        {acl(this.props.auth,[['mail:list']])&&
                            <List.Item>
                                <List.Icon
                                    name='mail'
                                    size='big'
                                />
                                <List.Content>
                                    <List.Header
                                        as='a'
                                        href='/mails'
                                    >
                                        {t('menubar.emails')}
                                    </List.Header>
                                </List.Content>
                            </List.Item>
                        }
                        {acl(this.props.auth,[['webcapture:list']])&&
                            <List.Item>
                                <List.Content floated='right'>
                                    <Button
                                        icon
                                        as='a'
                                        size='mini'
                                        href='/webcaptures/add'
                                    >
                                        <Icon name='plus' />
                                    </Button>
                                </List.Content>
                                <List.Icon
                                    name='camera'
                                    size='big'
                                />
                                <List.Content>
                                    <List.Header
                                        as='a'
                                        href='/webcaptures'
                                    >
                                        {t('menubar.webcaptures')}
                                    </List.Header>
                                </List.Content>
                            </List.Item>
                        }
                    </Fragment>
                }

                {acl(this.props.auth,[['certificate:list']])&&
                    <Fragment>
                        <Header as='h3'>{t('menubar.certificates')}</Header>
                        <List.Item>
                            <List.Content floated='right'>
                                <Label>
                                    {
                                        certificates
                                        .filter(i=>i.type==='active')
                                        .length
                                    }
                                </Label>
                            </List.Content>
                            <List.Icon
                                name='address card'
                                size='big'
                            />
                            <List.Content>
                                <List.Header
                                    as='a'
                                    href='/certificates'
                                >
                                    {t('menubar.certificates')}
                                </List.Header>
                            </List.Content>
                        </List.Item>
                        {acl(this.props.auth,[['certificate:archive']])&&
                            <List.Item>
                                <List.Content floated='right'>
                                    <Label>
                                        {
                                            certificates
                                            .filter(i=>i.type==='archived')
                                            .length
                                        }
                                    </Label>
                                </List.Content>
                                <List.Icon
                                    name='address card'
                                    size='big'
                                />
                                <List.Content>
                                    <List.Header
                                        as='a'
                                        href='/cerificates/archive'
                                    >
                                        {t('certificates.archived')}
                                    </List.Header>
                                </List.Content>
                            </List.Item>
                        }
                        {acl(this.props.auth,[['certificate:register']])&&
                            <List.Item>
                                <List.Icon
                                    name='address card'
                                    size='big'
                                />
                                <List.Content>
                                    <List.Header
                                        as='a'
                                        href='/certificates/add'
                                    >
                                        {t('certificates.register')}
                                    </List.Header>
                                </List.Content>
                            </List.Item>
                        }
                    </Fragment>
                }

                {acl(this.props.auth,[['workflow:list']])&&
                    <Fragment>
                        <Header as='h3'>{t('menubar.workflows')}</Header>
                        <List.Item>
                            <List.Content floated='right'>
                                <Label>
                                    {
                                        workflows
                                        .filter(i=>i.type==='active')
                                        .length
                                    }
                                </Label>
                            </List.Content>
                            <List.Icon
                                name='list alternate'
                                size='big'
                            />
                            <List.Content>
                                <List.Header
                                    as='a'
                                    href='/workflows'
                                >
                                    {t('menubar.workflows')}
                                </List.Header>
                            </List.Content>
                        </List.Item>
                        {acl(this.props.auth,[['workflow:archive']])&&
                            <List.Item>
                                <List.Content floated='right'>
                                    <Label>
                                        {
                                            workflows
                                            .filter(i=>i.type==='archived')
                                            .length
                                        }
                                    </Label>
                                </List.Content>
                                <List.Icon
                                    name='list alternate'
                                    size='big'
                                />
                                <List.Content>
                                    <List.Header
                                        as='a'
                                        href='/workflows/archive'
                                    >
                                        {t('workflows.archived')}
                                    </List.Header>
                                </List.Content>
                            </List.Item>
                        }
                    </Fragment>
                }

                {acl(this.props.auth,[['templates:q&a'],['templates:fields']])&&
                    <Fragment>
                        <Header as='h3'>
                            {t('menubar.templates')}
                        </Header>

                        {acl(this.props.auth,[['templates:q&a']])&&
                            <List.Item>
                                <List.Content floated='right'>
                                    <Label>
                                        {templatesqa}
                                    </Label>
                                </List.Content>
                                <List.Icon
                                    name='database'
                                    size='big'
                                />
                                <List.Content>
                                    <List.Header
                                        as='a'
                                        href='/templates/qa'
                                    >
                                        {t('templates.q&a.title')}
                                    </List.Header>
                                </List.Content>
                            </List.Item>
                        }
                        {acl(this.props.auth,[['templates:fields']])&&
                            <List.Item>
                                <List.Content floated='right'>
                                    <Label>
                                        {templatesfields}
                                    </Label>
                                </List.Content>
                                <List.Icon
                                    name='database'
                                    size='big'
                                />
                                <List.Content>
                                    <List.Header
                                        as='a'
                                        href='/templates/fields'
                                    >
                                        {t('templates.fields.title')}
                                    </List.Header>
                                </List.Content>
                            </List.Item>
                        }
                    </Fragment>
                }

                <Header as='h3'>{t('menubar.documentation')}</Header>
                <List.Item>
                    <List.Icon
                        name='file pdf'
                        size='big'
                    />
                    <List.Content>
                        <List.Header
                            as='a'
                            href={URL_MANUAL}
                        >
                            {t('menubar.manual')}
                        </List.Header>
                    </List.Content>
                </List.Item>

                <Header as='h3'>{t('menubar.settings')}</Header>
                <List.Item>
                    <List.Icon
                        name='cog'
                        size='big'
                    />
                    <List.Content>
                        <List.Header
                            as='a'
                            href='/settings'
                        >
                            {t('settings.account')}
                        </List.Header>
                    </List.Content>
                </List.Item>
            </List>
        );
    }

    render(){
        const {
                profile,
                timezone,
                enterprise,
                quota,
                documents,
                bookmarks,
                mails,
                webcaptures,
                certificates,
                workflows
            }=this.state,
            {t}=this.props;

        if(!this.props.auth.logged){
            return (
                <Redirect to='/signin' />
            );
        }

        const certs=[
                certificates
                .filter(i=>i.type==='active')
                .length,
                certificates
                .filter(i=>i.type==='archived')
                .length,
                certificates
                .filter(i=>i.type==='active')
                .filter((i)=>{
                    const diff=moment(i.notafter)
                    .diff(moment(),'days');

                    return diff>14;
                })
                .length,
                certificates
                .filter(i=>i.type==='active')
                .filter((i)=>{
                    const diff=moment(i.notafter)
                    .diff(moment(),'days');

                    return 14>=diff&&diff>0;
                })
                .length,
                certificates
                .filter(i=>i.type==='active')
                .filter((i)=>{
                    const diff=moment(i.notafter)
                    .diff(moment());

                    return diff>=0;
                })
                .length
            ],
            flows=[
                workflows
                .filter(i=>i.type==='active')
                .length,
                workflows
                .filter(i=>i.type==='archived')
                .length,
                workflows
                .filter(i=>i.type==='active')
                .filter(i=>i.stage==='draft')
                .length,
                workflows
                .filter(i=>i.type==='active')
                .filter((i)=>{
                    if(i.deadline){
                        const diff=moment(i.deadline)
                        .diff(moment());

                        return i.stage==='published'&&
                            diff>=0;
                    }else{
                        return i.stage==='published';
                    }
                })
                .length,
                workflows
                .filter(i=>i.type==='active')
                .filter((i)=>{
                    if(i.deadline){
                        const diff=moment(i.deadline)
                        .diff(moment());

                        return (i.stage==='published'&&
                            diff<0)||
                            (i.state==='expired');
                    }else{
                        return i.stage==='expired';
                    }
                })
                .length,
                workflows
                .filter(i=>i.type==='active')
                .filter(i=>i.stage==='completed')
                .length,
                workflows
                .filter(i=>i.type==='active')
                .filter(i=>i.stage==='finished')
                .length
            ];

        return (
            <Container>
                <Grid columns={2}>
                    <Grid.Column width={6}>
                        {this.renderMenu()}
                    </Grid.Column>
                    <Grid.Column width={10}>
                        <Grid.Row>
                            <Grid>
                                <Grid.Column width={4}>
                                    <Reveal animated='move right'>
                                        <Reveal.Content visible>
                                            <Image
                                                src={'/photos/'+profile.photo}
                                                size='small'
                                            />
                                        </Reveal.Content>
                                        <Reveal.Content hidden>
                                            <Image
                                                src={enterprise?
                                                    '/photos/'+enterprise.photo:
                                                    '/photos/0.jpg'
                                                }
                                                size='small'
                                            />
                                        </Reveal.Content>
                                    </Reveal>
                                </Grid.Column>
                                <Grid.Column width={12}>
                                    <Header as='h2'>
                                        {profile.surname}, {profile.name}
                                        <Header.Subheader>
                                            {profile.email}
                                        </Header.Subheader>
                                    </Header>
                                    <dl className='profile'>
                                        <dt>
                                            {t('settings.timezone')}:
                                        </dt>
                                        <dd>{timezone}</dd>
                                        {enterprise&&
                                            <Fragment>
                                                <dt>
                                                    {t('settings.enterprise')}:
                                                </dt>
                                                <dd>
                                                    {enterprise.name}
                                                    &nbsp;
                                                    &nbsp;
                                                    <Flag
                                                        name={
                                                            enterprise.country
                                                        }
                                                    />
                                                </dd>
                                            </Fragment>
                                        }
                                    </dl>
                                </Grid.Column>
                            </Grid>
                        </Grid.Row>
                        {acl(this.props.auth,[['document:list']])&&
                            <Fragment>
                                <Divider />
                                <Grid.Row>
                                    <Header
                                        as='h3'
                                        textAlign='center'
                                    >
                                        {t('menubar.documents')}
                                    </Header>
                                    <Statistic.Group
                                        widths={
                                            acl(
                                                this.props.auth,
                                                [['mail:list']]
                                            )?
                                                acl(
                                                    this.props.auth,
                                                    [['webcapture:list']]
                                                )?
                                                    5:4
                                                :
                                                acl(
                                                    this.props.auth,
                                                    [['webcapture:list']]
                                                )?
                                                    4:3
                                        }
                                    >
                                        <Statistic size='tiny' color='red'>
                                            <Statistic.Value>
                                                {Math.round(
                                                    documents/1024*10
                                                )/10} MiB
                                            </Statistic.Value>
                                            <Statistic.Label>
                                                {t('documents.stats.inuse')}
                                            </Statistic.Label>
                                        </Statistic>
                                        <Statistic size='tiny' color='green'>
                                            <Statistic.Value>
                                                {quota} MiB
                                            </Statistic.Value>
                                            <Statistic.Label>
                                                {t('documents.stats.available')}
                                            </Statistic.Label>
                                        </Statistic>
                                        <Statistic size='tiny' color='blue'>
                                            <Statistic.Value>
                                                <Icon name='bookmark' />
                                                &nbsp;
                                                &nbsp;
                                                {bookmarks}
                                            </Statistic.Value>
                                            <Statistic.Label>
                                                {t('documents.stats.bookmarks')}
                                            </Statistic.Label>
                                        </Statistic>
                                        {acl(this.props.auth,[['mail:list']])&&
                                            <Statistic
                                                size='tiny'
                                                color='violet'
                                            >
                                                <Statistic.Value>
                                                    <Icon name='mail' />
                                                    &nbsp;
                                                    &nbsp;
                                                    {mails}
                                                </Statistic.Value>
                                                <Statistic.Label>
                                                    {t('documents.stats.email')}
                                                </Statistic.Label>
                                            </Statistic>
                                        }
                                        {acl(
                                            this.props.auth,
                                            [['webcapture:list']]
                                        )&&
                                            <Statistic
                                                size='tiny'
                                                color='purple'
                                            >
                                                <Statistic.Value>
                                                    <Icon name='camera' />
                                                    &nbsp;
                                                    &nbsp;
                                                    {webcaptures}
                                                </Statistic.Value>
                                                <Statistic.Label>
                                                    {t('documents.stats.'+
                                                       'webcapture')}
                                                </Statistic.Label>
                                            </Statistic>
                                        }
                                    </Statistic.Group>
                                </Grid.Row>
                            </Fragment>
                        }
                        {acl(this.props.auth,[['certificate:list']])&&
                            <Fragment>
                                <Divider />
                                <Grid.Row>
                                    <Header
                                        as='h3'
                                        textAlign='center'
                                    >
                                        {t('menubar.certificates')}
                                    </Header>
                                    <VictoryChart
                                        theme={VictoryTheme.material}
                                        width={600}
                                        height={(20*certs.length)+20}
                                        padding={{
                                            top:20,
                                            bottom:20,
                                            left:150,
                                            right:30
                                        }}
                                        animate={{
                                            duration:2000,
                                            easing:'bounce'
                                        }}
                                    >
                                        <VictoryAxis />
                                        <VictoryBar
                                            horizontal
                                            style={{
                                                data:{
                                                    fill:'#8e7a33',
                                                    fillOpacity:0.7,
                                                    stroke:'#1b1c1d',
                                                    strokeWidth:1
                                                }
                                            }}
                                            barRatio={0.1}
                                            barWidth={20}
                                            data={[{
                                                x:t('certificates.types.'+
                                                    'expired'),
                                                y:certs[4]
                                            },{
                                                x:t('certificates.types.soon'),
                                                y:certs[3]
                                            },{
                                                x:t('certificates.types.'+
                                                    'valid'),
                                                y:certs[2]
                                            },{
                                                x:t('certificates.types.'+
                                                    'archived'),
                                                y:certs[1]
                                            },{
                                                x:t('certificates.types.'+
                                                    'active'),
                                                y:certs[0]
                                            }]}
                                            labels={
                                                ({datum})=>
                                                    datum.y.toPrecision(1)
                                            }
                                        />
                                    </VictoryChart>
                                </Grid.Row>
                            </Fragment>
                        }
                        {acl(this.props.auth,[['workflow:list']])&&
                            <Fragment>
                                <Divider />
                                <Grid.Row>
                                    <Header
                                        as='h3'
                                        textAlign='center'
                                    >
                                        {t('menubar.workflows')}
                                    </Header>
                                    <VictoryChart
                                        theme={VictoryTheme.material}
                                        width={600}
                                        height={(20*flows.length)+20}
                                        padding={{
                                            top:20,
                                            bottom:20,
                                            left:150,
                                            right:30
                                        }}
                                        animate={{
                                            duration:2000,
                                            easing:'bounce'
                                        }}
                                    >
                                        <VictoryAxis />
                                        <VictoryBar
                                            horizontal
                                            style={{
                                                data:{
                                                    fill:'#8e7a33',
                                                    fillOpacity:0.7,
                                                    stroke:'#1b1c1d',
                                                    strokeWidth:1
                                                }
                                            }}
                                            barRatio={0.1}
                                            barWidth={20}
                                            data={[{
                                                x:t('workflows.types.finished'),
                                                y:flows[6]
                                            },{
                                                x:t('workflows.types.'+
                                                    'completed'),
                                                y:flows[5]
                                            },{
                                                x:t('workflows.types.expired'),
                                                y:flows[4]
                                            },{
                                                x:t('workflows.types.'+
                                                    'published'),
                                                y:flows[3]
                                            },{
                                                x:t('workflows.types.drafts'),
                                                y:flows[2]
                                            },{
                                                x:t('workflows.types.archived'),
                                                y:flows[1]
                                            },{
                                                x:t('workflows.types.active'),
                                                y:flows[0]
                                            }]}
                                            labels={
                                                ({datum})=>
                                                    datum.y.toPrecision(1)
                                            }
                                        />
                                    </VictoryChart>
                                </Grid.Row>
                            </Fragment>
                        }
                        <Divider />
                    </Grid.Column>
                </Grid>
            </Container>
        );
    }
}

const mapStateToProps=(state)=>{
    return {
        auth:state.auth,
        data:state.data
    };
};

export default withTranslation('global')(connect(mapStateToProps)(Home));

