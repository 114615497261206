import {createMedia} from '@artsy/fresnel';
import React,{Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch,useSelector} from 'react-redux';
import {withRouter} from 'react-router';
import {
    Icon,
    Menu
} from 'semantic-ui-react';

import {
    RESPONSIVE_TRIGGER
} from '../config';
import {
    AUTH_OUT,
    OFFCANVAS_HIDDEN,
    OFFCANVAS_VISIBLE
} from '../constants';
import {
    apiFetch,
    message,
    acl
} from '../utils';

const {
        MediaContextProvider,
        Media
    }=createMedia({
        breakpoints:{
            sm:0,
            md:RESPONSIVE_TRIGGER
        }
    }),
    hide=()=>{
        return {
            type:OFFCANVAS_HIDDEN
        };
    },
    show=()=>{
        return {
            type:OFFCANVAS_VISIBLE
        };
    },
    logout=()=>{
        return {
            type:AUTH_OUT
        };
    };

export default withRouter((props)=>{
    const offcanvas=useSelector(state=>state.offcanvas),
        auth=useSelector(state=>state.auth),
        dispatch=useDispatch(),
        [t]=useTranslation('global'),
        handleClick=(event)=>{
            event.preventDefault();

            apiFetch({
                url:'/api/_session',
                method:'DELETE',
                data:{},
                success:(data)=>{
                    dispatch(logout());
                    props.history.push('/signin');

                    message(data.ok,data.message,t);
                },
                error:(error)=>{
                    message(false,error.body.message,t);
                },
                t:t
            });
        };

    return (
        <MediaContextProvider>
            <Menu
                id='menubar'
                inverted
            >
                <Media at='sm'>
                    <Menu.Item
                        id='bars'
                        as='a'
                        onClick={()=>dispatch(offcanvas?hide():show())}
                    >
                        <Icon name='bars' />
                    </Menu.Item>
                </Media>
                <Media greaterThanOrEqual='md'>
                    <Menu.Item
                        as='a'
                        href='/'
                    >
                        {t('menubar.home')}
                    </Menu.Item>
                </Media>

                {auth.logged&&
                    <Fragment>
                        {acl(auth,[['certificate:list']])&&
                            <Media greaterThanOrEqual='md'>
                                <Menu.Item
                                    as='a'
                                    href='/certificates'
                                >
                                    {t('menubar.certificates')}
                                </Menu.Item>
                            </Media>
                        }
                        {acl(auth,[['document:list']])&&
                            <Media greaterThanOrEqual='md'>
                                <Menu.Item
                                    as='a'
                                    href='/documents'
                                >
                                    {t('menubar.documents')}
                                </Menu.Item>
                            </Media>
                        }
                        {acl(auth,[['workflow:list']])&&
                            <Media greaterThanOrEqual='md'>
                                <Menu.Item
                                    as='a'
                                    href='/workflows'
                                >
                                    {t('menubar.workflows')}
                                </Menu.Item>
                            </Media>
                        }
                        <Media greaterThanOrEqual='md'>
                            <Menu.Item
                                as='a'
                                href='/settings'
                            >
                                {t('menubar.settings')}
                            </Menu.Item>
                        </Media>
                    </Fragment>
                }

                {auth.logged?
                    <Menu.Item
                        as='a'
                        className='right item'
                        onClick={handleClick}
                    >
                        {t('menubar.logout')}
                    </Menu.Item>:
                    <Menu.Item
                        as='a'
                        href='/signin'
                        className='right item'
                    >
                        {t('menubar.login')}
                    </Menu.Item>
                }
            </Menu>
        </MediaContextProvider>
    );
});

