import React,{Fragment} from 'react';
import Files from 'react-butterfiles';
import {
    Image,
    Message
} from 'semantic-ui-react';

export default ({
    image,
    errors,
    onSuccess,
    setState,
    t=((s)=>s)
})=>(
    <Files
        multiple={false}
        maxSize='10mb'
        multipleMaxSize='10mb'
        accept={['image/png','image/jpg','image/jpeg']}
        onSuccess={onSuccess}
        onError={errors=>setState({errors})}
    >
        {({
            browseFiles,
            getDropZoneProps
        })=>(
            <Fragment>
                <div
                    onClick={browseFiles}
                    aria-hidden='true'
                    {...getDropZoneProps({
                        style:{
                            width:250,
                            minHeight:250,
                            border:'1px dashed black',
                            borderRadius:'3px',
                            margin:'0 auto',
                            padding:'4px'
                        }
                    })}
                >
                    <Image
                        fluid
                        rounded
                        src={image}
                    />
                </div>
                {errors.map(error=>(
                    <Message
                        compact
                        key={errors.file.name}
                        color='red'
                    >
                        {{
                            'unsupportedFileType':
                                t('messages.image.unsupportedFileType'),
                            'maxSizeExceeded':
                                t('messages.image.maxSizeExceeded'),
                        }[error.type]}
                    </Message>
                ))}
            </Fragment>
        )}
    </Files>
);

