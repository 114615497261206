import Cookies from 'js-cookie';
import moment from 'moment-timezone';
import React,{Component,Fragment} from 'react';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {
    Button,
    Container,
    Divider,
    Dropdown,
    Header,
    Icon,
    Input,
    Form,
    Label,
    Segment
} from 'semantic-ui-react';

import {
    TIMEZONE_DEFAULT,
    LANGUAGE_DEFAULT
} from '../config';
import {
    Photo
} from '../components';
import {
    AUTH_IN,
    UPDATE
} from '../constants';
import {
    apiFetch,
    message
} from '../utils';

class Settings extends Component{
    constructor(props){
        super(props);

        const {t}=this.props;

        this.state={
            data:{
                name:'',
                surname:'',
                id:'',
                email:'',
                password:'',
                password1:'',
                password2:'',
                timezone:TIMEZONE_DEFAULT,
                language:LANGUAGE_DEFAULT,
                bookmarks:[],
                quota:0
            },
            invalid_password:false,
            errors:[]
        };

        this.handlePhoto=this.handlePhoto.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);

        let names=moment.tz.names();

        this.timezones=[];
        this.languages=[{
            key:'es',
            value:'es',
            flag: 'pa',
            text:t('utils.language.spanish')
        },{
            key:'en',
            value:'en',
            flag: 'us',
            text:t('utils.language.english')
        }];

        for(let i in names){
            this.timezones.push({
                key:names[i],
                value:names[i],
                text:'(GMT'+moment.tz(names[i]).format('Z')+') '+names[i]
            });
        }
    }

    componentDidMount(){
        const {t}=this.props;

        apiFetch({
            url:'/api/settings',
            method:'GET',
            success:(data)=>{
                apiFetch({
                    url:'/api/settings/data',
                    method:'GET',
                    success:(_data)=>{
                        let __data=JSON.parse(_data.data);

                        this.setState({
                            data:{
                                ...data,
                                ...__data
                            }
                        });

                        /*this.props.data(
                            this.state.timezone,
                            this.state.language,
                            this.state.bookmarks,
                            this.state.quota
                        );*/
                    },
                    error:(error)=>{
                        this.setState({
                            data:data
                        });
                    },
                    t:t
                });
            },
            error:(error)=>{
                message(false,error.body.message,t);
            },
            t:t
        });
    }

    handlePhoto(files){
        let formData=new FormData(),
            file=files[0].src.file,
            status,
            {t}=this.props;

        formData.append(
            'photo',
            file,
            file.name
        );

        fetch('/api/settings/photo',{
            method:'POST',
            credentials:'same-origin',
            headers:{
                'Accept':'application/json',
                'CSRF-Token':Cookies.get('firmanza.user.csrf')
            },
            body:formData
        })
        .then((response)=>{
            status=response.status;

            return response.json();
        })
        .then((body)=>{
            if(status===200||status===201){
                message(true,body.message,t);

                this.componentDidMount();
            }else{
                message(false,body.message,t);
            }
        })
        .catch((e)=>{
            console.log(e);

            message(false,'api.error',t);
        });
    }

    handleChange(event,_data){
        const {
            data
        }=this.state;

        this.setState({
            data:{
                ...data,
                [_data.name]:_data.value
            }
        });

        if(_data.name==='password1'){
            this.setState({
                invalid_password:(data.password2!==_data.value)
            });
        }

        if(_data.name==='password2'){
            this.setState({
                invalid_password:(data.password1!==_data.value)
            });
        }
    }

    handleSubmit(event,_data){
        const {
                data
            }=this.state,
            {t}=this.props;

        event.preventDefault();

        switch(_data['data-name']){
            case 'password':
                apiFetch({
                    url:'/api/settings/password',
                    method:'POST',
                    data:{
                        password1:data.password,
                        password2:data.password1
                    },
                    success:(__data)=>{
                        this.props.history.push('/');

                        message(__data.ok,__data.message,t);
                    },
                    error:(error)=>{
                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            case 'settings':
                apiFetch({
                    url:'/api/settings/data',
                    method:'POST',
                    data:{
                        timezone:data.timezone,
                        language:data.language,
                        bookmarks:data.bookmarks,
                        quota:data.quota
                    },
                    success:(__data)=>{
                        /*this.props.data(
                            this.state.timezone,
                            this.state.language,
                            this.state.bookmarks,
                            this.state.quota
                        );*/
                        this.props.history.push('/');

                        message(__data.ok,__data.message,t);
                    },
                    error:(error)=>{
                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            default:
                break;
        }
    }

    render(){
        const {
                data,
                invalid_password,
                errors
            }=this.state,
            {t}=this.props;

        if(!this.props.auth.logged){
            return (
                <Redirect to='/' />
            );
        }

        return (
            <Container>
                <Header as='h1'>{t('settings.account')}</Header>

                <div className='ui breadcrumb'>
                    <a href='/' className='section'>Inicio</a>
                    <i aria-hidden='true'
                        className='right angle icon divider'></i>
                    <div className='active section'>
                        {t('settings.account')}
                    </div>
                </div>
                <br />

                <Fragment>
                    <Header as='h4' dividing>
                        {t('settings.photo')}
                    </Header>
                    <Segment
                        basic
                        placeholder
                    >
                        <Photo
                            image={data.photo}
                            errors={errors}
                            onSuccess={this.handlePhoto}
                            setState={this.setState}
                            t={t}
                        />
                    </Segment>
                </Fragment>

                <Header as='h4' dividing>
                    {t('settings.personal.title')}
                </Header>

                <Form>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label htmlFor='name'>
                                {t('settings.personal.name')}
                            </label>
                            <Segment
                                name='name'
                                size='small'
                            >
                                {data.name}
                            </Segment>
                        </Form.Field>
                        <Form.Field>
                            <label htmlFor='surname'>
                                {t('settings.personal.surname')}
                            </label>
                            <Segment
                                name='surname'
                                size='small'
                            >
                                {data.surname}
                            </Segment>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label htmlFor='id'>
                                {t('settings.personal.id')}
                            </label>
                            <Segment
                                name='id'
                                size='small'
                            >
                                {data.id}
                            </Segment>
                        </Form.Field>
                        <Form.Field>
                        </Form.Field>
                    </Form.Group>
                </Form>

                <Header as='h4' dividing>
                    {t('settings.contact.title')}
                </Header>

                <Form>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label htmlFor='email'>
                                {t('settings.contact.email')}
                            </label>
                            <Segment
                                name='email'
                                size='small'
                            >
                                {data.email}
                            </Segment>
                        </Form.Field>
                        <Form.Field>
                            <label htmlFor='quota'>
                                {t('settings.contact.space')}
                            </label>
                            <Segment
                                name='quota'
                                size='small'
                            >
                                {data.quota} MiB
                            </Segment>
                        </Form.Field>
                    </Form.Group>
                </Form>

                <Header as='h4' dividing>
                    {t('settings.password.title')}
                </Header>

                <Form
                    data-name='password'
                    onSubmit={this.handleSubmit}
                >
                    <Form.Group widths='equal'>
                        <Form.Field required>
                            <label htmlFor='password'>
                                {t('settings.password.current')}
                            </label>
                            <Input
                                name='password'
                                type='password'
                                value={data.password||''}
                                onChange={this.handleChange}
                                icon='key'
                                iconPosition='left'
                                required
                            />
                        </Form.Field>
                        <Form.Field>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field required>
                            <label htmlFor='password1'>
                                {t('settings.password.new')}
                            </label>
                            <Input
                                name='password1'
                                type='password'
                                value={data.password1||''}
                                onChange={this.handleChange}
                                icon='key'
                                iconPosition='left'
                                required
                            />
                            {invalid_password&&
                                <Label basic color='red' pointing>
                                    {t('settings.message.invalid')}
                                </Label>
                            }
                        </Form.Field>
                        <Form.Field required>
                            <label htmlFor='password2'>
                                {t('settings.password.repeat')}
                            </label>
                            <Input
                                name='password2'
                                type='password'
                                value={data.password2||''}
                                onChange={this.handleChange}
                                icon='key'
                                iconPosition='left'
                                required
                            />
                            {invalid_password&&
                                <Label basic color='red' pointing>
                                    {t('settings.message.invalid')}
                                </Label>
                            }
                        </Form.Field>
                    </Form.Group>

                    <Divider />
                    <Button
                        icon
                        color='blue'
                        labelPosition='left'
                        type='submit'
                    >
                        <Icon name='save' />
                        {t('utils.actions.save')}
                    </Button>
                </Form>

                <Header as='h4' dividing>
                    {t('settings.preferences.title')}
                </Header>

                <Form
                    data-name='settings'
                    onSubmit={this.handleSubmit}
                >
                    <Form.Group widths='equal'>
                        <Form.Field required>
                            <label htmlFor='timezone'>
                                {t('settings.timezone')}
                            </label>
                            <Dropdown
                                fluid
                                search
                                selection
                                name='timezone'
                                options={this.timezones}
                                value={data.timezone}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field required>
                            <label htmlFor='language'>
                                {t('settings.language')}
                            </label>
                            <Dropdown
                                fluid
                                search
                                selection
                                name='language'
                                options={this.languages}
                                value={data.language}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                    </Form.Group>

                    <Divider />
                    <Button
                        icon
                        color='blue'
                        labelPosition='left'
                        type='submit'
                    >
                        <Icon name='save' />
                        {t('utils.actions.save')}
                    </Button>
                </Form>
            </Container>
        );
    }
}

const mapStateToProps=(state)=>{
    return {
        auth:state.auth,
        data:state.data
    };
};

const mapDispatchToProps=(dispatch)=>{
    return {
        login:(permissions)=>{
            dispatch({
                type:AUTH_IN,
                permissions:permissions
            });
        },
        data:(timezone,bookmarks,quota)=>{
            dispatch({
                type:UPDATE,
                timezone:timezone,
                bookmarks:bookmarks,
                quota:quota
            });
        }
    };
};

export default withTranslation('global')(
    connect(mapStateToProps,mapDispatchToProps)(Settings)
);

