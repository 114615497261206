import moment from 'moment-timezone';
import React,{Component,Fragment} from 'react';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {
    Button,
    Container,
    Dimmer,
    Divider,
    Dropdown,
    Header,
    Icon,
    Input,
    Form,
    Loader,
    Menu,
    Modal,
    Segment,
    Table,
    TextArea
} from 'semantic-ui-react';

import {
    TIMEZONE_DEFAULT
} from '../config';
import {
    apiFetch,
    booleanValue,
    certificateAttr,
    message,
    acl
} from '../utils';

class CertificateViewer extends Component{
    constructor(props){
        super(props);

        this.state={
            mode:this.props.mode,
            id:this.props.match.params.resource,
            data:{
                name:'',
                email:'',
                crt:'',
                info:{
                    serial:'',
                    issuer:{},
                    subject:{},
                    signatureAlgorithm:'',
                    publicKeyAlgorithm:'',
                    validity:{
                        start:null,
                        end:null,
                    },
                    san:{
                        dns:[],
                        ip:[],
                        email:[]
                    }
                }
            },
            preview:false,
            loading:false,
            modal:false,
            timezone:this.props.data.timezone||TIMEZONE_DEFAULT
        };

        this.handleClose=this.handleClose.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.handleClick=this.handleClick.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
    }

    componentDidMount(){
        const {
                id
            }=this.state,
            {t}=this.props;

        apiFetch({
            url:'/api/settings/data',
            method:'GET',
            success:(data)=>{
                let obj=JSON.parse(data.data);

                this.setState({
                    timezone:obj.timezone
                });

                if(id){
                    apiFetch({
                        url:'/api/certificates/'+id,
                        method:'GET',
                        success:(data)=>{
                            this.setState({
                                data:data,
                                preview:true
                            });
                        },
                        error:(error)=>{
                            message(false,error.body.message,t);
                        },
                        t:t
                    });
                }
            },
            error:(error)=>{
                this.setState({
                    error:error
                });
            },
            t:t
        });
    }

    handleClose(){
        this.setState({
            modal:false
        });
    }

    handleChange(event,_data){
        const {
            data
        }=this.state;

        this.setState({
            data:{
                ...data,
                [_data.name]:_data.value
            }
        });
    }

    handleClick(event,_data){
        const {
                id,
                data
            }=this.state,
            {t}=this.props;

        event.stopPropagation();

        switch(_data['data-name']){
            case 'preview':
                if(
                    document.querySelector('textarea[name="crt"]')
                    .reportValidity()
                ){
                    this.setState({
                        loading:true
                    });

                    apiFetch({
                        url:'/api/certificates/preview',
                        method:'POST',
                        data:{
                            crt:data.crt
                        },
                        success:(__data)=>{
                            this.setState({
                                data:{
                                    ...data,
                                    info:{
                                        ...data.info,
                                        ...__data.info
                                    }
                                },
                                preview:true,
                                loading:false
                            });
                        },
                        error:(error)=>{
                            this.setState({
                                preview:false,
                                loading:false
                            });

                            message(false,error.body.message,t);
                        },
                        t:t
                    });
                }

                break;
            case 'archive':
                this.setState({
                    modal:true
                });

                break;
            case 'modal-archive':
                apiFetch({
                    url:'/api/certificates/'+id+'/archive',
                    method:'POST',
                    success:(__data)=>{
                        this.setState({
                            modal:false
                        });
                        this.props.history.push('/certificates');

                        message(__data.ok,__data.message,t);
                    },
                    error:(error)=>{
                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            default:
                return;
        }
    }

    handleSubmit(event){
        const {
                mode,
                id,
                data
            }=this.state,
            {t}=this.props;

        this.setState({
            loading:true
        });

        event.preventDefault();

        if(mode==='add'){
            apiFetch({
                url:'/api/certificates',
                method:'POST',
                data:{
                    name:data.name,
                    email:data.email,
                    crt:data.crt
                },
                success:(data)=>{
                    this.setState({
                        loading:false
                    });
                    this.props.history.push('/certificates');

                    message(data.ok,data.message,t);
                },
                error:(error)=>{
                    this.setState({
                        loading:false
                    });

                    message(false,error.body.message,t);
                },
                t:t
            });
        }

        if(mode==='edit'){
            apiFetch({
                url:'/api/certificates/'+id,
                method:'PUT',
                data:{
                    name:data.name,
                    email:data.email,
                    crt:data.crt
                },
                success:(data)=>{
                    this.setState({
                        loading:false
                    });
                    this.props.history.push('/certificates/'+id+'/view');

                    message(data.ok,data.message,t);
                },
                error:(error)=>{
                    this.setState({
                        loading:false
                    });

                    message(false,error.body.message,t);
                },
                t:t
            });
        }
    }

    render(){
        const {
                mode,
                id,
                data,
                preview,
                loading,
                modal,
                timezone
            }=this.state,
            {t}=this.props;

        if(['add','edit'].includes(mode)){
            if(
                !this.props.auth.logged||
                !acl(this.props.auth,[['certificate:register']])
            ){
                return (
                    <Redirect to='/' />
                );
            }
        }else if(['view'].includes(mode)){
            if(
                !this.props.auth.logged||
                !acl(this.props.auth,[['certificate:list']])
            ){
                return (
                    <Redirect to='/' />
                );
            }
        }

        return (
            <Container fluid>
                <Header as='h1'>{t('menubar.certificates')}</Header>

                <div className='ui breadcrumb'>
                    <a href='/' className='section'>
                        {t('menubar.home')}
                    </a>
                    <i aria-hidden='true'
                        className='right angle icon divider'></i>
                    <a href='/certificates' className='section'>
                        {t('menubar.certificates')}
                    </a>
                    {mode==='edit'&&
                        <Fragment>
                            <i aria-hidden='true'
                                className='right angle icon divider'></i>
                            <a
                                href={'/certificates/'+id+'/view'}
                                className='section'
                            >
                                {t('certificates.view')}
                            </a>
                        </Fragment>
                    }
                    <i aria-hidden='true'
                        className='right angle icon divider'></i>
                    {
                        {
                            'add':<div className='active section'>
                                {t('certificates.register')}</div>,
                            'edit':<div className='active section'>
                                {t('certificates.edit')}</div>,
                            'view':<div className='active section'>
                                {t('certificates.view')}</div>
                        }[mode]
                    }
                </div>
                <br />

                <Menu tabular>
                    <Menu.Item
                        as='a'
                        href='/certificates'
                        name={t('certificates.active')}
                    />
                    {acl(this.props.auth,[['certificate:archive']])&&
                        <Menu.Item
                            as='a'
                            href='/certificates/archive'
                            name={t('certificates.archived')}
                        />
                    }
                    {
                        {
                            'add':
                                <Menu.Item
                                    as='a'
                                    href='/certificates/add'
                                    name={t('certificates.register')}
                                    active
                                />,
                            'edit':
                                <Menu.Item
                                    as='a'
                                    href={'/certificates/'+id+'/edit'}
                                    name={t('certificates.edit')}
                                    active
                                />,
                            'view':
                                <Menu.Item
                                    as='a'
                                    href={'/certificates/'+id+'/view'}
                                    name={t('certificates.view')}
                                    active
                                />
                        }[mode]
                    }
                </Menu>

                {preview&&
                    <Fragment>
                        <Header as='h4' dividing>
                            {t('certificates.info')}
                        </Header>

                        <Table compact size='small'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell
                                        colSpan='2'
                                        textAlign='center'
                                    >
                                        {t('certificates.certificate.subject')}
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {Object.keys(data.info.subject).map((i,j)=>(
                                    <Table.Row key={'subject-'+j}>
                                        <Table.Cell width={6} textAlign='right'>
                                            <strong>
                                                {certificateAttr(
                                                    data.info.subject[i].name,t
                                                )} ({i})
                                            </strong>
                                        </Table.Cell>
                                        <Table.Cell width={6}>
                                            {data.info.subject[i].value}
                                        </Table.Cell>
                                    </Table.Row>:null
                                ))}
                            </Table.Body>
                        </Table>
                        <Table compact size='small'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell
                                        colSpan='2'
                                        textAlign='center'
                                    >
                                        {t('certificates.certificate.issuer')}
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {Object.keys(data.info.issuer).map((i,j)=>(
                                    <Table.Row key={'issuer-'+j}>
                                        <Table.Cell width={6} textAlign='right'>
                                            <strong>
                                                {certificateAttr(
                                                    data.info.issuer[i].name,t
                                                )} ({i})
                                            </strong>
                                        </Table.Cell>
                                        <Table.Cell width={6}>
                                            {data.info.issuer[i].value}
                                        </Table.Cell>
                                    </Table.Row>:null
                                ))}
                            </Table.Body>
                        </Table>
                        <Table compact size='small'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell
                                        colSpan='2'
                                        textAlign='center'
                                    >
                                        {t('certificates.certificate.validity')}
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell width={6} textAlign='right'>
                                        <strong>
                                            {t('certificates.certificate.'+
                                               'notbefore',t)}
                                        </strong>
                                    </Table.Cell>
                                    <Table.Cell width={6}>
                                        {moment(data.info.validity.start)
                                        .tz(timezone)
                                        .format('YYYY-MM-DD HH:mm:ss')}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell width={6} textAlign='right'>
                                        <strong>
                                            {t('certificates.certificate.'+
                                               'notafter',t)}
                                        </strong>
                                    </Table.Cell>
                                    <Table.Cell width={6}>
                                        {moment(data.info.validity.end)
                                        .tz(timezone)
                                        .format('YYYY-MM-DD HH:mm:ss')}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                        <Table compact size='small'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell
                                        colSpan='2'
                                        textAlign='center'
                                    >
                                        {t('certificates.certificate.'+
                                           'publicKey')}
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell width={6} textAlign='right'>
                                        <strong>
                                            {t('certificates.certificate.'+
                                               'algorithm')}
                                        </strong>
                                    </Table.Cell>
                                    <Table.Cell width={6}>
                                        {data.info.publicKeyAlgorithm}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                        <Table compact size='small'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell
                                        colSpan='2'
                                        textAlign='center'
                                    >
                                        {t('certificates.certificate.'+
                                           'miscellaneous')}
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell width={6} textAlign='right'>
                                        <strong>
                                            {t('certificates.certificate.'+
                                               'serial')}
                                        </strong>
                                    </Table.Cell>
                                    <Table.Cell width={6}>
                                        {data.info.serial}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell width={6} textAlign='right'>
                                        <strong>
                                            {t('certificates.certificate.'+
                                               'signatureAlgorithm')}
                                        </strong>
                                    </Table.Cell>
                                    <Table.Cell width={6}>
                                        {data.info.signatureAlgorithm}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                        {[].concat(
                            data.info.san.dns,
                            data.info.san.ip,
                            data.info.san.email
                        ).length!==0?
                            <Table compact size='small'>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell
                                            colSpan='2'
                                            textAlign='center'
                                        >
                                            {t('certificates.certificate.'+
                                               'alternative')}
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {[].concat(
                                        data.info.san.dns.map(i=>['dns',i]),
                                        data.info.san.ip.map(j=>['ip',j]),
                                        data.info.san.email.map(k=>['email',k])
                                    ).map((alternative,i)=>{
                                        return (
                                            <Table.Row key={'san-'+i}>
                                                <Table.Cell
                                                    width={6}
                                                    textAlign='right'
                                                >
                                                    <strong>
                                                        {certificateAttr(
                                                            alternative[0],t
                                                        )}
                                                    </strong>
                                                </Table.Cell>
                                                <Table.Cell width={6}>
                                                    {alternative[1]}
                                                </Table.Cell>
                                            </Table.Row>
                                        );
                                    })}
                                </Table.Body>
                            </Table>:null
                        }
                        <Table compact size='small'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell
                                        colSpan='2'
                                        textAlign='center'
                                    >
                                        {t('certificates.certificate.keyUsage')}
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {Object.keys(data.info.keyUsage).map((i,j)=>(
                                    <Table.Row key={'keyusage-'+j}>
                                        <Table.Cell width={6} textAlign='right'>
                                            <strong>
                                                {i}
                                            </strong>
                                        </Table.Cell>
                                        <Table.Cell width={6}>
                                            {booleanValue(
                                                data.info.keyUsage[i],t
                                            )}
                                        </Table.Cell>
                                    </Table.Row>:null
                                ))}
                            </Table.Body>
                        </Table>
                        <Table compact size='small'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell
                                        colSpan='2'
                                        textAlign='center'
                                    >
                                        <strong>
                                            {t('certificates.certificate.'+
                                               'extKeyUsage')}
                                        </strong>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {Object.keys(data.info.extKeyUsage).map((i,j)=>(
                                    <Table.Row key={'extkeyusage-'+j}>
                                        <Table.Cell width={6} textAlign='right'>
                                            <strong>
                                                {certificateAttr(i,t)}
                                            </strong>
                                        </Table.Cell>
                                        <Table.Cell width={6}>
                                            {booleanValue(
                                                data.info.extKeyUsage[i],t
                                            )}
                                        </Table.Cell>
                                    </Table.Row>:null
                                ))}
                            </Table.Body>
                        </Table>
                    </Fragment>
                }

                <Divider />

                <Dimmer.Dimmable dimmed={loading}>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group widths='equal'>
                            <Form.Field required={mode!=='view'}>
                                <label htmlFor='name'>
                                    {t('certificates.certificate.commonName')}
                                </label>
                                {mode==='view'?
                                    <Segment
                                        size='small'
                                    >
                                        {data.name}
                                    </Segment>:
                                    <Input
                                        name='name'
                                        type='text'
                                        value={data.name}
                                        onChange={this.handleChange}
                                        required
                                    />
                                }
                            </Form.Field>
                            <Form.Field required={mode!=='view'}>
                                <label htmlFor='email'>
                                    {t('certificates.certificate.email')}
                                </label>
                                {mode==='view'?
                                    <Segment
                                        size='small'
                                    >
                                        {data.name}
                                    </Segment>:
                                    <Input
                                        name='email'
                                        type='text'
                                        value={data.email}
                                        onChange={this.handleChange}
                                        required
                                    />
                                }
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label htmlFor='text'>
                                    CRT
                                </label>
                                {mode==='view'?
                                    <Segment
                                        style={{
                                            display:'flex',
                                            justifyContent:'center'
                                        }}
                                        size='small'
                                    >
                                        <pre style={{
                                            textAlign:'left',
                                            width:'500px'
                                        }}>
                                            {data.crt}
                                        </pre>
                                    </Segment>:
                                    <TextArea
                                        name='crt'
                                        value={data.crt}
                                        onChange={this.handleChange}
                                        required
                                    />
                                }
                            </Form.Field>
                        </Form.Group>

                        <Divider />
                        {{
                            'add':
                                <Fragment>
                                    <Button
                                        icon
                                        color='blue'
                                        labelPosition='left'
                                        type='submit'
                                    >
                                        <Icon name='save' />
                                        {t('utils.actions.save')}
                                    </Button>
                                    <Button
                                        icon
                                        labelPosition='left'
                                        data-name='preview'
                                        onClick={this.handleClick}
                                    >
                                        <Icon name='camera' />
                                        {t('utils.actions.preview')}
                                    </Button>
                                </Fragment>,
                            'view':
                                <Fragment>
                                    <Dropdown
                                        text={t('utils.actions.options')}
                                        icon='tasks'
                                        className='icon'
                                        labeled
                                        button
                                    >
                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                icon='save'
                                                content={t('utils.actions.'+
                                                   'edit')}
                                                href={
                                                    '/certificates/'+id+'/edit'
                                                }
                                            />

                                            <Dropdown.Item
                                                icon='box'
                                                content={t('utils.actions.'+
                                                    'archive')}
                                                data-name='archive'
                                                onClick={this.handleClick}
                                            />
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Fragment>,
                            'edit':
                                <Fragment>
                                    <Button
                                        icon
                                        color='blue'
                                        labelPosition='left'
                                        type='submit'
                                    >
                                        <Icon name='save' />
                                        {t('utils.actions.save')}
                                    </Button>
                                    <Button
                                        icon
                                        labelPosition='left'
                                        data-name='preview'
                                        onClick={this.handleClick}
                                    >
                                        <Icon name='camera' />
                                        {t('utils.actions.preview')}
                                    </Button>
                                </Fragment>
                        }[mode]}

                        <Modal
                            basic
                            size='small'
                            open={modal}
                            onClose={this.handleClose}
                        >
                            <Header
                                icon='archive'
                                content={t('certificates.confirm.archive.'+
                                   'title')}
                            />

                            <Modal.Content>
                                <p>
                                    {t('certificates.confirm.archive.content')}
                                </p>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button
                                    basic
                                    inverted
                                    color='red'
                                    onClick={this.handleClose}
                                >
                                    <Icon name='remove' />
                                    {t('utils.bool.no')}
                                </Button>
                                <Button
                                    inverted
                                    color='green'
                                    data-name='modal-archive'
                                    onClick={this.handleClick}
                                >
                                    <Icon name='checkmark' />
                                    {t('utils.bool.yes')}
                                </Button>
                            </Modal.Actions>
                        </Modal>
                    </Form>

                    <Dimmer active={loading}>
                        <Loader
                            active={loading}
                            indeterminate>
                            {t('api.sending')}
                        </Loader>
                    </Dimmer>
                </Dimmer.Dimmable>
            </Container>
        );
    }
}

const mapStateToProps=(state)=>{
    return {
        auth:state.auth,
        data:state.data
    };
};

export default withTranslation('global')(
    connect(mapStateToProps)(CertificateViewer)
);

