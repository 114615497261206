import {combineReducers} from 'redux';

import auth from './auth';
import data from './data';
import offcanvas from './offcanvas';

export default combineReducers({
    auth:auth,
    data:data,
    offcanvas:offcanvas
});

