import moment from 'moment-timezone';
import React,{Component} from 'react';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {
    Button,
    Container,
    Divider,
    Dropdown,
    Header,
    Icon,
    Menu,
    Modal,
    Table
} from 'semantic-ui-react';

import {
    TIMEZONE_DEFAULT
} from '../config';
import {
    apiFetch,
    message,
    acl
} from '../utils';

class MailViewer extends Component{
    constructor(props){
        super(props);

        this.state={
            id:this.props.match.params.resource,
            data:{
                ts:'',
                subject:'',
                from:[],
                to:[],
                cc:[],
                attachments:0,
                content:''
            },
            modal:false,
            timezone:this.props.data.timezone||TIMEZONE_DEFAULT
        };

        this.handleClose=this.handleClose.bind(this);
        this.handleClick=this.handleClick.bind(this);
    }

    componentDidMount(){
        const {
                id
            }=this.state,
            {t}=this.props;

        apiFetch({
            url:'/api/settings/data',
            method:'GET',
            success:(data)=>{
                let obj=JSON.parse(data.data);

                this.setState({
                    timezone:obj.timezone
                });

                if(id){
                    apiFetch({
                        url:'/api/mails/'+id,
                        method:'GET',
                        success:(data)=>{
                            this.setState({
                                data:data
                            });
                        },
                        error:(error)=>{
                            message(false,error.body.message,t);
                        },
                        t:t
                    });
                }
            },
            error:(error)=>{
                this.setState({
                    error:error
                });
            },
            t:t
        });
    }

    handleClose(){
        this.setState({
            modal:false
        });
    }

    handleClick(event,_data){
        const {
                id
            }=this.state,
            {t}=this.props;

        event.stopPropagation();

        switch(_data['data-name']){
            case 'archive':
                this.setState({
                    modal:true
                });

                break;
            case 'modal-archive':
                apiFetch({
                    url:'/api/mails/'+id+'/archive',
                    method:'POST',
                    success:(__data)=>{
                        this.setState({
                            modal:false
                        });
                        this.props.history.push('/mails');

                        message(__data.ok,__data.message,t);
                    },
                    error:(error)=>{
                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            default:
                break;
        }
    }

    render(){
        const {
                id,
                data,
                modal,
                timezone
            }=this.state,
            {t}=this.props;

        if(
            !this.props.auth.logged||
            !acl(this.props.auth,[['mail:list']])
        ){
            return (
                <Redirect to='/' />
            );
        }

        return (
            <Container fluid>
                <Header as='h1'>{t('menubar.emails')}</Header>

                <div className='ui breadcrumb'>
                    <a href='/' className='section'>
                        {t('menubar.home')}
                    </a>
                    <i aria-hidden='true'
                        className='right angle icon divider'></i>
                    <a href='/mails' className='section'>
                        {t('menubar.emails')}
                    </a>
                    <i aria-hidden='true'
                        className='right angle icon divider'></i>
                    <div className='active section'>
                        {t('emails.view')}
                    </div>
                </div>
                <br />

                <Menu tabular>
                    <Menu.Item
                        as='a'
                        href='/mails'
                        name={t('emails.active')}
                    />
                    {acl(this.props.auth,[['mail:archive']])&&
                        <Menu.Item
                            as='a'
                            href='/mails/archive'
                            name={t('emails.archived')}
                        />
                    }
                    <Menu.Item
                        as='a'
                        href={'/mails/'+id+'/view'}
                        name={t('emails.view')}
                        active
                    />
                </Menu>

                <Table compact size='small'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                colSpan='2'
                                textAlign='center'
                            >
                                <strong>{t('utils.headers.details')}</strong>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell width={6} textAlign='right'>
                                <strong>{t('utils.headers.date')}</strong>
                            </Table.Cell>
                            <Table.Cell width={6}>
                                {moment(data.ts)
                                .tz(timezone)
                                .format('YYYY-MM-DD HH:mm:ss')}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell width={6} textAlign='right'>
                                <strong>{t('utils.headers.subject')}</strong>
                            </Table.Cell>
                            <Table.Cell width={6}>
                                {data.subject}
                            </Table.Cell>
                        </Table.Row>
                        {data.from.map((item,i)=>(
                            <Table.Row key={'from-'+i}>
                                <Table.Cell width={6} textAlign='right'>
                                    <strong>{
                                        i===0?t('utils.headers.from'):''
                                    }</strong>
                                </Table.Cell>
                                <Table.Cell width={6}>
                                    {data.from[i]}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                        {data.to.map((item,i)=>(
                            <Table.Row key={'to-'+i}>
                                <Table.Cell width={6} textAlign='right'>
                                    <strong>{
                                        i===0?t('utils.headers.to'):''
                                    }</strong>
                                </Table.Cell>
                                <Table.Cell width={6}>
                                    {data.to[i]}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                        {data.cc.map((item,i)=>(
                            <Table.Row key={'to-'+i}>
                                <Table.Cell width={6} textAlign='right'>
                                    <strong>{
                                        i===0?t('utils.headers.cc'):''
                                    }</strong>
                                </Table.Cell>
                                <Table.Cell width={6}>
                                    {data.cc[i]}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                        <Table.Row>
                            <Table.Cell width={6} textAlign='right'>
                                <strong>
                                    {t('utils.headers.attachments')}
                                </strong>
                            </Table.Cell>
                            <Table.Cell width={6}>
                                {data.attachments||t('utils.none')}
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                colSpan='2'
                                textAlign='center'
                            >
                                <strong>{t('utils.headers.message')}</strong>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Row>
                        <Table.Cell
                            width={12}
                            colSpan={2}
                        >
                            <pre style={{
                                textAlign:'left',
                            }}>
                                {data.content}
                            </pre>
                        </Table.Cell>
                    </Table.Row>
                </Table>

                <Divider />
                <Button
                    icon
                    color='green'
                    labelPosition='right'
                    floated='right'
                    type='button'
                    title={t('emails.pdf.download')}
                    target='_BLANK'
                    href={'/api/mails/'+id+'/pdf'}
                    download={
                        t('emails.pdf.file')+' ('+
                        moment(data.ts)
                        .tz(timezone)
                        .format('YYYY-MM-DD HH:mm:ss')+
                        ')'
                    }
                >
                    <Icon name='download' />
                    {t('emails.pdf.download')}
                </Button>
                <Dropdown
                    text={t('utils.actions.options')}
                    icon='tasks'
                    className='icon'
                    labeled
                    button
                >
                    <Dropdown.Menu>
                        <Dropdown.Item
                            icon='box'
                            content={t('utils.actions.archive')}
                            data-name='archive'
                            onClick={this.handleClick}
                        />
                    </Dropdown.Menu>
                </Dropdown>

                <Modal
                    basic
                    size='small'
                    open={modal}
                    onClose={this.handleClose}
                >
                    <Header
                        icon='archive'
                        content={t('emails.confirm.archive.title')}
                    />

                    <Modal.Content>
                        <p>
                            {t('emails.confirm.archive.content')}
                        </p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            basic
                            inverted
                            color='red'
                            onClick={this.handleClose}
                        >
                            <Icon name='remove' />
                            {t('utils.bool.no')}
                        </Button>
                        <Button
                            inverted
                            color='green'
                            data-name='modal-archive'
                            onClick={this.handleClick}
                        >
                            <Icon name='checkmark' />
                            {t('utils.bool.yes')}
                        </Button>
                    </Modal.Actions>
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps=(state)=>{
    return {
        auth:state.auth,
        data:state.data
    };
};

export default withTranslation('global')(connect(mapStateToProps)(MailViewer));

