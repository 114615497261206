import moment from 'moment-timezone';
import React,{Component,Fragment} from 'react';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {
    Button,
    Container,
    Form,
    Grid,
    Header,
    Icon,
    Input,
    Menu,
    Message,
    Modal,
    Table
} from 'semantic-ui-react';

import {
    TIMEZONE_DEFAULT
} from '../config';
import {
    apiFetch,
    message,
    acl
} from '../utils';

class TemplateQA extends Component{
    constructor(props){
        super(props);

        this.state={
            error:null,
            isLoaded:false,
            total:0,
            items:[],
            shareable:false,
            search:'',
            page:1,
            name:'',
            questions:[],
            required:0,
            skippable:0,
            question:'',
            answer:'',
            modal:false,
            modal_type:'',
            modal_index:-1,
            edit:false,
            edit_index:-1,
            timezone:this.props.data.timezone||TIMEZONE_DEFAULT
        };

        this.handleSearch=this.handleSearch.bind(this);
        this.handlePagination=this.handlePagination.bind(this);
        this.handleClose=this.handleClose.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.handleClick=this.handleClick.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
    }

    loadData(search,page){
        const {t}=this.props;

        apiFetch({
            url:'/api/templates/qa',
            method:'GET',
            success:(data)=>{
                this.setState({
                    isLoaded:true,
                    total:data.total,
                    items:data.list,
                    shareable:data.shareable,
                    search:search,
                    page:page
                });
            },
            error:(error)=>{
                this.setState({
                    isLoaded:true,
                    error:error
                });
            },
            t:t
        });
    }

    componentDidMount(){
        const {t}=this.props;

        apiFetch({
            url:'/api/settings/data',
            method:'GET',
            success:(data)=>{
                let obj=JSON.parse(data.data);

                this.setState({
                    timezone:obj.timezone
                });

                this.loadData('',1);
            },
            error:(error)=>{
                this.setState({
                    error:error
                });
            },
            t:t
        });
    }

    handleSearch(event,data){
        this.loadData(data.value,1);
    }

    handlePagination(event,data){
        const {
            search
        }=this.state;

        this.loadData(search,data.activePage);
    }

    handleClose(){
        this.setState({
            modal:false,
            modal_type:'',
            edit:false
        });

        this.loadData('',1);
    }

    handleChange(event,data){
        this.setState({
            [data['data-name']]:data.value
        });
    }

    handleClick(event,data){
        const {
                items,
                name,
                questions,
                required,
                skippable,
                question,
                answer,
                modal_index,
                edit_index
            }=this.state,
            {t}=this.props;

        event.stopPropagation();

        let index=+data['data-index'];

        switch(data['data-name']){
            case 'new':
                this.setState({
                    name:'',
                    questions:[],
                    required:0,
                    skippable:0,
                    question:'',
                    answer:'',
                    modal:true,
                    modal_type:'new'
                });

                break;
            case 'unshare':
                apiFetch({
                    url:'/api/templates/qa/'+items[index]._id+'/unshare',
                    method:'POST',
                    success:(data)=>{
                        this.loadData('',1);

                        message(data.ok,data.message,t);
                    },
                    error:(error)=>{
                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            case 'share':
                apiFetch({
                    url:'/api/templates/qa/'+items[index]._id+'/share',
                    method:'POST',
                    success:(data)=>{
                        this.loadData('',1);

                        message(data.ok,data.message,t);
                    },
                    error:(error)=>{
                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            case 'view':
                this.setState({
                    name:items[index].name,
                    questions:items[index].questions,
                    required:items[index].required,
                    skippable:items[index].skippable,
                    modal:true,
                    modal_type:'view'
                });

                break;
            case 'edit':
                this.setState({
                    name:items[index].name,
                    questions:items[index].questions,
                    required:items[index].required,
                    skippable:items[index].skippable,
                    modal:true,
                    modal_type:'edit',
                    modal_index:index
                });

                break;
            case 'remove':
                this.setState({
                    modal:true,
                    modal_type:'remove',
                    modal_index:index
                });

                break;
            case 'question-add':
                this.setState({
                    questions:questions.concat([{
                        question:question,
                        answer:answer
                    }]),
                    question:'',
                    answer:''
                });

                break;
            case 'question-edit':
                this.setState({
                    question:questions[index].question,
                    answer:questions[index].answer,
                    edit:true,
                    edit_index:index
                });

                break;
            case 'question-save':{
                let _questions=questions;

                _questions[edit_index].question=question;
                _questions[edit_index].answer=answer;

                this.setState({
                    questions:_questions,
                    question:'',
                    answer:'',
                    edit:false,
                });

                break;
            }
            case 'question-remove':{
                let _questions=questions;

                _questions.splice(index,1);

                this.setState({
                    questions:_questions
                });

                break;
            }
            case 'save-as':
                apiFetch({
                    url:'/api/templates/qa',
                    method:'POST',
                    data:{
                        name:name,
                        questions:questions,
                        required:+required,
                        skippable:+skippable
                    },
                    success:(data)=>{
                        this.setState({
                            modal:false,
                            modal_type:''
                        });
                        this.loadData('',1);

                        message(data.ok,data.message,t);
                    },
                    error:(error)=>{
                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            case 'modal-remove':
                apiFetch({
                    url:'/api/templates/qa/'+items[modal_index]._id,
                    method:'DELETE',
                    success:(data)=>{
                        this.setState({
                            modal:false,
                            modal_type:''
                        });
                        this.loadData('',1);

                        message(data.ok,data.message,t);
                    },
                    error:(error)=>{
                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            default:
                return;
        }
    }

    handleSubmit(event){
        const {
                items,
                name,
                questions,
                required,
                skippable,
                modal_type,
                modal_index
            }=this.state,
            {t}=this.props;

        event.preventDefault();

        if(modal_type==='new'){
            apiFetch({
                url:'/api/templates/qa',
                method:'POST',
                data:{
                    name:name,
                    questions:questions,
                    required:+required,
                    skippable:+skippable
                },
                success:(data)=>{
                    this.setState({
                        modal:false,
                        modal_type:''
                    });
                    this.loadData('',1);

                    message(data.ok,data.message,t);
                },
                error:(error)=>{
                    message(false,error.body.message,t);
                },
                t:t
            });
        }

        if(modal_type==='edit'){
            apiFetch({
                url:'/api/templates/qa/'+items[modal_index]._id,
                method:'PUT',
                data:{
                    name:name,
                    questions:questions,
                    required:+required,
                    skippable:+skippable
                },
                success:(data)=>{
                    this.setState({
                        modal:false,
                        modal_type:''
                    });
                    this.loadData('',1);

                    message(data.ok,data.message,t);
                },
                error:(error)=>{
                    message(false,error.body.message,t);
                },
                t:t
            });
        }
    }

    renderForm(){
        const {
                name,
                questions,
                required,
                skippable,
                question,
                answer,
                modal_type,
                edit,
                edit_index
            }=this.state,
            {t}=this.props;

        return (
            <Fragment>
                <Form.Field>
                    <label htmlFor='name'>
                        {t('templates.name')}
                    </label>
                    <Input
                        type='text'
                        placeholder={t('templates.q&a.name')}
                        value={name}
                        data-name='name'
                        onChange={this.handleChange}
                        fluid
                        readOnly={modal_type==='view'}
                    />
                </Form.Field>
                <Form.Field>
                    <label htmlFor='questions'>
                        {t('templates.fields.possibleValues')}
                    </label>
                    <Table
                        name='questions'
                        size='small'
                        inverted
                    >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell
                                    textAlign='center'
                                    width={1}
                                >
                                    #
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={7}
                                >
                                    {t('templates.q&a.question')}
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={5}
                                >
                                    {t('templates.q&a.answer')}
                                </Table.HeaderCell>
                                {modal_type!=='view'&&
                                    <Table.HeaderCell
                                        textAlign='right'
                                        width={3}
                                    >
                                        &nbsp;
                                    </Table.HeaderCell>
                                }
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {questions.map((item,i)=>{
                                return (
                                    <Table.Row
                                        key={'question-'+i}
                                    >
                                        <Table.Cell>
                                            {i+1}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {edit&&edit_index===i?
                                                <Input
                                                    type='text'
                                                    placeholder={t([
                                                        'templates',
                                                        'q&a',
                                                        'question'
                                                    ].join('.'))}
                                                    value={question}
                                                    data-name='question'
                                                    onChange={this.handleChange}
                                                    fluid
                                                />:
                                                <Fragment>
                                                    {item.question}
                                                </Fragment>
                                            }
                                        </Table.Cell>
                                        <Table.Cell>
                                            {edit&&edit_index===i?
                                                <Input
                                                    type='text'
                                                    placeholder={t([
                                                        'templates',
                                                        'q&a',
                                                        'answer'
                                                    ].join('.'))}
                                                    value={answer}
                                                    data-name='answer'
                                                    onChange={this.handleChange}
                                                    fluid
                                                />:
                                                <Fragment>
                                                    {item.answer}
                                                </Fragment>
                                            }
                                        </Table.Cell>
                                        {modal_type!=='view'&&
                                            <Table.Cell
                                                textAlign='right'
                                                width={3}
                                            >
                                                {edit?
                                                    <Button
                                                        size='tiny'
                                                        icon='save'
                                                        title={t([
                                                            'utils',
                                                            'actions',
                                                            'save'
                                                        ].join('.'))}
                                                        type='button'
                                                        data-name={
                                                            'question-save'
                                                        }
                                                        data-index={i}
                                                        onClick={
                                                            this.handleClick
                                                        }
                                                        disabled={
                                                            edit_index!==i
                                                        }
                                                    />:
                                                    <Button
                                                        size='tiny'
                                                        icon='edit'
                                                        title={t([
                                                            'utils',
                                                            'actions',
                                                            'edit'
                                                        ].join('.'))}
                                                        type='button'
                                                        data-name={
                                                            'question-edit'
                                                        }
                                                        data-index={i}
                                                        onClick={
                                                            this.handleClick
                                                        }
                                                    />
                                                }
                                                <Button
                                                    size='tiny'
                                                    icon='times'
                                                    title={t([
                                                        'templates',
                                                        'q&a',
                                                        'remove'
                                                    ].join('.'))}
                                                    type='button'
                                                    data-name='question-remove'
                                                    data-index={i}
                                                    onClick={this.handleClick}
                                                    disabled={edit}
                                                    negative
                                                />
                                            </Table.Cell>
                                        }
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                        {modal_type!=='view'&&
                            <Table.Footer>
                                <Table.Row>
                                    <Table.Cell>&nbsp;</Table.Cell>
                                    <Table.Cell>
                                        <Input
                                            type='text'
                                            placeholder={t([
                                                'templates',
                                                'q&a',
                                                'question'
                                            ].join('.'))}
                                            value={edit?'':question}
                                            data-name='question'
                                            onChange={this.handleChange}
                                            readOnly={edit}
                                            fluid
                                        />
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Input
                                            type='text'
                                            placeholder={t([
                                                'templates',
                                                'q&a',
                                                'answer'
                                            ].join('.'))}
                                            value={edit?'':answer}
                                            data-name='answer'
                                            onChange={this.handleChange}
                                            readOnly={edit}
                                            fluid
                                        />
                                    </Table.Cell>
                                    <Table.Cell
                                        textAlign='right'
                                    >
                                        <Button
                                            size='tiny'
                                            icon='plus'
                                            title={t([
                                                'templates',
                                                'q&a',
                                                'add'
                                            ].join('.'))}
                                            type='button'
                                            data-name='question-add'
                                            onClick={this.handleClick}
                                            disabled={edit}
                                            secondary
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Footer>
                        }
                    </Table>
                </Form.Field>
                <Form.Group widths='equal'>
                    <Form.Input
                        label={t('templates.q&a.required')}
                        type='number'
                        name='required'
                        data-name='required'
                        value={required}
                        min={1}
                        max={questions.length}
                        onChange={this.handleChange}
                        required
                        fluid
                        readOnly={modal_type==='view'}
                    />
                    <Form.Input
                        label={t('templates.q&a.skippable')}
                        type='number'
                        name='skippable'
                        data-name='skippable'
                        value={skippable}
                        min={0}
                        max={questions.length-required}
                        onChange={this.handleChange}
                        required
                        fluid
                        readOnly={modal_type==='view'}
                    />
                </Form.Group>
            </Fragment>
        );
    }

    render(){
        const {
                error,
                isLoaded,
                items,
                shareable,
                modal,
                modal_type,
                edit,
                timezone
            }=this.state,
            {t}=this.props;

        if(
            !this.props.auth.logged||
            !acl(this.props.auth,[['templates:q&a']])
        ){
            return (
                <Redirect to='/' />
            );
        }

        return (
            <Container fluid>
                <Header as='h1'>{t('menubar.templates')}</Header>

                <div className='ui breadcrumb'>
                    <a href='/' className='section'>
                        {t('menubar.home')}
                    </a>
                    <i aria-hidden='true'
                        className='right angle icon divider'></i>
                    <div className='active section'>
                        {t('templates.q&a.title')}
                    </div>
                </div>
                <br />

                <Menu tabular>
                    <Menu.Item
                        as='a'
                        href='/templates/qa'
                        name={t('templates.q&a.title')}
                        active
                    />
                    <Menu.Item
                        as='a'
                        href='/templates/fields'
                        name={t('templates.fields.title')}
                    />
                </Menu>

                <Grid>
                    <Grid.Column width={12}>
                        <Button
                            basic
                            color='black'
                            data-name='new'
                            onClick={this.handleClick}
                        >
                            <Icon name='plus' />
                            {t('templates.add')}
                        </Button>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Input
                            className='search-input'
                            size='small'
                            icon={{
                                name:'search',
                                circular:true,
                                link:true
                            }}
                            placeholder={t('api.search')}
                            onChange={this.handleSearch}
                        />
                    </Grid.Column>
                </Grid>

                <Table compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                {t('utils.headers.details')}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                textAlign='center'
                                width={3}
                            >
                                {t('utils.headers.date')}
                            </Table.HeaderCell>
                            {shareable&&
                                <Table.HeaderCell>
                                    &nbsp;
                                </Table.HeaderCell>
                            }
                            <Table.HeaderCell width={2}>
                                &nbsp;
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {error&&
                            <Table.Row>
                                <Table.Cell colSpan='4'>
                                    <Message negative>
                                        <Message.Header>
                                            {t('api.emptytable')}
                                        </Message.Header>
                                        <p>
                                            {t(error.body.message)}
                                        </p>
                                    </Message>
                                </Table.Cell>
                            </Table.Row>
                        }
                        {!isLoaded&&
                            <Table.Row>
                                <Table.Cell colSpan='4'>
                                    <Message icon info>
                                        <Icon name='circle notched' loading />
                                        <Message.Content>
                                            <Message.Header>
                                                {t('api.wait')}
                                            </Message.Header>
                                            <p>
                                                {t('api.loading')}
                                            </p>
                                        </Message.Content>
                                    </Message>
                                </Table.Cell>
                            </Table.Row>
                        }
                        {items.map((item,i)=>(
                            <Table.Row
                                key={item._id}
                                active={item.owner}
                            >
                                <Table.Cell>
                                    <Header as='h4' image>
                                        <Header.Content>
                                            {item.name}
                                            <Header.Subheader>
                                                <strong>
                                                    {t([
                                                        'templates',
                                                        'q&a',
                                                        'questions'
                                                    ].join('.'))}:
                                                </strong>
                                                &nbsp;
                                                {item.questions.length}&nbsp;
                                                <strong>
                                                    {t([
                                                        'templates',
                                                        'q&a',
                                                        'required'
                                                    ].join('.'))}:
                                                </strong>
                                                &nbsp;
                                                {item.required}&nbsp;
                                                <strong>
                                                    {t([
                                                        'templates',
                                                        'q&a',
                                                        'skippable'
                                                    ].join('.'))}:
                                                </strong>
                                                &nbsp;
                                                {item.skippable}
                                            </Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell textAlign='center'>
                                    {moment(item.ts)
                                    .tz(timezone)
                                    .format('YYYY-MM-DD HH:mm:ss')}
                                </Table.Cell>
                                {shareable&&
                                    <Table.Cell textAlign='center'>
                                        {item.enterprise?
                                            <Fragment>
                                                {item.owner?
                                                    <span>{t([
                                                        'templates',
                                                        'sharing'
                                                    ].join('.'))}
                                                    </span>:
                                                    <span>{t([
                                                        'templates',
                                                        'shared'
                                                    ].join('.'))}
                                                    </span>
                                                }
                                                <Button
                                                    basic
                                                    icon='broken chain'
                                                    color='blue'
                                                    title={t('templates.hide')}
                                                    type='button'
                                                    data-name='unshare'
                                                    data-index={i}
                                                    onClick={this.handleClick}
                                                    disabled={!item.owner}
                                                />
                                            </Fragment>:
                                            <Button
                                                basic
                                                icon='chain'
                                                color='blue'
                                                title={t('templates.share')}
                                                type='button'
                                                data-name='share'
                                                data-index={i}
                                                onClick={this.handleClick}
                                                disabled={!item.owner}
                                            />
                                        }
                                    </Table.Cell>
                                }
                                <Table.Cell textAlign='right'>
                                    <Button.Group basic size='small'>
                                        <Button
                                            icon='eye'
                                            title={t('templates.view')}
                                            type='button'
                                            data-name='view'
                                            data-index={i}
                                            onClick={this.handleClick}
                                        />
                                        <Button
                                            icon='save'
                                            title={t('templates.edit')}
                                            type='button'
                                            data-name='edit'
                                            data-index={i}
                                            onClick={this.handleClick}
                                            disabled={!item.owner}
                                        />
                                        <Button
                                            icon='trash'
                                            title={t('templates.trash')}
                                            type='button'
                                            data-name='remove'
                                            data-index={i}
                                            onClick={this.handleClick}
                                            disabled={!item.owner}
                                        />
                                    </Button.Group>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>

                <Modal
                    basic
                    size='small'
                    open={modal}
                    onClose={this.handleClose}
                >
                    <Header
                        icon={{
                            'new':'plus',
                            'view':'eye',
                            'edit':'save',
                            'remove':'trash'
                        }[modal_type]}
                        content={{
                            'new':t('templates.q&a.confirm.new.title'),
                            'view':t('templates.q&a.confirm.view.title'),
                            'edit':t('templates.q&a.confirm.edit.title'),
                            'remove':t('templates.q&a.confirm.remove.title')
                        }[modal_type]}
                    />

                    <Form
                        inverted
                        onSubmit={this.handleSubmit}
                    >
                        <Modal.Content>
                            {modal_type==='remove'?
                                <p>
                                    {t('templates.q&a.confirm.remove.content')}
                                </p>:
                                this.renderForm()
                            }
                        </Modal.Content>
                        <Modal.Actions>
                            <Fragment>
                                {modal_type==='edit'&&
                                    <Button.Group floated='left'>
                                        <Button
                                            inverted
                                            title={t('utils.actions.saveAs')}
                                            type='button'
                                            data-name='save-as'
                                            onClick={this.handleClick}
                                            disabled={edit}
                                        >
                                            <Icon name='save' />
                                            {t('utils.actions.saveAs')}
                                        </Button>
                                    </Button.Group>
                                }
                                <Button.Group floated='right'>
                                    <Button
                                        basic
                                        inverted
                                        title={{
                                            'new':t('utils.actions.close'),
                                            'view':t('utils.actions.close'),
                                            'edit':t('utils.actions.close'),
                                            'remove':t('utils.bool.no')
                                        }[modal_type]}
                                        type='button'
                                        onClick={this.handleClose}
                                    >
                                        <Icon name='remove' />
                                        {{
                                            'new':t('utils.actions.close'),
                                            'view':t('utils.actions.close'),
                                            'edit':t('utils.actions.close'),
                                            'remove':t('utils.bool.no')
                                        }[modal_type]}
                                    </Button>
                                    {(
                                        modal_type==='new'||
                                        modal_type==='edit'
                                    )&&
                                        <Button
                                            inverted
                                            title={t('utils.actions.save')}
                                            type='submit'
                                            color='green'
                                            disabled={edit}
                                        >
                                            <Icon name='checkmark' />
                                            {t('utils.actions.save')}
                                        </Button>
                                    }
                                    {modal_type==='remove'&&
                                        <Button
                                            inverted
                                            title={t('utils.bool.yes')}
                                            type='button'
                                            color='green'
                                            data-name={'modal-'+modal_type}
                                            onClick={this.handleClick}
                                        >
                                            <Icon name='checkmark' />
                                            {t('utils.bool.yes')}
                                        </Button>
                                    }
                                </Button.Group>
                            </Fragment>
                        </Modal.Actions>
                    </Form>
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps=(state)=>{
    return {
        auth:state.auth,
        data:state.data
    };
};

export default withTranslation('global')(connect(mapStateToProps)(TemplateQA));

