import Cookies from 'js-cookie';
import {toast} from 'react-semantic-toasts';

function apiFetch({
    url,
    method,
    data,
    success,
    error=()=>{},
    t=((s)=>s)
}){
    if(method!=='GET'){
        if(!data){
            data={};
        }

        data._csrf=Cookies.get('firmanza.user.csrf');
    }

    let status;

    return fetch(url,{
        method:method,
        credentials:'same-origin',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json'
        },
        body:data?JSON.stringify(data):null
    })
    .then((response)=>{
        status=response.status;

        return response.json();
    })
    .then((body)=>{
        if(status===200||status===201){
            return success(body);
        }else{
            return error({
                status:status,
                body:body
            });
        }
    })
    .catch((e)=>{
        console.log(e);

        return error({
            status:0,
            body:{
                ok:false,
                message:t('api.error')
            }
        });
    });
}

function promiseFetch({
    url,
    method,
    data,
    t=((s)=>s)
}){
    return new Promise((resolve,reject)=>{
        apiFetch({
            url:url,
            method:method,
            data:data,
            success:(_data)=>{
                return resolve(_data);
            },
            error:(_error)=>{
                return reject(_error);
            },
            t:t
        });
    });
}

function generate(length=8){
    let charset='abcdefghijklmnopqrstuvwxyz'+
            'ABCDEFGHIJKLMNOPQRSTUVWXYZ'+
            '0123456789',
        retVal='';

    for (var i=0,n=charset.length;i<length;++i){
        retVal+=charset.charAt(Math.floor(Math.random()*n));
    }

    return retVal;
}

function booleanValue(value,t=((s)=>s)){
    if(value){
        return t('utils.bool.yes');
    }else{
        return t('utils.bool.no');
    }
}

function humanStatus(status,t=((s)=>s)){
    switch(status){
        case 'confirm':
            return t('utils.status.confirm');
        case 'forgot':
            return t('utils.status.forgot');
        case 'active':
            return t('utils.status.active');
        case 'inactive':
            return t('utils.status.inactive');
        case 'draft':
            return t('utils.status.draft');
        case 'published':
            return t('utils.status.published');
        case 'expired':
            return t('utils.status.expired');
        case 'completed':
            return t('utils.status.completed');
        case 'finished':
            return t('utils.status.finished');
        case 'archived':
            return t('utils.status.archived');
        case 'initialization':
            return t('utils.status.initialization');
        case 'update':
            return t('utils.status.update');
        case 'publish':
            return t('utils.status.publish');

        case 'find recipient':
            return t('utils.status.findRecipient');
        case 'get recipient':
            return t('utils.status.getRecipient');
        case 'complete recipient':
            return t('utils.status.completeRecipient');
        case '2fa auth':
            return t('utils.status.2faAuth');
        case '2fa waiting':
            return t('utils.status.2faWaiting');

        case '2fa firmanza granted':
            return t('utils.status.2faFirmanzaGranted');

        case '2fa q&a granted':
            return t('utils.status.2faQ&aGranted');
        case '2fa q&a answered':
            return t('utils.status.2faQ&aAnswered');
        case '2fa q&a skipped':
            return t('utils.status.2faQ&aSkipped');
        case '2fa q&a revoked':
            return t('utils.status.2faQ&aRevoked');
        case '2fa q&a denied':
            return t('utils.status.2faQ&aDenied');

        case '2fa email granted':
            return t('utils.status.2faEmailGranted');
        case '2fa phone granted':
            return t('utils.status.2faPhoneGranted');
        case '2fa password granted':
            return t('utils.status.2faPasswordGranted');
        case '2fa email denied':
            return t('utils.status.2faEmailDenied');
        case '2fa phone denied':
            return t('utils.status.2faPhoneDenied');
        case '2fa password denied':
            return t('utils.status.2faPasswordDenied');

        case 'get fields':
            return t('utils.status.getFields');
        case 'fill fields':
            return t('utils.status.fillFields');
        case 'presign field':
            return t('utils.status.presignField');
        case 'postsign field':
            return t('utils.status.postsignField');

        case 'get pdf':
            return t('utils.status.getPdf');

        default:
            return status;
    }
}

function jsonPrint(obj={},level=0){
    let string=[];

    Object.keys(obj).forEach((i)=>{
        switch(typeof obj[i]){
            case 'object':
                string.push(' '.repeat(4*level)+i+':');
                string.push(jsonPrint(obj[i]||{},level+1));

                break;
            case 'function':
            case 'undefined':
                string.push(' '.repeat(4*level)+i+': ');

                break;
            default:
                string.push(' '.repeat(4*level)+i+': '+obj[i].toString());

                break;
        }
    });

    return string.join('\n');
}

function certificateAttr(name,t=((s)=>s)){
    switch(name){
        case 'countryName':
            return t('certificates.certificate.countryName');
        case 'stateOrProvinceName':
            return t('certificates.certificate.stateOrProvinceName');
        case 'localityName':
            return t('certificates.certificate.localityName');
        case 'organizationName':
            return t('certificates.certificate.organizationName');
        case 'organizationalUnitName':
            return t('certificates.certificate.organizationUnitName');
        case 'commonName':
            return t('certificates.certificate.commonName');
        case 'emailAddress':
        case 'email':
            return t('certificates.certificate.email');
        case 'ip':
            return 'IP';
        case 'dns':
            return 'DNS';
        default:
            return name;
    }
}

function message(ok,message,t=((s)=>s),values={}){
    toast({
        title:ok?t('api.success'):t('api.failed'),
        description:t(message,values),
        type:ok?'success':'error',
        icon:ok?'check':'times',
        animation:ok?'fade down':'fade up',
        time:10000
    });
}

function acl(auth,permissions=[[]]){
    let granted=true;

    permissions.forEach((ands)=>{
        let term=false;

        ands.forEach((ors)=>{
            term=term||(auth.permissions.includes(ors));
        });

        granted=granted&&term;
    });

    return granted;
}

export {
    apiFetch,
    promiseFetch,
    generate,
    booleanValue,
    humanStatus,
    jsonPrint,
    certificateAttr,
    message,
    acl
};

