import React,{Component,Fragment} from 'react';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {
    Button,
    Container,
    Dimmer,
    Divider,
    Dropdown,
    Form,
    Grid,
    Header,
    Icon,
    Input,
    List,
    Loader,
    Menu,
    Modal,
    Segment,
    Table
} from 'semantic-ui-react';

import {
    apiFetch,
    message,
    acl
} from '../utils';

class TemplateFieldsViewer extends Component{
    constructor(props){
        super(props);

        const {t}=this.props;

        this.papersizes=[
            {label:t('utils.papersize.custom'),size:[0,0]},
            {label:t('utils.papersize.letter'),size:[21.6,27.9]},
            {label:t('utils.papersize.governmentLegal'),size:[21.6,33.0]},
            {label:t('utils.papersize.legal'),size:[21.6,35.6]},
            {label:t('utils.papersize.halfLetter'),size:[21.6,14.0]},
            {label: 'A0',size:[ 84.1,118.9]},
            {label: 'A1',size:[ 59.4, 84.1]},
            {label: 'A2',size:[ 42.0, 59.4]},
            {label: 'A3',size:[ 29.7, 42.0]},
            {label: 'A4',size:[21.0,29.7]},
            {label: 'A5',size:[ 14.8, 21.0]},
            {label: 'A6',size:[ 10.5, 14.8]},
            {label: 'A7',size:[  7.4, 10.5]},
            {label: 'A8',size:[  5.2,  7.4]},
            {label: 'A9',size:[  3.7,  5.2]},
            {label:'A10',size:[  2.6,  3.7]},
            {label: 'B0',size:[100.0,141.4]},
            {label: 'B1',size:[ 70.7,100.0]},
            {label: 'B2',size:[ 50.0, 70.7]},
            {label: 'B3',size:[ 35.3, 50.0]},
            {label: 'B4',size:[ 25.0, 35.3]},
            {label: 'B5',size:[ 17.6, 25.0]},
            {label: 'B6',size:[ 12.5, 17.6]},
            {label: 'B7',size:[  8.8, 12.5]},
            {label: 'B8',size:[  6.2,  8.8]},
            {label: 'B9',size:[  4.4,  6.2]},
            {label:'B10',size:[  3.1,  4.4]},
            {label: 'C0',size:[ 91.7,129.7]},
            {label: 'C1',size:[ 64.8, 91.7]},
            {label: 'C2',size:[ 45.8, 64.8]},
            {label: 'C3',size:[ 32.4, 45.8]},
            {label: 'C4',size:[ 22.9, 32.4]},
            {label: 'C5',size:[ 16.2, 22.9]},
            {label: 'C6',size:[ 11.4, 16.2]},
            {label: 'C7',size:[  8.1, 11.4]},
            {label: 'C8',size:[  5.7,  8.1]},
            {label: 'C9',size:[  4.0,  5.7]},
            {label:'C10',size:[  2.8,  4.0]}
        ];
        this.ptsToCm=28.35;

        this.state={
            mode:this.props.mode,
            id:this.props.match.params.template,
            data:{
                name:'',
                page:0,
                pages:0,
                recipients:0,
                papersize:0,
                orientation:true,
                width:0,
                _width:0,
                height:0,
                _height:0,
                fields:[]
            },
            field_index:-1,
            field:{
                recipient:-1,
                name:'',
                description:'',
                type:null,
                values:[],
                positions:[],
                mandatory:false,
                readonly:false
            },
            selected:{
                active:false,
                field:-1,
                position:-1
            },
            value:'',
            position:{
                page:0,
                coordinates:{
                    x:0,
                    y:0,
                    w:200,
                    h:20
                },
                edit:false,
                edit_index:-1
            },
            dragging:{
                active:false,
                x:0,
                y:0
            },
            modal:false,
            loading:false
        };

        this.handleClose=this.handleClose.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.handleClick=this.handleClick.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
        this.handlePointerDown=this.handlePointerDown.bind(this);
        this.handlePointerMove=this.handlePointerMove.bind(this);
        this.handlePointerUp=this.handlePointerUp.bind(this);
        this.handlePointerOver=this.handlePointerOver.bind(this);
        this.handlePointerOut=this.handlePointerOut.bind(this);
    }

    componentDidMount(){
        const {
                id
            }=this.state,
            {t}=this.props;

        if(id){
            apiFetch({
                url:'/api/templates/fields/'+id,
                method:'GET',
                success:(_data)=>{
                    const _width=Math.round((
                            _data.papersize.width/this.ptsToCm
                        )*10)/10,
                        _height=Math.round((
                            _data.papersize.height/this.ptsToCm
                        )*10)/10,
                        orientation=(_width<_height);

                    this.setState({
                        data:{
                            name:_data.name,
                            page:0,
                            pages:+_data.pages,
                            recipients:+_data.recipients,
                            papersize:this.papersizes.findIndex((i)=>{
                                return i.size[orientation?0:1]===_width&&
                                    i.size[orientation?1:0]===_height;
                            }),
                            orientation:orientation,
                            width:_data.papersize.width,
                            _width:_width,
                            height:_data.papersize.height,
                            _height:_height,
                            fields:_data.fields
                        }
                    });
                },
                error:(error)=>{
                    message(false,error.body.message,t);
                },
                t:t
            });
        }
    }

    getPaperSizes(){
        return this.papersizes
        .map((size,i)=>{
            return {
                key:i,
                text:i===0?
                    size.label:
                    size.label+' ('+size.size[0]+' cm, '+size.size[1]+' cm)',
                value:i
            };
        });
    }

    getOrientations(){
        const {t}=this.props;

        return [{
            key:0,
            text:t('utils.orientation.vertical'),
            value:true
        },{
            key:1,
            text:t('utils.orientation.horizontal'),
            value:false
        }];
    }

    getTypes(){
        const {t}=this.props;

        return [{
            key:0,
            text:t('utils.fieldType.checkbox'),
            value:'checkbox',
            icon:'check square'
        },{
            key:1,
            text:t('utils.fieldType.radio'),
            value:'radio',
            icon:'radio'
        },{
            key:2,
            text:t('utils.fieldType.text'),
            value:'text',
            icon:'text cursor'
        },{
            key:3,
            text:t('utils.fieldType.list'),
            value:'list',
            icon:'list ul'
        },{
            key:4,
            text:t('utils.fieldType.combo'),
            value:'combo',
            icon:'caret square down'
        },{
            key:5,
            text:t('utils.fieldType.signature'),
            value:'signature',
            icon:'edit'
        },{
            key:6,
            text:t('utils.fieldType.signatureImage'),
            value:'signature-image',
            icon:'signing'
        },{
            key:7,
            text:t('utils.fieldType.image'),
            value:'image',
            icon:'image'
        }];
    }

    getRecipients(){
        const {
                data
            }=this.state,
            {t}=this.props;

        return [...Array(data.recipients).keys()]
        .map((recipient,index)=>{
            return {
                key:index,
                text:t('templates.signer')+' #'+(recipient+1),
                value:index
            };
        });
    }

    handleClose(){
        this.setState({
            modal:false
        });
    }

    handleChange(event,_data){
        const {
            data,
            field,
            position
        }=this.state;

        switch(_data['data-name']){
            case 'pages':
                this.setState({
                    data:{
                        ...data,
                        [_data['data-name']]:+_data.value
                    }
                });

                break;
            case 'recipients':
                this.setState({
                    data:{
                        ...data,
                        [_data['data-name']]:+_data.value
                    }
                });

                break;
            case 'papersize':{
                const _width=this.papersizes[_data.value]
                    .size[data.orientation?0:1],
                    width=Math.round(_width*this.ptsToCm),
                    _height=this.papersizes[_data.value]
                    .size[data.orientation?1:0],
                    height=Math.round(_height*this.ptsToCm);

                this.setState({
                    data:{
                        ...data,
                        papersize:_data.value,
                        _width:_width,
                        width:width,
                        _height:_height,
                        height:height
                    }
                });

                break;
            }
            case 'orientation':{
                const _width=this.papersizes[data.papersize]
                    .size[_data.value?0:1],
                    width=Math.round(_width*this.ptsToCm),
                    _height=this.papersizes[data.papersize]
                    .size[_data.value?1:0],
                    height=Math.round(_height*this.ptsToCm);

                this.setState({
                    data:{
                        ...data,
                        orientation:_data.value,
                        _width:_width,
                        width:width,
                        _height:_height,
                        height:height
                    }
                });

                break;
            }
            case 'width':
                this.setState({
                    data:{
                        ...data,
                        _width:_data.value,
                        width:Math.round(_data.value*this.ptsToCm)
                    }
                });

                break;
            case 'height':
                this.setState({
                    data:{
                        ...data,
                        _height:_data.value,
                        height:Math.round(_data.value*this.ptsToCm)
                    }
                });

                break;
            case 'field-name':
                this.setState({
                    field:{
                        ...field,
                        name:_data.value
                    }
                });

                break;
            case 'field-type':
                this.setState({
                    field:{
                        ...field,
                        type:_data.value
                    }
                });

                break;
            case 'field-recipient':
                this.setState({
                    field:{
                        ...field,
                        recipient:_data.value
                    }
                });

                break;
            case 'field-description':
                this.setState({
                    field:{
                        ...field,
                        description:_data.value
                    }
                });

                break;
            case 'field-value':
                this.setState({
                    value:_data.value
                });

                break;
            case 'position-page':
                this.setState({
                    position:{
                        ...position,
                        page:+_data.value-1
                    }
                });

                break;
            case 'position-x':
                this.setState({
                    position:{
                        ...position,
                        coordinates:{
                            ...position.coordinates,
                            x:+_data.value
                        }
                    }
                });

                break;
            case 'position-y':
                this.setState({
                    position:{
                        ...position,
                        coordinates:{
                            ...position.coordinates,
                            y:+_data.value
                        }
                    }
                });

                break;
            case 'position-w':
                this.setState({
                    position:{
                        ...position,
                        coordinates:{
                            ...position.coordinates,
                            w:+_data.value
                        }
                    }
                });

                break;
            case 'position-h':
                this.setState({
                    position:{
                        ...position,
                        coordinates:{
                            ...position.coordinates,
                            h:+_data.value
                        }
                    }
                });

                break;
            case 'field-mandatory':
                this.setState({
                    field:{
                        ...field,
                        mandatory:_data.checked
                    }
                });

                break;
            default:
                this.setState({
                    data:{
                        ...data,
                        [_data['data-name']]:_data.value
                    }
                });

                break;
        }
    }

    handleClick(event,_data){
        const {
            data,
            field_index,
            field,
            value,
            position
        }=this.state;

        event.stopPropagation();

        switch(_data['data-name']){
            case 'fields-page-start':
                this.setState({
                    data:{
                        ...data,
                        page:0
                    }
                });

                break;
            case 'fields-page-previous':
                if(data.page>0){
                    this.setState({
                        data:{
                            ...data,
                            page:data.page-1
                        }
                    });
                }

                break;
            case 'fields-page-next':
                if(data.page<data.pages-1){
                    this.setState({
                        data:{
                            ...data,
                            page:data.page+1
                        }
                    });
                }

                break;
            case 'fields-page-end':
                this.setState({
                    data:{
                        ...data,
                        page:data.pages-1
                    }
                });

                break;
            case 'field':{
                let index=+_data['data-index'];

                this.setState({
                    field_index:index,
                    field:data.fields[index],
                    modal:true
                });

                break;
            }
            case 'modal-field':
                if(
                    document.querySelector('input[name="field-name"]')
                    .reportValidity()&&
                    field.type!==null&&
                    field.recipient!==null
                ){
                    if(field_index>=0){
                        data.fields[field_index]=field;
                    }else{
                        data.fields.push(field);
                    }

                    this.setState({
                        data:{
                            ...data,
                            fields:data.fields
                        },
                        field_index:-1,
                        modal:false
                    });
                }

                break;
            case 'field-up':{
                let index=+_data['data-index'];

                if(index>0){
                    [data.fields[index],data.fields[index-1]]=
                        [data.fields[index-1],data.fields[index]];

                    this.setState({
                        data:{
                            ...data,
                            fields:data.fields
                        }
                    });
                }

                break;
            }
            case 'field-bottom':{
                let index=+_data['data-index'];

                if(index<data.fields.length-1){
                    [data.fields[index],data.fields[index+1]]=
                        [data.fields[index+1],data.fields[index]];

                    this.setState({
                        data:{
                            ...data,
                            fields:data.fields
                        }
                    });
                }

                break;
            }
            case 'field-remove':{
                let index=+_data['data-index'];

                data.fields.splice(index,1);

                this.setState({
                    data:{
                        ...data,
                        fields:data.fields
                    }
                });

                break;
            }
            case 'field-clone':{
                let index=+_data['data-index'],
                    field={
                        ...data.fields[index],
                        name:data.fields[index].name+' COPIA'
                    };

                data.fields.push(JSON.parse(JSON.stringify(field)));

                this.setState({
                    data:{
                        ...data,
                        fields:data.fields
                    }
                });

                break;
            }
            case 'field-add':
                this.setState({
                    field:{
                        recipient:-1,
                        name:'',
                        description:'',
                        type:null,
                        values:[],
                        positions:[],
                        mandatory:false,
                        readonly:false
                    },
                    modal:true
                });

                break;
            case 'field-clean':
                this.setState({
                    data:{
                        ...data,
                        fields:[]
                    }
                });

                break;
            case 'value-up':{
                let index=+_data['data-index'],
                    values=field.values;

                if(index>0){
                    [values[index],values[index-1]]=
                        [values[index-1],values[index]];

                    this.setState({
                        field:{
                            ...field,
                            values:values
                        }
                    });
                }

                break;
            }
            case 'value-down':{
                let index=+_data['data-index'],
                    values=field.values;

                if(index<values.length-1){
                    [values[index],values[index+1]]=
                        [values[index+1],values[index]];

                    this.setState({
                        field:{
                            ...field,
                            values:values
                        }
                    });
                }

                break;
            }
            case 'value-remove':{
                let index=+_data['data-index'],
                    values=field.values;

                values.splice(index,1);

                this.setState({
                    field:{
                        ...field,
                        values:values
                    }
                });

                break;
            }
            case 'value-add':{
                this.setState({
                    field:{
                        ...field,
                        values:field.values.concat(value)
                    },
                    value:''
                });

                break;
            }
            case 'position-up':{
                let index=+_data['data-index'],
                    positions=field.positions;

                if(index>0){
                    [positions[index],positions[index-1]]=
                        [positions[index-1],positions[index]];

                    this.setState({
                        field:{
                            ...field,
                            positions:positions
                        }
                    });
                }

                break;
            }
            case 'position-down':{
                let index=+_data['data-index'],
                    positions=field.positions;

                if(index<positions.length-1){
                    [positions[index],positions[index+1]]=
                        [positions[index+1],positions[index]];

                    this.setState({
                        field:{
                            ...field,
                            positions:positions
                        }
                    });
                }

                break;
            }
            case 'position-save':{
                let index=+_data['data-index'],
                    _position=field.positions[index];

                _position.page=position.page;
                _position.coordinates=position.coordinates;

                this.setState({
                    field:{
                        ...field,
                        positions:field.positions
                    },
                    position:{
                        page:0,
                        coordinates:{
                            x:0,
                            y:0,
                            w:200,
                            h:20
                        },
                        edit:false,
                        edit_index:-1
                    }
                });

                break;
            }
            case 'position-edit':{
                let index=+_data['data-index'];

                this.setState({
                    position:{
                        page:field.positions[index].page,
                        coordinates:field.positions[index].coordinates,
                        edit:true,
                        edit_index:index
                    }
                });

                break;
            }
            case 'position-remove':{
                let index=+_data['data-index'],
                    positions=field.positions;

                positions.splice(index,1);

                this.setState({
                    field:{
                        ...field,
                        positions:positions
                    }
                });

                break;
            }
            case 'position-add':
                this.setState({
                    field:{
                        ...field,
                        positions:field.positions.concat({
                            ...position,
                            ...{
                                page:position.page
                            }
                        })
                    },
                    position:{
                        page:0,
                        coordinates:{
                            x:0,
                            y:0,
                            w:200,
                            h:20
                        },
                        edit:false,
                        edit_index:-1
                    }
                });

                break;
            default:
                return;
        }
    }

    handleSubmit(event){
        const {
                mode,
                id,
                data
            }=this.state,
            {t}=this.props;

        this.setState({
            loading:true
        });

        event.preventDefault();

        switch(mode){
            case 'add':
                apiFetch({
                    url:'/api/templates/fields',
                    method:'POST',
                    data:{
                        name:data.name,
                        pages:+data.pages,
                        recipients:+data.recipients,
                        papersize:{
                            width:+data.width,
                            height:+data.height
                        },
                        fields:data.fields
                    },
                    success:(_data)=>{
                        this.setState({
                            loading:false
                        });
                        this.props.history.push('/templates/fields');

                        message(_data.ok,_data.message,t);
                    },
                    error:(error)=>{
                        this.setState({
                            loading:false
                        });

                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            case 'edit':
                apiFetch({
                    url:'/api/templates/fields/'+id,
                    method:'PUT',
                    data:{
                        name:data.name,
                        pages:+data.pages,
                        recipients:+data.recipients,
                        papersize:{
                            width:+data.width,
                            height:+data.height
                        },
                        fields:data.fields
                    },
                    success:(_data)=>{
                        this.setState({
                            loading:false
                        });
                        this.props.history.push('/templates/fields');

                        message(_data.ok,_data.message,t);
                    },
                    error:(error)=>{
                        this.setState({
                            loading:false
                        });

                        message(false,error.body.message,t);
                    },
                    t:t
                });

                break;
            default:
                return;
        }
    }

    handlePointerDown(event){
        const {
            data
        }=this.state;

        const box=data
            .fields[event.target.dataset.field]
            .positions[event.target.dataset.position],
            bbox=event.target.getBoundingClientRect();

        event.target.setPointerCapture(event.pointerId);

        this.setState({
            dragging:{
                active:true,
                x:((event.clientX-bbox.left)*box.coordinates.w)/bbox.width,
                y:((event.clientY-bbox.top)*box.coordinates.h)/bbox.height
            }
        });
    }

    handlePointerMove(event){
        const {
            data,
            dragging
        }=this.state;

        if(dragging.active){
            let fields=data.fields,
                box=fields[event.target.dataset.field]
                .positions[event.target.dataset.position]
                .coordinates,
                bbox=event.target.getBoundingClientRect();

            box.x=Math.round(
                box.x-(
                    dragging.x-(
                        ((event.clientX-bbox.left)*box.w)/bbox.width
                    )
                )
            );
            box.y=Math.round(
                box.y-(
                    dragging.y-(
                        ((event.clientY-bbox.top)*box.h)/bbox.height
                    )
                )
            );

            this.setState({
                data:{
                    ...data,
                    fields:fields
                }
            });
        }
    }

    handlePointerUp(){
        this.setState({
            dragging:{
                active:false,
                x:0,
                y:0
            }
        });
    }

    handlePointerOver(event){
        this.setState({
            selected:{
                active:true,
                field:+event.target.dataset.field,
                position:+event.target.dataset.position
            }
        });
    }

    handlePointerOut(event){
        this.setState({
            selected:{
                active:false,
                field:-1,
                position:-1
            }
        });
    }

    formField(){
        const {
                mode,
                data,
                field,
                value,
                position
            }=this.state,
            {t}=this.props;

        return (
            <Form inverted>
                <Form.Group widths='equal'>
                    <Form.Input
                        label={t('templates.fields.name')}
                        type='text'
                        placeholder={t([
                            'templates',
                            'fields',
                            'placeholderName'
                        ].join('.'))}
                        name='field-name'
                        data-name='field-name'
                        value={field.name}
                        onChange={this.handleChange}
                        disabled={field.readonly||mode==='view'}
                        required
                        fluid
                    />
                    <Form.Select
                        label={t('templates.fields.type')}
                        placeholder={t('templates.fields.type')}
                        options={this.getTypes()}
                        name='field-type'
                        value={field.type}
                        data-name='field-type'
                        onChange={this.handleChange}
                        disabled={field.readonly||mode==='view'}
                        required
                        fluid
                    />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Select
                        label={t('templates.signer')}
                        placeholder={t('templates.signer')}
                        name='field-recipient'
                        options={this.getRecipients()}
                        value={field.recipient}
                        data-name='field-recipient'
                        onChange={this.handleChange}
                        disabled={mode==='view'}
                        required
                        fluid
                    />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.TextArea
                        label={t('templates.fields.instructions')}
                        rows='5'
                        value={field.description}
                        data-name='field-description'
                        onChange={this.handleChange}
                        disabled={mode==='view'}
                    />
                </Form.Group>
                {['checkbox','radio','combo','list'].includes(field.type)&&
                    <Fragment>
                        <label htmlFor='values'>
                            {field.type==='checkbox'?
                                t('templates.fields.possibleValue'):
                                t('templates.fields.possibleValues')
                            }
                        </label>
                        <Table
                            name='values'
                            size='small'
                            inverted
                        >
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell
                                        textAlign='center'
                                        width={1}
                                    >
                                        #
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        width={12}
                                        textAlign='center'
                                    >
                                        {t('templates.fields.value')}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        textAlign='right'
                                        width={3}
                                    >
                                        &nbsp;
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        textAlign='right'
                                        width={1}
                                    >
                                        &nbsp;
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {field.values.map((value,i)=>{
                                    return (
                                        <Table.Row
                                            key={'field-value-'+i}
                                        >
                                            <Table.Cell
                                                textAlign='center'
                                                width={1}
                                            >
                                                {i+1}
                                            </Table.Cell>
                                            <Table.Cell
                                                textAlign='center'
                                            >
                                                {value}
                                            </Table.Cell>
                                            <Table.Cell
                                                textAlign='right'
                                                width={3}
                                            >
                                                <Button
                                                    type='button'
                                                    icon='chevron up'
                                                    size='tiny'
                                                    title={t([
                                                        'templates',
                                                        'fields',
                                                        'moveUp'
                                                    ].join('.'))}
                                                    data-name='value-up'
                                                    data-index={i}
                                                    onClick={this.handleClick}
                                                    disabled={
                                                        field.readonly||
                                                        i===0
                                                    }
                                                />
                                                <Button
                                                    type='button'
                                                    icon='chevron down'
                                                    size='tiny'
                                                    title={t([
                                                        'templates',
                                                        'fields',
                                                        'getDown'
                                                    ].join('.'))}
                                                    data-name='value-down'
                                                    data-index={i}
                                                    onClick={this.handleClick}
                                                    disabled={
                                                        field.readonly||
                                                        i===
                                                        field.values.length-1
                                                    }
                                                />
                                            </Table.Cell>
                                            <Table.Cell
                                                textAlign='right'
                                                width={1}
                                            >
                                                <Button
                                                    type='button'
                                                    icon='times'
                                                    size='tiny'
                                                    title={t([
                                                        'templates',
                                                        'fields',
                                                        'remove'
                                                    ].join('.'))}
                                                    data-name='value-remove'
                                                    data-index={i}
                                                    onClick={this.handleClick}
                                                    disabled={
                                                        field.readonly||
                                                        mode==='view'
                                                    }
                                                    negative
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                            {!field.readonly&&
                            mode!=='view'&&
                            !(
                                field.type==='checkbox'&&
                                field.values.length>0
                            )&&
                                <Table.Footer>
                                    <Table.Row>
                                        <Table.Cell>&nbsp;</Table.Cell>
                                        <Table.Cell
                                            textAlign='center'
                                        >
                                            <Input
                                                type='text'
                                                placeholder={t([
                                                    'templates',
                                                    'fields',
                                                    'possibleValue'
                                                ].join('.'))}
                                                value={value}
                                                data-name='field-value'
                                                onChange={this.handleChange}
                                                fluid
                                            />
                                        </Table.Cell>
                                        <Table.Cell>&nbsp;</Table.Cell>
                                        <Table.Cell
                                            textAlign='right'
                                        >
                                            <Button
                                                type='button'
                                                icon='plus'
                                                size='tiny'
                                                title={t([
                                                    'templates',
                                                    'fields',
                                                    'add'
                                                ].join('.'))}
                                                data-name='value-add'
                                                onClick={this.handleClick}
                                                secondary
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Footer>
                            }
                        </Table>
                    </Fragment>
                }
                <label htmlFor='positions'>
                    {t('templates.fields.positioning')}
                    (pts) [28.35 pts = 1 cm]
                </label>
                <Table
                    name='positions'
                    size='small'
                    inverted
                >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                textAlign='center'
                                width={1}
                            >
                                #
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                textAlign='center'
                            >
                                {t('templates.fields.page')}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                textAlign='center'
                            >
                                X
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                textAlign='center'
                            >
                                Y
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                textAlign='center'
                            >
                                {t('templates.fields.width')}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                textAlign='center'
                            >
                                {t('templates.fields.height')}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                textAlign='right'
                                width={3}
                            >
                                &nbsp;
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                textAlign='right'
                                width={1}
                            >
                                &nbsp;
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {field.positions.map((_position,i)=>{
                            return (
                                <Table.Row
                                    key={'field-position-'+i}
                                >
                                    <Table.Cell
                                        textAlign='center'
                                    >
                                        {i+1}
                                    </Table.Cell>
                                    <Table.Cell
                                        textAlign='center'
                                    >
                                        {position.edit&&position.edit_index===i?
                                            <Input
                                                type='text'
                                                value={position.page+1}
                                                data-name='position-page'
                                                onChange={this.handleChange}
                                                fluid
                                            />:
                                            <Fragment>
                                                {_position.page+1}
                                                &nbsp;({
                                                    data.width
                                                }x{
                                                    data.height
                                                })
                                            </Fragment>
                                        }
                                    </Table.Cell>
                                    <Table.Cell
                                        textAlign='center'
                                    >
                                        {position.edit&&position.edit_index===i?
                                            <Input
                                                type='text'
                                                value={position.coordinates.x}
                                                data-name='position-x'
                                                onChange={this.handleChange}
                                                fluid
                                            />:
                                            _position.coordinates.x
                                        }
                                    </Table.Cell>
                                    <Table.Cell
                                        textAlign='center'
                                    >
                                        {position.edit&&position.edit_index===i?
                                            <Input
                                                type='text'
                                                value={position.coordinates.y}
                                                data-name='position-y'
                                                onChange={this.handleChange}
                                                fluid
                                            />:
                                            _position.coordinates.y
                                        }
                                    </Table.Cell>
                                    <Table.Cell
                                        textAlign='center'
                                    >
                                        {position.edit&&position.edit_index===i?
                                            <Input
                                                type='text'
                                                value={position.coordinates.w}
                                                data-name='position-w'
                                                onChange={this.handleChange}
                                                fluid
                                            />:
                                            _position.coordinates.w
                                        }
                                    </Table.Cell>
                                    <Table.Cell
                                        textAlign='center'
                                    >
                                        {position.edit&&position.edit_index===i?
                                            <Input
                                                type='text'
                                                value={position.coordinates.h}
                                                data-name='position-h'
                                                onChange={this.handleChange}
                                                fluid
                                            />:
                                            _position.coordinates.h
                                        }
                                    </Table.Cell>
                                    <Table.Cell
                                        textAlign='right'
                                        width={3}
                                    >
                                        <Button
                                            type='button'
                                            icon='chevron up'
                                            size='tiny'
                                            title={t([
                                                'templates',
                                                'fields',
                                                'moveUp'
                                            ].join('.'))}
                                            data-name='position-up'
                                            data-index={i}
                                            onClick={this.handleClick}
                                            disabled={
                                                field.readonly||
                                                i===0
                                            }
                                        />
                                        <Button
                                            type='button'
                                            icon='chevron down'
                                            size='tiny'
                                            title={t([
                                                'templates',
                                                'fields',
                                                'getDown'
                                            ].join('.'))}
                                            data-name='position-down'
                                            data-index={i}
                                            onClick={this.handleClick}
                                            disabled={
                                                field.readonly||
                                                i===field.positions.length-1
                                            }
                                        />
                                    </Table.Cell>
                                    <Table.Cell
                                        textAlign='right'
                                        width={3}
                                    >
                                        {position.edit&&position.edit_index===i?
                                            <Button
                                                type='button'
                                                icon='save'
                                                size='tiny'
                                                title={t([
                                                    'templates',
                                                    'fields',
                                                    'save'
                                                ].join('.'))}
                                                data-name='position-save'
                                                data-index={i}
                                                onClick={this.handleClick}
                                                positive
                                            />:
                                            <Button
                                                type='button'
                                                icon='edit'
                                                size='tiny'
                                                title={t([
                                                    'templates',
                                                    'fields',
                                                    'modify'
                                                ].join('.'))}
                                                data-name='position-edit'
                                                data-index={i}
                                                onClick={this.handleClick}
                                                disabled={
                                                    field.readonly||
                                                    mode==='view'
                                                }
                                            />
                                        }
                                        <Button
                                            type='button'
                                            icon='times'
                                            size='tiny'
                                            title={t([
                                                'templates',
                                                'fields',
                                                'remove'
                                            ].join('.'))}
                                            data-name='position-remove'
                                            data-index={i}
                                            onClick={this.handleClick}
                                            disabled={
                                                field.readonly||
                                                mode==='view'||
                                                position.edit
                                            }
                                            negative
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                    {!field.readonly&&
                    !position.edit&&
                    mode!=='view'&&
                    (
                        ([
                            'checkbox',
                            'radio'
                        ].includes(field.type)&&field.positions.length<
                            field.values.length)||
                        ([
                            'text',
                            'list',
                            'combo',
                            'signature',
                            'signature-image',
                            'image'
                        ].includes(field.type)&&field.positions.length<1)
                    )&&
                        <Table.Footer>
                            <Table.Row>
                                <Table.Cell
                                    textAlign='center'
                                >
                                    &nbsp;
                                </Table.Cell>
                                <Table.Cell
                                    textAlign='center'
                                >
                                    <Input
                                        type='text'
                                        value={position.page+1}
                                        data-name='position-page'
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Table.Cell>
                                <Table.Cell
                                    textAlign='center'
                                >
                                    <Input
                                        type='text'
                                        value={position.coordinates.x}
                                        data-name='position-x'
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Table.Cell>
                                <Table.Cell
                                    textAlign='center'
                                >
                                    <Input
                                        type='text'
                                        value={position.coordinates.y}
                                        data-name='position-y'
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Table.Cell>
                                <Table.Cell
                                    textAlign='center'
                                >
                                    <Input
                                        type='text'
                                        value={position.coordinates.w}
                                        data-name='position-w'
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Table.Cell>
                                <Table.Cell
                                    textAlign='center'
                                >
                                    <Input
                                        type='text'
                                        value={position.coordinates.h}
                                        data-name='position-h'
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    &nbsp;
                                </Table.Cell>
                                <Table.Cell
                                    textAlign='right'
                                >
                                    <Button
                                        type='button'
                                        icon='plus'
                                        size='tiny'
                                        title={t('templates.fields.add')}
                                        data-name='position-add'
                                        onClick={this.handleClick}
                                        secondary
                                    />
                                </Table.Cell>
                            </Table.Row>
                        </Table.Footer>
                    }
                </Table>
                <Form.Checkbox
                    label={t('templates.fields.required')}
                    checked={field.mandatory}
                    data-name='field-mandatory'
                    onChange={this.handleChange}
                    disabled={mode==='view'}
                />
            </Form>
        );
    }

    renderGuides(){
        const {
            data,
            selected
        }=this.state;

        const coordinates=data
            .fields[selected.field]
            .positions[selected.position]
            .coordinates,
            d1=Math.round(coordinates.x*100)/100,
            d2=Math.round(coordinates.y*100)/100,
            d3=Math.round((data.width-(coordinates.x+coordinates.w))*100)/100,
            d4=Math.round((data.height-(coordinates.y+coordinates.h))*100)/100;

        return (
            <g>
                <rect
                    x={0}
                    y={coordinates.y}
                    width={coordinates.x}
                    height={coordinates.h}
                    style={{
                        fill:'#000',
                        fillOpacity:0.15,
                    }}
                />
                <line
                    x1={0}
                    y1={coordinates.y+0.5*coordinates.h}
                    x2={coordinates.x}
                    y2={coordinates.y+0.5*coordinates.h}
                    style={{
                        stroke:'#000',
                        strokeWidth:1.0
                    }}
                />
                <rect
                    x={0.5*coordinates.x-4-String(d1).length*3}
                    y={coordinates.y+0.5*coordinates.h-8}
                    width={8+String(d1).length*6}
                    height={16}
                    style={{
                        fill:'#000',
                    }}
                />
                <text
                    x={coordinates.x/2-(String(d1).length*3)}
                    y={coordinates.y+0.5*coordinates.h+3}
                    style={{
                        fontFamily:'monospace',
                        fontSize:10,
                        fontWeight:'bold',
                        fill:'#fff'
                    }}
                >
                    {d1}
                </text>

                <rect
                    x={coordinates.x}
                    y={0}
                    width={coordinates.w}
                    height={coordinates.y}
                    style={{
                        fill:'#000',
                        fillOpacity:0.15,
                    }}
                />
                <line
                    x1={coordinates.x+0.5*coordinates.w}
                    y1={0}
                    x2={coordinates.x+0.5*coordinates.w}
                    y2={coordinates.y}
                    style={{
                        stroke:'#000',
                        strokeWidth:1.0
                    }}
                />
                <rect
                    x={coordinates.x+0.5*coordinates.w-4-String(d2).length*3}
                    y={0.5*coordinates.y-8}
                    width={8+String(d2).length*6}
                    height={16}
                    style={{
                        fill:'#000',
                    }}
                />
                <text
                    x={coordinates.x+0.5*coordinates.w-(String(d2).length*3)
                    }
                    y={0.5*coordinates.y+3}
                    style={{
                        fontFamily:'monospace',
                        fontSize:10,
                        fontWeight:'bold',
                        fill:'#fff'
                    }}
                >
                    {d2}
                </text>

                <rect
                    x={coordinates.x+coordinates.w}
                    y={coordinates.y}
                    width={data.width-coordinates.w-coordinates.x}
                    height={coordinates.h}
                    style={{
                        fill:'#000',
                        fillOpacity:0.15,
                    }}
                />
                <line
                    x1={coordinates.x+coordinates.w}
                    y1={coordinates.y+0.5*coordinates.h}
                    x2={data.width}
                    y2={coordinates.y+0.5*coordinates.h}
                    style={{
                        stroke:'#000',
                        strokeWidth:1.0
                    }}
                />
                <rect
                    x={coordinates.x+coordinates.w+
                        0.5*(data.width-(coordinates.x+coordinates.w))-4-
                        String(d3).length*3
                    }
                    y={coordinates.y+0.5*coordinates.h-8}
                    width={8+String(d3).length*6}
                    height={16}
                    style={{
                        fill:'#000',
                    }}
                />
                <text
                    x={coordinates.x+coordinates.w+
                        0.5*(data.width-(coordinates.x+coordinates.w))-
                        (String(d3).length*3)
                    }
                    y={coordinates.y+0.5*coordinates.h+3}
                    style={{
                        fontFamily:'monospace',
                        fontSize:10,
                        fontWeight:'bold',
                        fill:'#fff'
                    }}
                >
                    {d3}
                </text>

                <rect
                    x={coordinates.x}
                    y={coordinates.y+coordinates.h}
                    width={coordinates.w}
                    height={data.height-coordinates.h-coordinates.y}
                    style={{
                        fill:'#000',
                        fillOpacity:0.15,
                    }}
                />
                <line
                    x1={coordinates.x+0.5*coordinates.w}
                    y1={coordinates.y+coordinates.h}
                    x2={coordinates.x+0.5*coordinates.w}
                    y2={data.height}
                    style={{
                        stroke:'#000',
                        strokeWidth:1.0
                    }}
                />
                <rect
                    x={coordinates.x+0.5*coordinates.w-4-String(d4).length*3}
                    y={coordinates.y+coordinates.h+
                        0.5*(data.height-(coordinates.y+coordinates.h))-8
                    }
                    width={8+String(d4).length*6}
                    height={16}
                    style={{
                        fill:'#000',
                    }}
                />
                <text
                    x={coordinates.x+0.5*coordinates.w-
                        (String(d4).length*3)
                    }
                    y={coordinates.y+coordinates.h+
                        0.5*(data.height-(coordinates.y+coordinates.h))+3
                    }
                    style={{
                        fontFamily:'monospace',
                        fontSize:10,
                        fontWeight:'bold',
                        fill:'#fff'
                    }}
                >
                    {d4}
                </text>
            </g>
        );
    }

    renderFields(index,readonly){
        const {
            mode,
            data,
            selected
        }=this.state;

        const renderPosition=(position,j,field,i)=>{
                return (
                    <g
                        key={'editor-position-'+j}
                    >
                        <rect
                            x={position.coordinates.x}
                            y={position.coordinates.y}
                            width={position.coordinates.w}
                            height={position.coordinates.h}
                            style={{
                                fill:'#ffe680',
                                fillOpacity:0.75,
                                stroke:field.mandatory?'#db2828':'#2185d0',
                                strokeOpacity:1,
                                strokeWidth:1
                            }}
                            data-field={i}
                            data-position={j}
                            onPointerDown={
                                field.readonly||readonly||mode==='view'?
                                    null:this.handlePointerDown
                            }
                            onPointerMove={
                                field.readonly||readonly||mode==='view'?
                                    null:this.handlePointerMove
                            }
                            onPointerUp={
                                field.readonly||readonly||mode==='view'?
                                    null:this.handlePointerUp
                            }
                            onPointerOver={this.handlePointerOver}
                            onPointerOut={this.handlePointerOut}
                        />
                        {[
                            'text',
                            'list',
                            'combo',
                            'signature',
                            'signature-image',
                            'image'
                        ].includes(field.type)&&
                            <text
                                x={position.coordinates.x+4}
                                y={position.coordinates.y+12}
                                style={{
                                    fontSize:10,
                                    fill:field.mandatory?'#db2828':'#2185d0'
                                }}
                            >
                                {
                                    field.name+' ('+
                                    (Math.round(position.coordinates.w*
                                    100)/100)+
                                    'x'+
                                    (Math.round(position.coordinates.h*
                                    100)/100)+
                                    ')'
                                }
                            </text>
                        }
                    </g>
                );
            },
            renderField=(field,i)=>{
                return (
                    <g key={'editor-field-'+i}>
                        {
                            field.positions
                            .filter((position,j)=>{
                                return position.page===data.page;
                            })
                            .map((position,j)=>{
                                return renderPosition(position,j,field,i);
                            })
                        }
                    </g>
                );
            };

        return (
            <svg
                viewBox={'0 0 '+data.width+' '+data.height}
                style={{
                    width:((1080/data.height)*data.width)+'px',
                    height:'1080px',
                    backgroundColor:'#ffffff'
                }}
            >
                {!readonly&&
                    <g>
                        {[...Array(Math.ceil(data.width*2.54/72))]
                        .map((x,i)=>{
                            return (
                                <line
                                    key={'gridx-'+i}
                                    x1={i*72/2.54}
                                    y1={0}
                                    x2={i*72/2.54}
                                    y2={data.height}
                                    style={{
                                        stroke:'#a0a0a0',
                                        strokeWidth:0.5
                                    }}
                                />
                            );
                        })}
                        {[...Array(Math.ceil(data.height*2.54/72))]
                        .map((y,j)=>{
                            return (
                                <line
                                    key={'gridy-'+j}
                                    x1={0}
                                    y1={j*72/2.54}
                                    x2={data.width}
                                    y2={j*72/2.54}
                                    style={{
                                        stroke:'#a0a0a0',
                                        strokeWidth:0.5
                                    }}
                                />
                            );
                        })}
                    </g>
                }
                {data.fields.map(renderField)}
                {selected.active&&this.renderGuides()}
            </svg>
        );
    }

    renderViewer(readonly=false){
        const {
                data
            }=this.state,
            {t}=this.props;

        return (
            <Fragment>
                <Menu
                    size='mini'
                    icon
                    compact
                    borderless
                    inverted
                    widths={5}
                    fluid
                >
                    <Menu.Item
                        icon='step backward'
                        title={t('templates.fields.first')}
                        data-name='fields-page-start'
                        onClick={this.handleClick}
                    />
                    <Menu.Item
                        icon='angle left'
                        title={t('templates.fields.previous')}
                        data-name='fields-page-previous'
                        onClick={this.handleClick}
                    />
                    <Menu.Item>
                        <strong>
                            {
                                data.width+' pts x '+
                                data.height+' pts '
                            }
                            &nbsp;&nbsp;|&nbsp;&nbsp;
                            {data.page+1}
                            &nbsp;/&nbsp;
                            {data.pages}
                        </strong>
                    </Menu.Item>
                    <Menu.Item
                        icon='angle right'
                        title={t('templates.fields.next')}
                        data-name='fields-page-next'
                        onClick={this.handleClick}
                    />
                    <Menu.Item
                        icon='step forward'
                        title={t('templates.fields.last')}
                        data-name='fields-page-end'
                        onClick={this.handleClick}
                    />
                </Menu>
                <div id='designer'>
                    <div
                        id='fields-editor'
                        style={{
                            position:'relative'
                        }}
                    >
                        {this.renderFields(data.page,readonly)}
                    </div>
                </div>
            </Fragment>
        );
    }

    render(){
        const {
                mode,
                id,
                data,
                selected,
                modal,
                loading
            }=this.state,
            {t}=this.props;

        if(
            !this.props.auth.logged||
            !acl(this.props.auth,[['templates:fields']])
        ){
            return (
                <Redirect to='/' />
            );
        }

        const renderField=(field,i,fields)=>{
            return (
                <List.Item
                    key={'step3-'+i}
                    className={[
                        'field',
                        selected.active&&selected.field===i?'selected':null
                    ].join(' ')}
                >
                    <List.Icon
                        name={{
                            'checkbox':'check square',
                            'radio':'radio',
                            'text':'text cursor',
                            'list':'list ul',
                            'combo':'caret square down',
                            'signature':'edit',
                            'signature-image':'signing',
                            'image':'image'
                        }[field.type]}
                        size='large'
                        color={field.mandatory?'red':'blue'}
                        verticalAlign='middle'
                    />
                    <List.Content>
                        <List.Header>
                            {
                                field.name+' ('+
                                (field.mandatory?
                                    t('utils.fieldMandatory.required'):
                                    t('utils.fieldMandatory.optional')
                                )+')'
                            }
                        </List.Header>
                        <List.Description>
                            {t('templates.fields.page')}:
                            {field.positions.map((pos)=>{
                                return pos.page+1;
                            }).join(', ')}
                            &nbsp;|&nbsp;
                            {t('templates.signer')}:
                            {field.recipient+1}
                        </List.Description>
                    </List.Content>
                    <List.Content
                        className='hidden'
                        floated='right'
                    >
                        <br />
                        {['add','edit'].includes(mode)&&
                            <Fragment>
                                <Button
                                    type='button'
                                    icon='chevron up'
                                    size='tiny'
                                    title={t('templates.fields.moveUp')}
                                    data-name='field-up'
                                    data-index={i}
                                    onClick={this.handleClick}
                                    disabled={i===0}
                                />
                                <Button
                                    type='button'
                                    icon='chevron down'
                                    size='tiny'
                                    title={t('templates.fields.getDown')}
                                    data-name='field-bottom'
                                    data-index={i}
                                    onClick={this.handleClick}
                                    disabled={i===fields.length-1}
                                />
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                <Button
                                    type='button'
                                    icon='clone'
                                    size='tiny'
                                    title={t('templates.fields.duplicate')}
                                    data-name='field-clone'
                                    data-index={i}
                                    onClick={this.handleClick}
                                />
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                <Button
                                    type='button'
                                    icon='cog'
                                    size='tiny'
                                    title={t('templates.fields.configure')}
                                    data-name='field'
                                    data-index={i}
                                    onClick={this.handleClick}
                                />
                                <Button
                                    type='button'
                                    icon='times'
                                    size='tiny'
                                    title={t('templates.fields.remove')}
                                    data-name='field-remove'
                                    data-index={i}
                                    onClick={this.handleClick}
                                    disabled={field.readonly}
                                    negative
                                />
                            </Fragment>
                        }
                        {mode==='view'&&
                            <Button
                                type='button'
                                icon='eye'
                                size='tiny'
                                title={t('templates.fields.display')}
                                data-name='field'
                                data-index={i}
                                onClick={this.handleClick}
                            />
                        }
                    </List.Content>
                </List.Item>
            );
        };

        return (
            <Container fluid>
                <Header as='h1'>{t('menubar.templates')}</Header>

                <div className='ui breadcrumb'>
                    <a href='/' className='section'>
                        {t('menubar.home')}
                    </a>
                    <i aria-hidden='true'
                        className='right angle icon divider'></i>
                    {mode==='edit'&&
                        <Fragment>
                            <a
                                href={'/templates/fields/'+id+'/view'}
                                className='section'>
                                {t('templates.view')}
                            </a>
                            <i aria-hidden='true'
                                className='right angle icon divider'></i>
                        </Fragment>
                    }
                    {
                        {
                            'add':<div className='active section'>
                                {t('templates.create')}
                            </div>,
                            'view':<div className='active section'>
                                {t('templates.view')}
                            </div>,
                            'edit':<div className='active section'>
                                {t('templates.edit')}
                            </div>
                        }[mode]
                    }
                </div>
                <br />

                <Menu tabular>
                    <Menu.Item
                        as='a'
                        href='/templates/qa'
                        name={t('templates.q&a.title')}
                    />
                    <Menu.Item
                        as='a'
                        href='/templates/fields'
                        name={t('templates.fields.title')}
                    />
                    {
                        {
                            'add':
                                <Menu.Item
                                    as='a'
                                    href='/templates/fields/add'
                                    name={t('templates.create')}
                                    active
                                />,
                            'view':
                                <Menu.Item
                                    as='a'
                                    href={'/templates/fields/'+id+'/view'}
                                    name={t('templates.view')}
                                    active
                                />,
                            'edit':
                                <Menu.Item
                                    as='a'
                                    href={'/templates/fields/'+id+'/edit'}
                                    name={t('templates.edit')}
                                    active
                                />
                        }[mode]
                    }
                </Menu>

                <Dimmer.Dimmable dimmed={loading}>
                    <Form onSubmit={this.handleSubmit}>
                        <Header as='h3' dividing>
                            {t('templates.configure')}
                        </Header>

                        <Form.Group widths='equal'>
                            <Form.Field required={mode!=='view'}>
                                <label htmlFor='name'>
                                    {t('templates.name')}
                                </label>
                                {mode==='view'?
                                    <Segment
                                        name='name'
                                        size='small'
                                    >
                                        {data.name}
                                    </Segment>:
                                    <Input
                                        type='text'
                                        placeholder={t([
                                            'templates',
                                            'fields',
                                            'name'
                                        ].join('.'))}
                                        value={data.name}
                                        data-name='name'
                                        onChange={this.handleChange}
                                        required
                                    />
                                }
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field required={mode!=='view'}>
                                <label htmlFor='pages'>
                                    {t('templates.fields.count.pages')}
                                </label>
                                {mode==='view'?
                                    <Segment
                                        name='pages'
                                        size='small'
                                    >
                                        {data.pages}
                                    </Segment>:
                                    <Input
                                        type='number'
                                        value={data.pages}
                                        data-name='pages'
                                        min={0}
                                        onChange={this.handleChange}
                                        required
                                    />
                                }
                            </Form.Field>
                            <Form.Field required={mode!=='view'}>
                                <label htmlFor='recipients'>
                                    {t('templates.fields.count.signers')}
                                </label>
                                {mode==='view'?
                                    <Segment
                                        name='recipients'
                                        size='small'
                                    >
                                        {data.recipients}
                                    </Segment>:
                                    <Input
                                        type='number'
                                        value={data.recipients}
                                        data-name='recipients'
                                        min={0}
                                        onChange={this.handleChange}
                                        required
                                    />
                                }
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field required={mode!=='view'}>
                                <label htmlFor='papersize'>
                                    {t('templates.fields.papersize')}
                                </label>
                                {mode==='view'?
                                    <Segment
                                        name='papersize'
                                        size='small'
                                    >
                                        {data.papersize}
                                    </Segment>:
                                    <Dropdown
                                        selection
                                        options={this.getPaperSizes()}
                                        value={data.papersize}
                                        data-name='papersize'
                                        onChange={this.handleChange}
                                    />
                                }
                            </Form.Field>
                            <Form.Field required={mode!=='view'}>
                                <label htmlFor='orientation'>
                                    {t('templates.fields.orientation')}
                                </label>
                                {mode==='view'?
                                    <Segment
                                        name='orientation'
                                        size='small'
                                    >
                                        {data.orientation?
                                            t('utils.orientation.vertical'):
                                            t('utils.orientation.horizontal')
                                        }
                                    </Segment>:
                                    <Dropdown
                                        selection
                                        options={this.getOrientations()}
                                        value={data.orientation}
                                        data-name='orientation'
                                        onChange={this.handleChange}
                                    />
                                }
                            </Form.Field>
                            <Form.Field required={mode!=='view'}>
                                <label htmlFor='width'>
                                    {t('templates.fields.width')} (cm)
                                </label>
                                {mode==='view'?
                                    <Segment
                                        name='width'
                                        size='small'
                                    >
                                        {data._width}
                                    </Segment>:
                                    <Input
                                        type='number'
                                        step='.01'
                                        value={data._width}
                                        data-name='width'
                                        min={0}
                                        onChange={this.handleChange}
                                        required
                                        readOnly={data.papersize!==0}
                                    />
                                }
                            </Form.Field>
                            <Form.Field required={mode!=='view'}>
                                <label htmlFor='height'>
                                    {t('templates.fields.height')} (cm)
                                </label>
                                {mode==='view'?
                                    <Segment
                                        name='height'
                                        size='small'
                                    >
                                        {data._height}
                                    </Segment>:
                                    <Input
                                        type='number'
                                        step='.01'
                                        value={data._height}
                                        data-name='height'
                                        min={0}
                                        onChange={this.handleChange}
                                        required
                                        readOnly={data.papersize!==0}
                                    />
                                }
                            </Form.Field>
                        </Form.Group>

                        <Header as='h3' dividing>
                            {t('templates.fields.field.list')}
                        </Header>

                        <Grid centered columns={2}>
                            <Grid.Column width={4}>
                                <List divided relaxed>
                                    {data.fields.map(renderField)}
                                    {['add','edit'].includes(mode)&&
                                        <List.Item>
                                            <List.Icon
                                                name='square outline'
                                                size='large'
                                                verticalAlign='middle'
                                            />
                                            <List.Content>
                                                <List.Header>
                                                    {t([
                                                        'templates',
                                                        'fields',
                                                        'field',
                                                        'new'
                                                    ].join('.'))}
                                                </List.Header>
                                                <List.Description>
                                                    {t([
                                                        'utils',
                                                        'fieldMandatory',
                                                        'optional'
                                                    ].join('.'))}
                                                </List.Description>
                                            </List.Content>
                                            <List.Content floated='right'>
                                                <Button
                                                    type='button'
                                                    icon='plus'
                                                    size='tiny'
                                                    title={t([
                                                        'templates',
                                                        'fields',
                                                        'add'
                                                    ].join('.'))}
                                                    data-name='field-add'
                                                    onClick={this.handleClick}
                                                    secondary
                                                />
                                                <Button
                                                    type='button'
                                                    icon='trash'
                                                    size='tiny'
                                                    title={t([
                                                        'templates',
                                                        'fields',
                                                        'clean'
                                                    ].join('.'))}
                                                    data-name='field-clean'
                                                    onClick={this.handleClick}
                                                    negative
                                                />
                                            </List.Content>
                                        </List.Item>
                                    }
                                </List>
                            </Grid.Column>
                            <Grid.Column width={12}>
                                {this.renderViewer()}
                            </Grid.Column>
                        </Grid>

                        <Divider />
                        {{
                            'add':
                                <Button
                                    icon
                                    color='blue'
                                    labelPosition='left'
                                    type='submit'
                                >
                                    <Icon name='save' />
                                    {t('utils.actions.save')}
                                </Button>,
                            'view':
                                <Fragment>
                                    <Dropdown
                                        text={t('utils.actions.options')}
                                        icon='tasks'
                                        className='icon'
                                        labeled
                                        button
                                    >
                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                icon='save'
                                                content={t([
                                                    'utils',
                                                    'actions',
                                                    'edit'
                                                ].join('.'))}
                                                href={
                                                    '/templates/fields/'+
                                                    id+'/edit'
                                                }
                                            />
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Fragment>,
                            'edit':
                                <Button
                                    icon
                                    color='blue'
                                    labelPosition='left'
                                    type='submit'
                                >
                                    <Icon name='save' />
                                    {t('utils.actions.save')}
                                </Button>
                        }[mode]}
                    </Form>

                    <Modal
                        basic
                        size='small'
                        open={modal}
                        onClose={this.handleClose}
                    >
                        {['add','edit'].includes(mode)?
                            <Header icon='signup'
                                content={t([
                                    'templates',
                                    'fields',
                                    'confirm',
                                    'add',
                                    'title'
                                ].join('.'))}
                            />:
                            <Header icon='signup'
                                content={t([
                                    'templates',
                                    'fields',
                                    'confirm',
                                    'edit',
                                    'title'
                                ].join('.'))}
                            />
                        }

                        <Modal.Content>
                            {this.formField()}
                        </Modal.Content>
                        <Modal.Actions>
                            <Button.Group floated='right'>
                                <Button
                                    basic
                                    inverted
                                    color='red'
                                    title={t('utils.actions.close')}
                                    onClick={this.handleClose}
                                >
                                    <Icon name='remove' />
                                    {t('utils.actions.close')}
                                </Button>
                                <Button
                                    inverted
                                    color='green'
                                    title={t('utils.bool.yes')}
                                    data-name='modal-field'
                                    onClick={this.handleClick}
                                >
                                    <Icon name='checkmark' />
                                    {t('utils.actions.save')}
                                </Button>
                            </Button.Group>
                        </Modal.Actions>
                    </Modal>

                    <Dimmer active={loading}>
                        <Loader
                            active={loading}
                            indeterminate>
                            {t('api.sending')}
                        </Loader>
                    </Dimmer>
                </Dimmer.Dimmable>
            </Container>
        );
    }
}

const mapStateToProps=(state)=>{
    return {
        auth:state.auth,
        data:state.data
    };
};

export default withTranslation('global')(
    connect(mapStateToProps)(TemplateFieldsViewer)
);

